import React from 'react'
import { TextField, makeStyles } from '@material-ui/core'

const PrimaryInputField = ({ type, onKeyDownEvent, label, ...props }) => {
  const classes = useStyles()

  return (
    <TextField
      className={classes.inputElement}
      type={type}
      label={label}
      onKeyDown={onKeyDownEvent}
      {...props}
    />
  )
}

const useStyles = makeStyles(() => ({
  inputElement: {
    width: '100%',
    borderWidth: 1,
    fontSize: '14px',
    '&>div': {
      '&::before': {
        borderWidth: '1px'
      }
    }
  }
}))

export default PrimaryInputField
