import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Button, makeStyles } from '@material-ui/core'
import ConfirmApproveModal from './ConfirmApprove'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  modalView: {
    width: '22rem !important',
    height: '13rem'
  },
  modalSecondaryButton: {
    backgroundColor: '#e4e4e4',
    padding: '5px 20px',
    borderRadius: '40px',
    color: '#111d4e',
    '&:hover': {
      backgroundColor: '#e4e4e4',
      color: '#111d4e'
    }
  },
  modalPrimaryButton: {
    backgroundColor: '#111d4e',
    padding: '5px 20px',
    borderRadius: '40px',
    color: '#e4e4e4',
    '&:hover': {
      backgroundColor: '#111d4e',
      color: '#e4e4e4'
    }
  }
}))

const ApproveModal = ({ open, close, request }) => {
  const classes = useStyles()
  const [openApprove, setOpenApprove] = React.useState(false)

  const confirmApproveClicked = () => {
    close()
    setOpenApprove(true)
  }

  const confirmApproveClose = () => {
    setOpenApprove(false)
  }
  return (
    <div>
      <Dialog
        open={open}
        onClose={close}
        classes={{ paper: classes.modalView }}>
        <DialogTitle>Approve Transaction</DialogTitle>
        <DialogContent>Do you want to approve this transaction?</DialogContent>
        <DialogActions style={{ padding: '20px 40px' }}>
          <Button
            onClick={confirmApproveClicked}
            className={classes.modalPrimaryButton}>
            YES
          </Button>
          <Button onClick={close} className={classes.modalSecondaryButton}>
            NO
          </Button>
        </DialogActions>
      </Dialog>
      <React.Fragment>
        <ConfirmApproveModal
          open={openApprove}
          close={confirmApproveClose}
          request={request}
        />
      </React.Fragment>
    </div>
  )
}

export default ApproveModal
