import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Button, TextField, Grid, makeStyles } from '@material-ui/core'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { AdminStore } from '../../stores'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  modalView: {
    width: '32rem !important',
    height: '15rem'
  },
  ModalHead: {
    backgroundColor: '#111d4e',
    color: '#fff'
  },
  modalSecondaryButton: {
    backgroundColor: '#e4e4e4',
    padding: '5px 20px',
    borderRadius: '40px',
    color: '#111d4e',
    '&:hover': {
      backgroundColor: '#e4e4e4',
      color: '#111d4e'
    }
  },
  modalPrimaryButton: {
    backgroundColor: '#111d4e',
    padding: '5px 20px',
    borderRadius: '40px',
    color: '#e4e4e4',
    '&:hover': {
      backgroundColor: '#111d4e',
      color: '#e4e4e4'
    }
  },
  errMsg: {
    color: 'red'
  },
  Content:{
    padding:"30px 24px",
    overflowY:"hidden"
  }
}))

const ConfirmCancel = ({ open, close, request }) => {
  const classes = useStyles()
  const { cancelTransaction } = AdminStore.useStoreActions(actions => ({
    ...actions.doctorList
  }))


  const submit = () => {
    cancelTransaction({
      error_reason: formik.values.reason,
      request_id: request
    })
    close()
  }

  const formik = useFormik({
    initialValues: {
      reason: ''
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: submit
  })

  return (
    <div>
      <Dialog
        open={open}
        onClose={close}
        classes={{ paper: classes.modalView }}>
        <DialogTitle className={classes.ModalHead}>Details</DialogTitle>
        <DialogContent className={classes.Content}>
          <div>
            <form onSubmit={formik.handleSubmit}>
              <Grid>
                <TextField
                  id="reason"
                  style={{ width: '100%' }}
                  type="text"
                  placeholder="Cancellation Reason"
                  label="Cancellation Reason *"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik && formik.values && formik.values.reason}
                  name="reason"
                  helperText={
                    <div className={classes.errMsg}>
                      {formik.touched.reason && formik.errors.reason
                        ? formik.errors.reason
                        : null}
                    </div>
                  }
                />
              </Grid>
              <DialogActions style={{ padding: '40px 0px' }}>
                <Button type={'submit'} className={classes.modalPrimaryButton}>
                  Confirm Cancel
                </Button>
                <Button
                  onClick={close}
                  className={classes.modalSecondaryButton}>
                  Close
                </Button>
              </DialogActions>
            </form>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}

const validationSchema = yup.object().shape({
  reason: yup
    .string()
    .required('Cancellation Reason is required')
    .max(100, 'Maximum character limit exceeded')
})

export default ConfirmCancel
