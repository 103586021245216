import React, { useEffect } from 'react'
import {
  makeStyles,
  createMuiTheme,
  ThemeProvider
} from '@material-ui/core/styles'
import {
  Grid,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  InputAdornment
} from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import { AdminStore } from '../../stores'
import Loading from 'react-loading'
import { debounce } from 'lodash'
import NoRecordImg from '../../images/noRecordFound.png'
import { FaSearch } from 'react-icons/fa'
import { DOC_URL } from '../../Environment'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import LockIcon from '@material-ui/icons/Lock'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { NETWORK_STATUS } from '../../Constants'
import EditIcon from '@material-ui/icons/Edit'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ApproveModal from './ApproveModal'
import CancelModal from './CancelModal'
import SearchIcon from '@material-ui/icons/Search'
import SnackbarAlert from '../../components/commonComponents/SnackbarAlert'

// import { DOC_URL } from '../../Environment'

const columns = [
  {
    id: 'Clinic_slug',
    label: 'Clinic Slug',
    minWidth: 200
    //  align: 'center'
  },
  {
    id: 'Clinic_Name',
    label: 'Clinic Name',
    minWidth: 200
    //  align: 'center'
  },
  {
    id: 'wallet_balance',
    label: 'Wallet Balance',
    minWidth: 200
    // align: 'center'
  },
  {
    id: 'amount_requested',
    label: 'Amount Requested',
    minWidth: 200
    // align: 'center'
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 200
    //  align: 'center'
  },
  {
    id: 'actions',
    label: 'Action',
    minWidth: 100
  }
]

const useStyles = makeStyles({
  root: {
    width: '100%'
  },
  paper: {
    padding: '10px',
    '@media (max-width:767px)': {
      marginTop: '40px'
    }
  },
  container: {
    maxHeight: 440
  },
  loadingSpinner: {
    position: 'absolute',
    top: '42px',
    right: '150px',
    left: '430px',
    '@media (max-width:976px)': {
      right: '5px'
    }
  },
  tablecontent: {
    '& h6': {
      fontSize: '16px',
      padding: '15px 10px'
    },
    '& h5': {
      fontSize: '16px',
      fontWeight: 'bold',
      color: '#fff',
      margin: '0px'
    },
    '& p': {
      fontSize: '16px',
      marginBottom: '0px',
      fontWeight: '400'
    },
    '& .MuiTableCell-head': {
      color: '#fff',
      background: '#111d4e'
    },
    '& .MuiTableCell-root': {
      padding: '20px 10px'
    },
    '& .MuiPaper-rounded': {
      boxShadow: 'none !important',
      borderRadius: '0px !important'
    }
  },
  searchicon: {
    position: 'absolute',
    top: '40px',
    right: '175px',
    '@media (max-width:976px)': {
      right: '25px'
    }
  },
  Searchcol: {
    '@media (max-width:767px)': {
      marginBottom: '15px'
    }
  },
  modalFields: {
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '20px'
  },
  modalView: {
    width: '32rem !important',
    height: '35rem'
  },
  ModalHead: {
    backgroundColor: '#111d4e',
    color: '#fff'
  },
  modalSecondaryButton: {
    backgroundColor: '#e4e4e4',
    padding: '5px 20px',
    borderRadius: '40px',
    color: '#111d4e',
    '&:hover': {
      backgroundColor: '#e4e4e4',
      color: '#111d4e'
    }
  },
  loading: {
    position: 'relative',
    top: '50%',
    left: '42%'
  },
  Content: {
    height: '2px',
    '& .MuiDialogContent-root': {
      overflowY: 'hidden'
    }
  },
  DoctorSlug: {
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  statusCheckbox: {
    paddingTop: '10px',
    position: 'relative'
  },
  modalFields: {
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '20px'
  },
  modalView: {
    width: '32rem !important',
    height: '35rem'
  },
  ModalHead: {
    backgroundColor: '#111d4e',
    color: '#fff'
  },
  modalSecondaryButton: {
    backgroundColor: '#e4e4e4',
    padding: '5px 20px',
    borderRadius: '40px',
    color: '#111d4e',
    '&:hover': {
      backgroundColor: '#e4e4e4',
      color: '#111d4e'
    }
  },
  loading: {
    position: 'relative',
    top: '50%',
    left: '42%'
  },
  Content: {
    height: '2px',
    '& .MuiDialogContent-root': {
      overflowY: 'hidden'
    }
  }
})
const WalletList = () => {
  const classes = useStyles()
  const [page, setPage] = React.useState(0)
  // const [open, setOpen] = React.useState(false)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [walletsData, setWalletsData] = React.useState([])
  const [searchString, setSearchString] = React.useState('')
  const [statusVal, setStatusVal] = React.useState('all')
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [open, setOpen] = React.useState(false)
  const [openApproveModal, setOpenApproveModal] = React.useState(false)
  const [openCancelModal, setOpenCancelModal] = React.useState(false)
  const [requestID, setRequestID] = React.useState('')
  const [bankDetails, setBankDetails] = React.useState([])
  const [id, setID] = React.useState(null)
  const [snackBarMessage, setSnackBarMessage] = React.useState('')
  const [isSnackBarOpen, setOpenSnackBar] = React.useState(false)
  const [multiButton, setmultiButton] = React.useState(false)
  const [alertType, setAlertType] = React.useState('success')

  const opens = Boolean(anchorEl)

  const handleClose = () => {
    setOpen(false)
    setAnchorEl(null)
  }

  const { getWalletsList, ViewLock, clearSnack } = AdminStore.useStoreActions(
    actions => ({
      ...actions.doctorList
    })
  )

  const {
    walletsList,
    walletsRowCount,
    networkStatus,
    viewLockStatus,
    approveTrans,
    cancelTrans
  } = AdminStore.useStoreState(state => ({
    ...state.doctorList
  }))

  React.useEffect(() => {
    clearSnack()
  }, [])

  useEffect(() => {
    setWalletsData(walletsList)
  }, [walletsList])

  React.useEffect(() => {
    if (approveTrans == undefined) {
      //
    } else if (
      approveTrans.statusCode === 200 ||
      approveTrans.statusCode === 201
    ) {
      setOpenSnackBar(true)
      setSnackBarMessage('Request has been approved successfully...')
      setmultiButton(false)
      setAlertType('success')
    } else {
      setOpenSnackBar(true)
      setSnackBarMessage(approveTrans.message)
      setmultiButton(false)
      setAlertType('error')
    }
  }, [approveTrans])

  React.useEffect(() => {
    if (cancelTrans == undefined) {
      //
    } else if (
      cancelTrans.statusCode === 200 ||
      cancelTrans.statusCode === 201 
    ) {
      setOpenSnackBar(true)
      setSnackBarMessage('Request has been cancelled successfully...')
      setmultiButton(false)
      setAlertType('success')   
    } else {
      setOpenSnackBar(true)
      setSnackBarMessage(cancelTrans.message)
      setmultiButton(false)
      setAlertType('error')
    }
  }, [cancelTrans])

  React.useEffect(() => {
    if (viewLockStatus == undefined) {
      //
    } else if (
      viewLockStatus === 400 ||
      viewLockStatus === 533
    ) {
      setOpenSnackBar(true)
      setSnackBarMessage(viewLockStatus.message)
      setmultiButton(false)
      setAlertType('error')
    } else {
      setOpenSnackBar(true)
      setSnackBarMessage('Wallet Request has been updated successfully...')
      setmultiButton(false)
      setAlertType('success')
    }
  }, [viewLockStatus])

  const debouncedGetWalletsList = React.useCallback(
    debounce(
      searchString =>
        getWalletsList({
          pattern: searchString,
          table_config_page_no: page,
          table_config_rows_per_page: rowsPerPage,
          list_type: statusVal
        }),
      1000
    ),
    []
  )

  useEffect(() => {
    getWalletsList({
      pattern: searchString,
      table_config_page_no: page + 1,
      table_config_rows_per_page: rowsPerPage,
      list_type: statusVal
    })
  }, [rowsPerPage, page, statusVal])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = async event => {
    await setRowsPerPage(+event.target.value)
    await setPage(0)
  }

  const onSearchUsed = e => {
    setSearchString(e.target.value)
    debouncedGetWalletsList(e.target.value)
  }

  const [statusFilter, setStatusFilter] = React.useState({
    all: true,
    Cancelled: false,
    Completed: false,
    Requested: false,
    Approved: false
  })

  const statusFilterChange = e => {
    let value = e.target.checked
    setStatusVal(e.target.value)
    if (!value && e.target.name === 'all') {
      return
    } else if (!value) {
      setStatusFilter({
        all: true,
        Cancelled: false,
        Completed: false,
        Requested: false,
        Approved: false
      })
    } else {
      handleChangePage('', 0)
      let newFilter = statusFilter
      Object.keys(statusFilter).map(key => {
        newFilter[key] = false
      })
      setStatusFilter({ ...newFilter, [e.target.name]: e.target.checked })
    }
  }

  const editClicked = (e, data) => {
    setRequestID(data.wallet_request_payout_id)
    // console.log(data.wallet_request_payout_id)
    setAnchorEl(e.currentTarget)
  }

  const closeApproveModal = () => {
    setOpenApproveModal(false)
  }

  const closeCancelModal = () => {
    setOpenCancelModal(false)
  }

  const menuTheme = createMuiTheme({
    overrides: {
      MuiMenu: {
        paper: {
          boxShadow: 'none',
          border: '1px solid #000',
          marginRight: 10
        }
      }
    }
  })

  // To view the details of the data from api and open the Modal
  const viewModal = (event, data) => {
    setBankDetails(data)
    setOpen(!false)
  }

  const viewModaloff = e => {
    setID('')
    setOpen(false)
  }

  const viewModalClose = () => {
    setOpen(false)
  }

  const payoutLock = data => {
    setID(data[1])
    ViewLock({ clinic_id: data[0], wallet_request_payout_id: data[1] })
  }

  // To handle the Snackbar
  const hideSnackBar = () => {
    setOpenSnackBar(false)
  }

  return (
    <>
      <SnackbarAlert
        isOpen={isSnackBarOpen}
        message={snackBarMessage}
        alertType={alertType}
        multibutton={multiButton}
        primaryClick={hideSnackBar}
        autoHideDuration={5000}
      />
      <Paper elevation={3} className={classes.paper}>
        <React.Fragment>
          <ApproveModal
            open={openApproveModal}
            close={closeApproveModal}
            request={requestID}
          />
          <CancelModal
            open={openCancelModal}
            close={closeCancelModal}
            request={requestID}
          />
        </React.Fragment>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormControl component="fieldset" style={{ width: '100%' }}>
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  className={classes.formcol}
                  style={{ width: '100%' }}
                  control={
                    <TextField
                      id="standard-basic"
                      label="Search Doctor"
                      style={{ margin: '20px 30px', width: '400px' }}
                      value={searchString}
                      onChange={onSearchUsed}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        )
                      }}
                    />
                  }
                />
                {/* <FormControlLabel
                  className={classes.searchicon}
                  control={<FaSearch />}
                /> */}
                {networkStatus == 'FETCHING' ? (
                  <Loading
                    type="spin"
                    textalign="center"
                    color="grey"
                    height={20}
                    width={20}
                    className={classes.loadingSpinner}
                  />
                ) : null}
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              justifyContent: 'flex-end',
              display: 'flex',
              alignItems: 'center'
            }}>
            <div className={classes.statusCheckbox}>
              <FormControl component="fieldset">
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    value="all"
                    control={
                      <Checkbox
                        color="primary"
                        onChange={statusFilterChange}
                        name="all"
                      />
                    }
                    label="all"
                    labelPlacement="end"
                    checked={statusFilter.all}
                  />
                  <FormControlLabel
                    value="cancel"
                    control={
                      <Checkbox
                        color="primary"
                        onChange={statusFilterChange}
                        name="Cancelled"
                      />
                    }
                    label="Cancelled"
                    labelPlacement="end"
                    checked={statusFilter.Cancelled}
                  />
                  <FormControlLabel
                    value="approve"
                    control={
                      <Checkbox
                        color="primary"
                        onChange={statusFilterChange}
                        name="Completed"
                      />
                    }
                    label="Completed"
                    labelPlacement="end"
                    checked={statusFilter.Completed}
                  />
                  <FormControlLabel
                    value="in-progress"
                    control={
                      <Checkbox
                        color="primary"
                        onChange={statusFilterChange}
                        name="Requested"
                      />
                    }
                    label="In-Progress"
                    labelPlacement="end"
                    checked={statusFilter.Requested}
                  />
                </FormGroup>
              </FormControl>
            </div>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={12} className={classes.tablecontent}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow
                  style={{ background: 'rgb(16 29 71)', color: '#fff' }}>
                  {columns.map(column => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        maxWidth: column.maxWidth
                      }}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {walletsData == undefined ||
              (walletsData.length == 0 && networkStatus !== 'FETCHING') ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                  <img
                    alt="No data"
                    style={{ width: '200px' }}
                    src={NoRecordImg}
                  />
                </div>
              ) : (
                <TableBody>
                  {walletsData.slice(0, rowsPerPage).map(data => {
                    return (
                      <TableRow>
                        {columns.slice(0, columns.length - 1).map(column => {
                          const val = column.id
                          if (column.id === 'status') {
                            return (
                              <TableCell>
                                {data[val] === '1'
                                  ? 'Requested'
                                  : data[val] === '2'
                                  ? 'Completed'
                                  : data[val] === '5'
                                  ? 'In-Progress/Locked'
                                  : 'Cancelled'}
                              </TableCell>
                            )
                          } else if (val == 'doctor_slug') {
                            return (
                              <TableCell>
                                <a href={DOC_URL + data[val]} target="_blank">
                                  {data[val]}
                                </a>
                              </TableCell>
                            )
                          } else {
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {data[val]}
                              </TableCell>
                            )
                          }
                        })}
                        <TableCell
                          style={{
                            display: 'flex',
                            justifyContent: 'space-evenly'
                          }}>
                            { viewLockStatus != undefined ? (id == data.wallet_request_payout_id && viewLockStatus == 200 ?
                          <EditIcon
                            onClick={e => editClicked(e, data)}
                            style={{ color: '#9aca09', cursor: 'pointer' }}
                          /> : null) : null}
                          {id !== data.wallet_request_payout_id ? (
                            <VisibilityIcon
                              onClick={e => viewModal(e, data.bank_details)}
                              style={{ color: '#202020', cursor: 'pointer' }}
                            />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                          {id !== data.wallet_request_payout_id && (data.status != '2' && data.status !== '5') ? (
                            <LockOpenIcon
                              onClick={e =>
                                payoutLock([
                                  data.Clinic_Id,
                                  data.wallet_request_payout_id
                                ])
                              }
                              style={{ color: '#202020', cursor: 'pointer' }}
                            />
                          ) : (
                            <LockIcon
                              style={{ color: '#202020', cursor: 'pointer' }}
                              onClick={viewModaloff}
                            />
                          )}

                          <ThemeProvider theme={menuTheme}>
                            <Menu
                              id="fade-menu"
                              anchorEl={anchorEl}
                              keepMounted
                              open={opens}
                              onClose={handleClose}
                              PaperProps={{
                                style: {
                                  marginTop: 37.5
                                }
                              }}>
                              <MenuItem
                                onClick={() => setOpenApproveModal(true)}>
                                Approve
                              </MenuItem>
                              <MenuItem
                                onClick={() => setOpenCancelModal(true)}>
                                Cancel
                              </MenuItem>
                            </Menu>
                          </ThemeProvider>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Grid>

        {walletsData ? (
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={walletsRowCount !== undefined ? walletsRowCount : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        ) : null}
      </Paper>
      <React.Fragment>
        <Dialog
          open={open}
          classes={{ paper: classes.modalView }}
          onClose={open}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title" className={classes.ModalHead}>
            Bank Details
            {/* <span
         style={styles}
         onMouseEnter={() => setColor('red')}
         onMouseLeave={() => setColor('')}
         onClick={handleClose}>
         X
       </span> */}
          </DialogTitle>
          <DialogContent style={{ overflow: 'hidden' }}>
            {networkStatus === NETWORK_STATUS.FETCHING ? (
              <Loading
                type="bubbles"
                color="#9138aa"
                height={50}
                className={classes.loading}
              />
            ) : (
              <div style={{ padding: '80px 28px' }}>
                <div className={classes.modalFields}>
                  <b>Account Number:</b>
                  {bankDetails.primary_account_no}
                </div>
                <div className={classes.modalFields}>
                  <b>Bank Name:</b>
                  {bankDetails.primary_bank_name}
                </div>
                <div className={classes.modalFields}>
                  <b>Beneficiary:</b>
                  {bankDetails.primary_beneficiary_name}
                </div>
                <div className={classes.modalFields}>
                  <b>Branch Name:</b>
                  {bankDetails.primary_branch_name}
                </div>
                <div className={classes.modalFields}>
                  <b>IFSC Code:</b>
                  {bankDetails.primary_ifsc_code}
                </div>
              </div>
            )}
          </DialogContent>
          <DialogActions style={{ padding: '20px 40px' }}>
            <Button
              onClick={viewModalClose}
              className={classes.modalSecondaryButton}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </>
  )
}

export default WalletList
