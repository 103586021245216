import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  Footer: {
    width: '100%',
    background: '#272727',
    padding: '20px 60px',
    color: '#a4a4a4'
  }
}))
export default function Footer() {
  const classes = useStyles()
  return (
    <div className={classes.Footer}>
      © All Rights Reserved CareNow International
    </div>
  )
}
