import React, { useEffect } from 'react'
import {
  makeStyles,
  createMuiTheme,
  ThemeProvider
} from '@material-ui/core/styles'
import {
  Grid,
  FormControl,
  FormGroup,
  FormControlLabel,
  InputAdornment
} from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import EditIcon from '@material-ui/icons/Edit'
import VisibilityIcon from '@material-ui/icons/Visibility'
import TextField from '@material-ui/core/TextField'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { AdminStore } from '../../stores'
import Loading from 'react-loading'
import { useForm, Controller } from 'react-hook-form'
import { FaSearch } from 'react-icons/fa'
import { Typography } from '@material-ui/core'
import { debounce } from 'lodash'
import DoctorUpdate from './DoctorUpdate'
import UpdateBankDetails from './UpdateBankDetails'
import NoRecordImg from '../../images/noRecordFound.png'
import { setDriver } from 'localforage'
import ViewBankDetails from './ViewBankDetails'
import ViewDoctorDetails from './ViewDoctorDetails'
import { Box } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import Loader from '../../components/commonComponents/Loader'
import { NETWORK_STATUS } from '../../Constants'

const columns = [
  { id: 'name', label: 'Doctor Name', minWidth: 170 },
  { id: 'specialization', label: 'Specialization', minWidth: 100 },
  {
    id: 'mobile_no',
    label: 'Mobile Number',
    minWidth: 170,
    align: 'right'
  },
  {
    id: 'email',
    label: 'Email',
    minWidth: 170,
    align: 'center'
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 170,
    align: 'center'
  }
]

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  container: {
    maxHeight: 440
  },
  loadingSpinner: {
    position: 'absolute',
    top: '42px',
    right: '150px',
    left: '430px',
    '@media (max-width:976px)': {
      right: '5px'
    }
  },
  searchicon: {
    position: 'absolute',
    top: '40px',
    right: '175px',
    '@media (max-width:976px)': {
      right: '25px'
    }
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    padding: '10px',
    '@media (max-width:767px)': {
      marginTop: '40px'
    }
  },
  h2: {
    justifyContent: 'center',
    display: 'flex',
    color: '#3f4b79'
  },
  p: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between'
  },
  viewModal: {
    padding: theme.spacing(7, 4, 3)
  },
  editModal: {
    padding: theme.spacing(2, 4, 3)
  },
  button: {
    margin: theme.spacing(3, 0)
  },
  error: {
    color: 'error'
  },
  tablecontent: {
    '& h6': {
      fontSize: '16px',
      padding: '15px 10px'
    },
    '& h5': {
      fontSize: '16px',
      fontWeight: 'bold',
      color: '#fff',
      margin: '0px'
    },
    '& p': {
      fontSize: '16px',
      marginBottom: '0px',
      fontWeight: '400'
    },
    '& .MuiTableCell-head': {
      color: '#fff',
      background: 'rgb(16 29 71)'
    },
    '& .MuiTableCell-root': {
      padding: '20px 10px'
    },
    '& .MuiPaper-rounded': {
      boxShadow: 'none !important',
      borderRadius: '0px !important'
    }
  },
  Searchinput: {
    '& input': {
      padding: '0 40px 0 0'
    },
    '& .MuiInput-formControl': {
      marginTop: '30px'
    }
  },
  searchicon: {
    position: 'absolute',
    top: '40px',
    right: '10px',
    '@media (max-width:976px)': {
      right: '10px'
    }
  },
  Searchcol: {
    '@media (max-width:767px)': {
      marginBottom: '15px'
    }
  }
}))

const menuTheme = createMuiTheme({
  overrides: {
    MuiMenu: {
      paper: {
        boxShadow: 'none',
        border: '1px solid #000',
        marginRight: 10
      }
    }
  }
})

const DoctorList = ({ tabState }) => {
  const classes = useStyles()
  const { control, handleSubmit, register, errors } = useForm()
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [doctorData, setDoctorData] = React.useState([])
  const [searchString, setSearchString] = React.useState('')
  const [open, setOpen] = React.useState(false)
  const [docdetails, setDocdetails] = React.useState([])
  const [edit, setEdit] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [target, setTarget] = React.useState(null)
  const [id, setID] = React.useState(null)
  const [viewBankDetailsModal, setViewBankDetailsModal] = React.useState(false)
  const [viewDoctorDetailsModal, setViewDoctorDetailsModal] = React.useState(
    false
  )
  const opens = Boolean(anchorEl)

  const profilePopup = React.useRef(null)

  const bankPopup = React.useRef(null)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const { getMyDoctorsList } = AdminStore.useStoreActions(actions => ({
    ...actions.doctorList
  }))

  const {
    myDoctorsList,
    doctorsListRowCount,
    networkStatus,
    lastPage
  } = AdminStore.useStoreState(state => ({
    ...state.doctorList
  }))

  const debouncedGetMyDoctorList = React.useCallback(
    debounce(
      searchString =>
        getMyDoctorsList({
          pattern: searchString,
          table_config_page_no: 1,
          table_config_rows_per_page: rowsPerPage
        }),
      1000
    ),
    []
  )
  //initial undebounced call
  useEffect(() => {
    getMyDoctorsList({
      pattern: searchString,
      table_config_page_no: page + 1,
      table_config_rows_per_page: rowsPerPage
    })
  }, [rowsPerPage, page])

  //reset to 1st page on mount
  useEffect(() => {
    if (tabState == true) {
      setPage(0)
    }
  }, [tabState])

  useEffect(() => {
    setDoctorData(myDoctorsList)
  }, [myDoctorsList])

  const onSearchUsed = e => {
    setSearchString(e.target.value)
    debouncedGetMyDoctorList(e.target.value)
  }

  // To view the details of the data from api and open the Modal
  const viewModal = (event, data) => {
    if (data.status === '2') {
      setID('')
      setDocdetails(data)
      setAnchorEl(event.currentTarget)
    }
  }

  const closeBankDetailsModal = () => {
    setViewBankDetailsModal(false)
  }

  const closeDoctorDetailsModal = () => {
    setViewDoctorDetailsModal(false)
  }

  const editModal = (event, data, id) => {
    if (data.status === '2') {
      setID(id)
      setDocdetails(data)
      setAnchorEl(event.currentTarget)
    }
  }

  // Triggering the Child Component Button from the Parent
  const popupModal = e => {
    if (e.target.textContent === 'Edit Profile') {
      profilePopup.current.click()
    } else if (e.target.textContent === 'Bank Details Update') {
      bankPopup.current.click()
    } else if (e.target.textContent === 'Doctor Details') {
      setViewDoctorDetailsModal(true)
    } else if (e.target.textContent === 'Bank Details') {
      setViewBankDetailsModal(true)
    }
    setAnchorEl(null)
  }

  // Closes the Modal
  const handleClose = () => {
    setOpen(false)
    setAnchorEl(null)
  }

  // form submit
  const onSubmit = data => {
    setOpen(false)
  }

  return (
    <Paper elevation={3} className={classes.paper}>
      {networkStatus === NETWORK_STATUS.FETCHING ? (
        <Loader open={true} />
      ) : (
        <Loader />
      )}
      <React.Fragment>
        <DoctorUpdate
          ref={profilePopup}
          shortCode={docdetails.user_short_code}
          doctor_id={docdetails.doctor_id}
        />
        <UpdateBankDetails
          ref={bankPopup}
          user_master_id={docdetails.doctor_id}
        />
      </React.Fragment>

      <React.Fragment>
        <ViewBankDetails
          open={viewBankDetailsModal}
          close={closeBankDetailsModal}
          doctor_id={docdetails.doctor_id}
          shortCode={docdetails.user_short_code}
        />
        <ViewDoctorDetails
          open={viewDoctorDetailsModal}
          close={closeDoctorDetailsModal}
          shortCode={docdetails.user_short_code}
          doctor_id={docdetails.doctor_id}
        />
      </React.Fragment>
      {/* <div style={{ display: 'flex' }}>
        <TextField
          id="standard-basic"
          label="Search Doctor"
          style={{ margin: '20px 30px', width: '400px' }}
          value={searchString}
          onChange={onSearchUsed}
        />
        {networkStatus == 'FETCHING' ? (
          <Loading
            type="spin"
            textalign="center"
            color="grey"
            height={20}
            width={20}
            className={classes.loadingSpinner}
          />
        ) : null}
      </div> */}

      <Grid item xs={12} md={5}>
        <FormControl component="fieldset" style={{ width: '100%' }}>
          <FormGroup
            aria-label="position"
            row
            style={{ width: '100%', position: 'relative' }}>
            <FormControlLabel
              className={classes.formcol}
              style={{ width: '100%' }}
              control={
                <TextField
                  className={classes.Searchinput}
                  id="standard-basic"
                  label="Search Doctor"
                  style={{ margin: '20px 0 20px 20px', width: '100%' }}
                  value={searchString}
                  onChange={onSearchUsed}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <SearchIcon style={{ marginBottom: '15px' }} />
                      </InputAdornment>
                    )
                  }}
                />
              }
            />
            {/* <FormControlLabel
              className={classes.searchicon}
              control={<FaSearch />}
            /> */}
            {networkStatus == 'FETCHING' ? (
              <Loading
                type="spin"
                textalign="center"
                color="grey"
                height={20}
                width={20}
                className={classes.loadingSpinner}
              />
            ) : null}
          </FormGroup>
        </FormControl>
      </Grid>

      <Grid item xs={12} md={5}>
        <Box>
          <h2>Total Doctors: {doctorsListRowCount}</h2>
        </Box>
      </Grid>

      <Grid item xs={12} sm={12} md={12} className={classes.tablecontent}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            {viewBankDetailsModal === true || viewDoctorDetailsModal ? (
              <TableHead style={{ display: 'none' }}>
                <TableRow>
                  {columns.map(column => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}>
                      {column.label}
                    </TableCell>
                  ))}
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
            ) : (
              <TableHead>
                <TableRow>
                  {columns.map(column => (
                    <TableCell
                      key={column.id}
                      // align={column.align}
                      style={{ minWidth: column.minWidth }}>
                      {column.label}
                    </TableCell>
                  ))}
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
            )}

            <TableBody>
              {doctorData == undefined ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                  <img
                    alt="No data"
                    style={{
                      width: '200px',
                      left: '500px',
                      position: 'relative'
                    }}
                    src={NoRecordImg}
                  />
                </div>
              ) : (
                doctorData.map(data => {
                  return (
                    <TableRow>
                      <TableCell>{data.name}</TableCell>
                      <TableCell>{data.specialization}</TableCell>
                      <TableCell>{data.mobile_no}</TableCell>
                      <TableCell>{data.email}</TableCell>
                      <TableCell>
                        {data.status === '1' ? 'In-Active' : 'Active'}
                      </TableCell>
                      <TableCell align="center">
                        {data.status !== '1' && (
                          <EditIcon
                            onClick={(e, id) => editModal(e, data, (id = 1))}
                            style={{ color: '#9aca09', cursor: 'pointer' }}
                          />
                        )}{' '}
                        <ThemeProvider theme={menuTheme}>
                          <Menu
                            id="fade-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={opens}
                            onClose={handleClose}
                            PaperProps={{
                              style: {
                                marginTop: 37.5
                              }
                            }}>
                            <MenuItem onClick={e => popupModal(e)}>
                              {id ? 'Edit Profile' : 'Doctor Details'}
                            </MenuItem>
                            {/* <MenuItem onClick={e => popupModal(e)}>
                                {id ? 'Bank Details Update' : 'Bank Details'}
                              </MenuItem> */}
                            {/* <MenuItem onClick={handleClose}>Logout</MenuItem> */}
                          </Menu>
                        </ThemeProvider>
                        {data.status !== '1' && (
                          <VisibilityIcon
                            onClick={e => viewModal(e, data)}
                            style={{ color: '#202020', cursor: 'pointer' }}
                          />
                        )}
                        {data.status === '1' && (
                          <span style={{ textAlign: 'center' }}>N/A</span>
                        )}
                      </TableCell>
                    </TableRow>
                  )
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      {doctorData ? (
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          classes={{
            actions: classes.PageActions
          }}
          count={doctorsListRowCount !== undefined ? doctorsListRowCount : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            disabled: page === 0,
            style: { color: page === 0 ? '#b5b8c4' : '#000' }
          }}
          nextIconButtonProps={{
            disabled: page === lastPage - 1,
            style: { color: page === lastPage - 1 ? '#b5b8c4' : '#000' }
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      ) : null}
    </Paper>
  )
}

export default DoctorList
