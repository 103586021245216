import React, { useEffect } from 'react'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { AdminStore } from '../../stores'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Loading from 'react-loading'
import { NETWORK_STATUS } from '../../Constants'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  visibilityModal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '500px',
    zIndex: '1000005'
  },
  modalMain: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    width: '100%',
    height: '45%',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  modalFields: {
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '20px'
  },
  viewModalButton: {
    backgroundColor: '#3f4b79',
    color: 'white',
    borderRadius: 3,
    fontWeight: 200,
    padding: '0 30px',
    marginBottom: '10px',
    height: 40,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  modalView: {
    width: '32rem !important',
    height: '35rem'
  },
  ModalHead: {
    backgroundColor: '#111d4e',
    color: '#fff'
  },
  modalSecondaryButton: {
    backgroundColor: '#e4e4e4',
    padding: '5px 20px',
    borderRadius: '40px',
    color: '#111d4e',
    '&:hover': {
      backgroundColor: '#e4e4e4',
      color: '#111d4e'
    }
  },
  loading: {
    position: 'relative',
    top: '50%',
    left: '42%'
  },
  Content: {
    height: '2px',
    '& .MuiDialogContent-root': {
      overflowY: 'hidden'
    }
  }
}))

const ViewBankDetails = ({ open, doctor_id, shortCode, close }) => {
  const classes = useStyles()
  const [bankDetails, setBankDetails] = React.useState([])

  //Bank details
  const { getBankDetails } = AdminStore.useStoreActions(actions => ({
    ...actions.profile
  }))

  const { getBankList, networkStatus } = AdminStore.useStoreState(state => ({
    ...state.profile
  }))

  useEffect(() => {
    if (doctor_id !== undefined) {
      getBankDetails({ doctor_id: doctor_id })
    }
  }, [open])

  useEffect(() => {
    if (getBankList && getBankList.message) {
      setBankDetails(getBankList.message)
    }
  }, [getBankList])

  return (
    <div>
      <Dialog
        open={open}
        classes={{ paper: classes.modalView }}
        onClose={close}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" className={classes.ModalHead}>
          Bank Details
          {/* <span
            style={styles}
            onMouseEnter={() => setColor('red')}
            onMouseLeave={() => setColor('')}
            onClick={handleClose}>
            X
          </span> */}
        </DialogTitle>
        <DialogContent style={{ overflow: 'hidden' }}>
          {networkStatus === NETWORK_STATUS.FETCHING ? (
            <Loading
              type="bubbles"
              color="#9138aa"
              height={50}
              className={classes.loading}
            />
          ) : (
            <div style={{ padding: '80px 28px' }}>
              <div className={classes.modalFields}>
                <b>Account Number:</b>
                {bankDetails.primary_account_no}
              </div>
              <div className={classes.modalFields}>
                <b>Bank Name:</b>
                {bankDetails.primary_bank_name}
              </div>
              <div className={classes.modalFields}>
                <b>Beneficiary:</b>
                {bankDetails.primary_beneficiary_name}
              </div>
              <div className={classes.modalFields}>
                <b>Branch Name:</b>
                {bankDetails.primary_branch_name}
              </div>
              <div className={classes.modalFields}>
                <b>IFSC Code:</b>
                {bankDetails.primary_ifsc_code}
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions style={{ padding: '20px 40px' }}>
          <Button onClick={close} className={classes.modalSecondaryButton}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ViewBankDetails
