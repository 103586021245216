import { action, thunk } from 'easy-peasy'
import api from '../../api'
import {
  NETWORK_STATUS,
  HOSPITAL_ID,
  LOCAL_STORAGE_AUTHN_KEY_NAME
} from '../../Constants'
import localforage from 'localforage'
import BaseModel from '../Base'
import moment from 'moment'

export default {
  ...BaseModel(),
  myDoctorsList: [],
  hospitalAppointmentsList: [],
  appointmentsListRowCount: 0,
  doctorsListRowCount: 0,
  loadingHospitalAppointments: false,
  cancelAppointmentStatusCode: 0,
  cancelAppointmentMessage: '',
  walletsList: [],
  transactionsList: [],
  transactionsRowCount: 0,
  walletsRowCount: 0,
  clinicsList: [],
  clinicsListRowCount: 0,
  clinicAddedResponse: null,
  lastPage: 0,

  getMyDoctorsList: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    api.setAuthHeaders({
      authorization: localStorage.getItem('admin_id_token')
    })

    const response = await api.hospital.getMyDoctorsList({
      pattern: payload.pattern ? payload?.pattern : '',
      hospital_master_id: localStorage.getItem(HOSPITAL_ID),
      table_config_page_no: JSON.stringify(payload?.table_config_page_no),
      table_config_rows_per_page: JSON.stringify(
        payload?.table_config_rows_per_page
      )
    })

    if (response.ok) {
      actions.setMyDoctorsList(response.data.response)
      actions.setLastPage(response.data.total_pages_count)
      actions.setDoctorsListRowCount(response.data.total_rows_count)
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
    }
  }),

  setMyDoctorsList: action((state, payload) => {
    state.myDoctorsList = payload
  }),

  setLastPage: action((state, payload) => {
    state.lastPage = payload
  }),

  setDoctorsListRowCount: action((state, payload) => {
    state.doctorsListRowCount = payload
  }),

  getHospitalAppointments: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    api.setAuthHeaders({
      authorization: localStorage.getItem('admin_id_token')
    })

    const response = await api.hospital.getHospitalAppointments({
      hospital_id: localStorage.getItem(HOSPITAL_ID),
      table_config_rows_per_page: payload?.table_config_rows_per_page,
      table_config_page_no: payload?.table_config_page_no,
      table_config_search_string: payload?.table_config_search_string
    })

    if (response.ok) {
      actions.setHospitalAppointmentsList(response.data.result)
      actions.setAppointmentsListRowCount(response.data.total_rows_count)
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
    }
  }),

  setHospitalAppointmentsList: action((state, payload) => {
    state.hospitalAppointmentsList = payload
  }),

  setAppointmentsListRowCount: action((state, payload) => {
    state.appointmentsListRowCount = payload
  }),

  cancelHospitalAppointment: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    api.setAuthHeaders({
      authorization: localStorage.getItem('admin_id_token')
    })

    const response = await api.hospital.cancelHospitalAppointment({
      appointment_id: payload?.appointment_id,
      appointment_type: payload?.appointment_type
    })

    if (response.ok) {
      actions.setCancelAppointmentStatus(response.data)
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
    }
  }),

  setCancelAppointmentStatus: action((state, payload) => {
    state.cancelAppointmentStatusCode = payload
  }),

  clearSnack: action((state, payload) => {
    state.cancelAppointmentStatusCode = undefined
    state.approveTrans = undefined
    state.cancelTrans = undefined
    state.viewLockStatus = undefined
    state.clinicAddedResponse = null
  }),

  getWalletsList: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    api.setAuthHeaders({
      authorization: localStorage.getItem('admin_id_token')
    })

    const response = await api.hospital.getWalletList({
      pattern: payload?.pattern,
      list_type: 'all',
      hospital_master_id: localStorage.getItem(HOSPITAL_ID),
      table_config_page_no: payload?.table_config_page_no
        ? JSON.stringify(payload?.table_config_page_no)
        : '1',
      table_config_rows_per_page: payload?.table_config_rows_per_page
        ? JSON.stringify(payload?.table_config_rows_per_page)
        : '10',
      list_type: payload?.list_type
    })

    if (response.ok) {
      actions.setWalletsList(response.data.response)
      actions.setWalletsRowCount(response.data.total_rows_count)
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
    }
  }),

  setWalletsList: action((state, payload) => {
    state.walletsList = payload
  }),

  setWalletsRowCount: action((state, payload) => {
    state.walletsRowCount = payload
  }),

  getTransactionsList: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    api.setAuthHeaders({
      authorization: localStorage.getItem('admin_id_token')
    })

    const response = await api.hospital.getTransactions({
      hospital_master_id: localStorage.getItem(HOSPITAL_ID),
      pattern: payload?.pattern,
      search_by: 'Patient_name',
      table_config_rows_per_page: JSON.stringify(
        payload?.table_config_rows_per_page
      ),
      table_config_page_no: JSON.stringify(payload?.table_config_page_no)
    })

    if (response.ok) {
      actions.setTransactionsList(response.data.appointments_details)
      actions.setTransactionsRowCount(response.data.total_rows_count)
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
    }
  }),

  setTransactionsList: action((state, payload) => {
    state.transactionsList = payload
  }),

  setTransactionsRowCount: action((state, payload) => {
    state.transactionsRowCount = payload
  }),

  cancelTransaction: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
    api.setAuthHeaders({
      authorization: localStorage.getItem('admin_id_token')
    })

    const response = await api.hospital.cancelApprove({
      hospital_master_id: localStorage.getItem('user_details_id'),
      request_id: payload?.request_id,
      status_type: 'cancel',
      error_reason: payload?.error_reason
    })

    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.cancelTrans(response?.data)
    }
  }),

  cancelTrans: action((state, payload) => {
    state.cancelTrans = payload
  }),

  approveTransaction: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    api.setAuthHeaders({
      authorization: localStorage.getItem('admin_id_token')
    })

    const response = await api.hospital.cancelApprove({
      hospital_master_id: localStorage.getItem('user_details_id'),
      request_id: payload?.request_id,
      status_type: 'approve',
      admin_branch_name: payload?.branch_name,
      admin_account_no: payload?.account_no,
      admin_transaction_id: payload?.transaction,
      admin_ifsc_code: payload?.ifsc
    })

    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.approveTrans(response?.data)
    }
  }),

  approveTrans: action((state, payload) => {
    state.approveTrans = payload
  }),

  ViewLock: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
    const response = await api.hospital.payoutLock({
      clinic_id: payload?.clinic_id,
      wallet_request_payout_id: payload?.wallet_request_payout_id
    })

    if (response.ok) {
      actions.viewLock(response.data.statusCode)
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),

  viewLock: action((state, payload) => {
    state.viewLockStatus = payload
  }),

  getMyClinicsList: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
    api.setAuthHeaders({
      authorization: localStorage.getItem('admin_id_token')
    })

    const response = await api.hospital.getClinicsList({
      pattern: payload && payload.pattern ? payload.pattern : '',
      // hospital_master_id: localStorage.getItem(HOSPITAL_ID),
      doctor_id: '',
      table_config_page_no: JSON.stringify(payload?.table_config_page_no)
        ? JSON.stringify(payload?.table_config_page_no)
        : '1',
      table_config_rows_per_page: JSON.stringify(
        payload?.table_config_rows_per_page
          ? payload?.table_config_rows_per_page
          : 200
      )
    })

    if (response.ok) {
      actions.setMyClinicsList(response.data.data)
      actions.setClinicsRowCount(response.data.total_rows_count)
      actions.setLastPage(response.data.total_pages_count)
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
    }
  }),

  setMyClinicsList: action((state, payload) => {
    state.clinicsList = payload
  }),

  setClinicsRowCount: action((state, payload) => {
    state.clinicsListRowCount = payload
  }),

  addClinicProfile: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
    let event = new Date(payload.tnd)
    let date = moment(event).format('YYYY-MM-DD')

    const response = await api.hospital.addClinicProfile({
      name: payload.name,
      about: payload.about,
      address: payload.address,
      pincode: payload.pincode,
      city: payload.city_id,
      country: payload.country_id,
      state: payload.state_id,
      email: payload.email,
      phone_number: payload.phone_number,
      gst_no: payload.gst_no,
      registration_no: payload.registration_number,
      // active_subscription_plan_id: payload?.type,
      // status: '2',
      trial_end_date: date,
      url_slug_prefix: payload.url_slug_prefix,
      logo_image: payload.fileConverted,
      logo_image_name: payload?.file?.name,
      primary_account_no: payload.primary_account_no,
      primary_branch_name: payload.primary_branch_name,
      primary_ifsc_code: payload.primary_ifsc_code,
      primary_bank_name: payload.primary_bank_name,
      primary_beneficiary_name: payload.primary_beneficiary_name
    })

    if (response.ok && response.data.statusCode == 200) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
    actions.setClinicAddedResponse(response?.data)
  }),

  subscriptionPlanList: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
    const response = await api.hospital.getSubscriptionList({})
    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.getSubscriptionPlanList(response.data.response)
    } else {
      actions.getSubscriptionPlanList(null)
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),
  getSubscriptionPlanList: action((state, payload) => {
    state.getSubscriptionList = payload
  }),

  setClinicAddedResponse: action((state, payload) => {
    state.clinicAddedResponse = payload
  }),

  SubscriptionHistory: thunk(async (action, payload) => {
    api.setAuthHeaders({
      authorization: localStorage.getItem('admin_id_token')
    })
    action.updateNetworkStatus(NETWORK_STATUS.FETCHING)
    const response = await api.hospital.subscriptionhistory({
      clinic_id: '',
      pattern: payload.pattern ? payload?.pattern : '',
      table_config_page_no: JSON.stringify(payload?.table_config_page_no),
      table_config_rows_per_page: JSON.stringify(
        payload?.table_config_rows_per_page
      )
    })
    if (response.ok) {
      action.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      action.subscriptionhistoryList(response?.data)
    } else {
      action.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),

  subscriptionhistoryList: action((state, payload) => {
    state.subscriptionhistoryList = payload
  })
}
