import React, { useEffect } from 'react'
import Paper from '@material-ui/core/Paper'
import { Button, Avatar } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { AdminStore } from '../../stores'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Loading from 'react-loading'
import { NETWORK_STATUS } from '../../Constants'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  visibilityModal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '500px',
    zIndex: '1000005'
  },
  modalMain: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    width: '100%',
    height: '45%',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  avatarContainer: {
    width: '10rem',
    height: '10rem',
    margin: '0.8rem auto 0 auto'
  },
  modalFieldsContainer: {
    margin: '2.8rem 1rem 0.4rem 1rem',
  },
  modalFields: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 50%)',
    alignItems: 'start'
  },
  modalLabelField: {
    margin: '8px 0',
    fontWeight: 'bold'
  },
  modalValueField: {
    margin: '8px 0',
    maxWidth: '100%',
    overflowWrap: 'break-word',
    wordWrap: 'break-word'
  },
  viewModalButton: {
    backgroundColor: '#3f4b79',
    color: 'white',
    borderRadius: 3,
    fontWeight: 200,
    padding: '0 30px',
    marginBottom: '10px',
    height: 40,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  modalView: {
    width: '32rem !important',
    height: 'auto'
  },
  ModalHead: {
    backgroundColor: '#111d4e',
    color: '#fff'
  },
  modalSecondaryButton: {
    backgroundColor: '#e4e4e4',
    padding: '5px 20px',
    borderRadius: '40px',
    color: '#111d4e',
    '&:hover': {
      backgroundColor: '#e4e4e4',
      color: '#111d4e'
    }
  },
  loading: {
    position: 'relative',
    top: '50%',
    left: '42%'
  },
  AvatarCol: {
    border: '5px solid black',
    width: '100%',
    height: '100%'
  }
}))

const ViewDoctorDetails = ({ open, shortCode, doctor_id, close }) => {
  const classes = useStyles()
  const [doctorDetails, setDoctorDetails] = React.useState([])

  //Doctor Details
  const { getDoctorDetails } = AdminStore.useStoreActions(actions => ({
    ...actions.profile
  }))

  const { getDoctorList, networkStatus } = AdminStore.useStoreState(state => ({
    ...state.profile
  }))

  useEffect(() => {
    if (shortCode !== undefined && open) {
      getDoctorDetails({ user_short_code: shortCode })
    }
  }, [open])

  useEffect(() => {
    if (getDoctorList && getDoctorList.user_details) {
      setDoctorDetails(getDoctorList.user_details)
    }
  }, [getDoctorList])

  return (
    <Paper className={classes.root}>
      <Dialog
        open={open}
        classes={{ paper: classes.modalView }}
        onClose={close}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" className={classes.ModalHead}>
          Doctor Details
          {/* <span
            style={styles}
            onMouseEnter={() => setColor('red')}
            onMouseLeave={() => setColor('')}
            onClick={handleClose}>
            X
          </span> */}
        </DialogTitle>
        <DialogContent>
          {networkStatus === NETWORK_STATUS.FETCHING ? (
            <Loading
              type="bubbles"
              color="#9138aa"
              height={50}
              className={classes.loading}
            />
          ) : (
            <div>
              <div className={classes.avatarContainer}>
                <Avatar
                  className={classes.AvatarCol}
                  src={doctorDetails.picture}
                />
              </div>
              <div className={classes.modalFieldsContainer}>
              <div className={classes.modalFields}>
                <p className={classes.modalLabelField}>Name: </p>
                <p className={classes.modalValueField}>
                  {doctorDetails.prefix !== undefined
                    ? doctorDetails.prefix
                    : ''}{' '}
                  {doctorDetails.name}
                </p>
              </div>
              <div className={classes.modalFields}>
                <p className={classes.modalLabelField}>Qualification: </p>
                <p className={classes.modalValueField}>
                  {doctorDetails.qualification}
                </p>
              </div>
              <div className={classes.modalFields}>
                <p className={classes.modalLabelField}>Specialization: </p>
                <p className={classes.modalValueField}>
                  {doctorDetails.specialization}
                </p>
              </div>
              <div className={classes.modalFields}>
                <p className={classes.modalLabelField}>Experience: </p>
                <p className={classes.modalValueField}>
                  {doctorDetails.experience}
                </p>
              </div>
              <div className={classes.modalFields}>
                <p className={classes.modalLabelField}>Mobile No: </p>
                <p className={classes.modalValueField}>
                  {doctorDetails.mobile_no}
                </p>
              </div>
              <div className={classes.modalFields}>
                <p className={classes.modalLabelField}>Clinic: </p>
                <p className={classes.modalValueField}>
                  {doctorDetails.clinic_name}
                </p>
              </div>
              <div className={classes.modalFields}>
                <p className={classes.modalLabelField}>Clinic Address: </p>
                <p className={classes.modalValueField}>
                  {doctorDetails.clinic_address}
                </p>
              </div>
              </div>
              {/* <div className={classes.modalFields}>
                <b>Clinic:</b>
                {doctorDetails.clinic_name}, {doctorDetails.clinic_address}
              </div> */}
            </div>
          )}
        </DialogContent>
        <DialogActions style={{ padding: '0 40px 20px' }}>
          <Button onClick={close} className={classes.modalSecondaryButton}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  )
}

export default ViewDoctorDetails
