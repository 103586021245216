import React from 'react'
import { makeStyles, CssBaseline, Box } from '@material-ui/core'
import Footer from '../components/commonComponents/Footer'
import PublicNavbar from '../components/commonComponents/PublicNavbar'
import { AdminStore } from '../stores'
import { StylesProvider, ThemeProvider } from '../providers/ThemeProvider'

const Layout = ({ children, hideFooter, hideNavbar }) => {
  const classes = useStyles()

  return (
    <AdminStore.Provider>
      <StylesProvider>
        <ThemeProvider>
          <CssBaseline />
          {!hideNavbar && (
            <Box px={5}>
              <PublicNavbar />
            </Box>
          )}
          <div className={classes.body}>
            <div className={classes.content}>{children}</div>
            {!hideFooter && <Footer />}
          </div>
        </ThemeProvider>
      </StylesProvider>
    </AdminStore.Provider>
  )
}

const useStyles = makeStyles(() => ({
  body: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    minHeight: '100vh'
  },
  content: {
    flex: 1
  }
}))

export default Layout
