import React, { useEffect, useState, useRef } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { useHistory } from 'react-router-dom'
import Fade from '@material-ui/core/Fade'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { AdminStore } from '../../stores'
import profile from '../../images/profile-img.png'
import {
  Avatar,
  CircularProgress,
  FormControl,
  Dialog
} from '@material-ui/core'
import localforage from 'localforage'
import {
  VALIDATE_PHONE,
  VALIDATE_WHITESPACES,
  SINGLE_WHITESPACE
} from '../../Constants'
import { KeyboardDatePicker } from '@material-ui/pickers'
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { useDropzone } from 'react-dropzone'
import MuiAlert from '@material-ui/lab/Alert'
import SnackbarAlert from '../../components/commonComponents/SnackbarAlert'
import swal from 'sweetalert'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Slide from '@material-ui/core/Slide'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import copy from 'copy-to-clipboard'

function rand() {
  return Math.round(Math.random() * 20) - 10
}

let today = new Date() // today
// today.setDate(today.getDate() + 1)

function getModalStyle() {
  const top = 50 + rand()
  const left = 50 + rand()
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  }
}


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

// const singleSpaceRegex = /^[a-zA-Z0-9]+\s?[a-zA-Z0-9]+$/

const singleSpaceRegex = /^(\w+\s)*\w+$/

const AddClinic = React.forwardRef((props, ref) => {
  const [Image, previewImage] = useState('')
  const history = useHistory()
  const classes = useStyles()
  const theme = useTheme()
  const [modalStyle] = React.useState(getModalStyle)
  const [docfile, setDocFile] = useState('')
  const [name, setName] = useState(undefined)
  const [inputValue, setInputValue] = useState('')
  const [docdetails, setDocDetails] = React.useState(undefined)
  const [docName, setDocName] = useState('')
  const [Country, setCountry] = React.useState({})
  const [State, setState] = React.useState({})
  const [city, setCity] = React.useState({})
  const [multiButton, setmultiButton] = useState(false)
  const [alertType, setAlertType] = useState('')
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [isSnackBarOpen, setOpenSnackBar] = React.useState(false)
  const [filename, setFileName] = useState('')
  const [clinic, setClinic] = useState({})
  const [open, setOpen] = React.useState(false)

  // const [id, setSubId] = useState("")
  const [subscriptionList, setSubscriptionList] = useState([])

  const {
    fetchCountryList,
    fetchStateList,
    fetchCityList,
    clearState
  } = AdminStore.useStoreActions(actions => ({
    ...actions.profile
  }))

  const {
    addClinicProfile,
    getMyClinicsList,
    subscriptionPlanList
  } = AdminStore.useStoreActions(actions => ({
    ...actions.doctorList
  }))

  const { clinicAddedResponse, getSubscriptionList } = AdminStore.useStoreState(
    state => ({
      ...state.doctorList
    })
  )

  const {
    docdetailAddedStatus,
    networkStatus,
    countryList,
    stateList,
    cityList,
    fetchingCountry,
    fetchingCity,
    fetchingState
  } = AdminStore.useStoreState(state => ({
    ...state.profile
  }))

  React.useEffect(() => {
    fetchCountryList()
    subscriptionPlanList()
    setInputValue('')
    setState('')
    setCountry('')
    setCity('')
    formik.resetForm()
  }, [])

  useEffect(() => {
    if (getSubscriptionList == undefined) {
      //
    } else {
      setSubscriptionList(getSubscriptionList)
    }
  }, [getSubscriptionList])

  // React.useEffect(() => {
  //   if (clinicAddedResponse === undefined) {
  //     if (clinicAddedResponse.statusCode == 200) {
  //       // setAlertType('success')
  //        Swal.fire('Success', 'Clinic added successfully', 'success')
  //     } else {
  //       setAlertType('error')
  //     }
  //     // setOpenSnackBar(true)
  //     // setSnackBarMessage(clinicAddedResponse.message)
  //     // setmultiButton(false)
  //     let copied = new Promise((res, rej) => {
  //       try {
  //         copy(clinicAddedResponse.password, {
  //           message: 'Password has been copied to clipboard'
  //         })
  //         res(true)
  //       } catch {
  //         rej(false)
  //       }
  //     })
  //     copied.then(() =>
  //       setCopiedMessage({
  //         isOpen: true,
  //         message: 'Password has been copied to clipboard',
  //         alertType: 'success',
  //       })
  //     )
  //   }
  // }, [clinicAddedResponse])

  React.useEffect(() => {
    if (clinicAddedResponse === null || clinicAddedResponse === '') {
      //
    } else if (
      clinicAddedResponse.statusCode === 400 ||
      clinicAddedResponse.statusCode === 515
    ) {
      setOpenSnackBar(true)
      setSnackBarMessage(clinicAddedResponse.message.replace(/['"]+/g, ''))
      // setSnackBarMessage('Patient profile not updated successfully')
      setmultiButton(false)
      setAlertType('error')
    } else if (
      clinicAddedResponse.statusCode === 200 ||
      clinicAddedResponse.statusCode === 201
    ) {
      setOpenSnackBar(true)
      setSnackBarMessage('Clinic Account created successfully')
      // setSnackBarMessage('Patient profile not updated successfully')
      setmultiButton(false)
      setAlertType('success')

      // if (clinicAddedResponse.password) {
      //   copy(clinicAddedResponse.password)
      //   setOpenSnackBar(true)
      //   setSnackBarMessage('Your password is in stored in your clipboard')
      //   // setSnackBarMessage('Patient profile not updated successfully')
      //   setmultiButton(false)
      //   setAlertType('info')
      // }
      setOpen(false)
      formik.resetForm()
      getMyClinicsList()
      setInputValue('')
      previewImage('')
      setState('')
      setCountry('')
      setCity('')
    }
  }, [clinicAddedResponse])

  const submit = async values => {
    const file = values.file
    console.log('Clinic Form Created')
    if (file) {
      if (
        file.name.split('.').pop().search('pdf') === 0 ||
        file.name.split('.').pop().search('docx') === 0
      ) {
        setOpenSnackBar(true)
        setSnackBarMessage(
          'Pdf and Docx file are not supported for profile picture!'
        )
        setmultiButton(false)
        setAlertType('info')
      } else if (
        file.name.split('.').pop().search('pdf') !== 0 ||
        file.name.split('.').pop().search('docx') !== 0
      ) {
        let base64 = await convertBase64(file)

        addClinicProfile({
          ...values,
          file,
          fileConverted: base64
        })
      }
    } else {
      setOpenSnackBar(true)
      setSnackBarMessage('Please upload a logo for your clinic')
      setmultiButton(false)
      setAlertType('info')
    }
    if (values.phone_number && isValidPhoneNumber(values.phone_number)) {
      // console.log('true')
    } else {
      swal(
        'Wrong!',
        'The mobile number entered is not a valid number!',
        'error'
      )
    }
  }

  const convertBase64 = file => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result)
      }

      fileReader.onerror = error => {
        reject(error)
      }
    })
  }

  const formik = useFormik({
    initialValues: {
      name: '',
      about: '',
      address: '',
      pincode: '',
      phone_number: '',
      email: '',
      gst_no: '',
      registration_number: '',
      pincode: '',
      file: null,
      url_slug_prefix: '',
      primary_account_no: '',
      confirm_account_no: '',
      primary_branch_name: '',
      primary_ifsc_code: '',
      primary_bank_name: '',
      primary_beneficiary_name: '',
      country_id: '',
      state_id: '',
      city_id: '',
      tnd: today,
      type: ''
    },

    //Control whether Formik should reset the form if initialValues changes (enableReinitialize)
    enableReinitialize: true,
    validationSchema,
    onSubmit: submit
  })

  const hiddenFileInput = useRef()
  const save = useRef()

  const handleClick = event => {
    // event.stopPropagation()
    hiddenFileInput.current.value = null
    hiddenFileInput.current.click()
  }

  const handleChange = event => {
    if (event.target.files[0] == undefined) {
    } else {
      if (event.target.files[0].size / 1000000 <= 4) {
        formik.setFieldValue('file', event.currentTarget.files[0])
        setFileName(event.currentTarget.files[0].name)
        event.preventDefault()

        // setDocName(event?.currentTarget?.files[0]?.name)
        let reader = new FileReader()
        let fileimage = event.target.files[0]

        reader.onloadend = () => {
          previewImage(reader.result)
        }
        reader.readAsDataURL(fileimage)
      } else {
        setOpenSnackBar(true)
        setSnackBarMessage(`Oops, File is larger than 4000000 bytes`)
        setmultiButton(false)
        setAlertType('info')
        // formik.setFieldValue('file', null)
        return
      }
    }
  }
  const customStyles = {
    height: '100%',
    position: 'absolute',
    top: '0',
    right: '0',
    zIndex: '99',
    cursor: 'pointer',
    display: 'none',
    /*This makes the button huge. If you want a bigger button, increase the font size*/
    fontSize: '50px',
    /*Opacity settings for all browsers*/
    opacity: '0',
    mozOpacity: '0',
    msFilter: 'progid:DXImageTransform.Microsoft.Alpha(opacity=0)'
  }

  const maxSize = 1048576

  const {
    acceptedFiles,
    fileRejections,
    isDragActive,
    isDragReject,
    getRootProps,
    getInputProps
  } = useDropzone({
    accept: '.jpeg, .png , .pdf , .jpg , .docx',
    minSize: 0,
    maxSize: 4000000
  })

  React.useEffect(() => {
    if (acceptedFiles) {
      acceptedFiles.map(file => {
        console.log(file, 'FILE ACCEPTED')
        setDocName(file?.name)
        setDocFile(file)
      })
    }
  }, [acceptedFiles])

  React.useEffect(() => {
    if (fileRejections) {
      fileRejections.map(file => {
        // console.log(file, "FILE REJECTED")
        setOpenSnackBar(true)
        setSnackBarMessage(`Oops, ${file.errors[0].message}`)
        setmultiButton(false)
        setAlertType('info')
        // return Swal.fire('Oops', file.errors[0].code.split('-').join(' '),'info')
        // setDocName(file?.name)
        // setDocFile(file)
      })
    }
  }, [fileRejections])

  //on mount country, state and city init values to empty
  React.useEffect(() => {
    formik.setFieldValue('country_id', '')
    formik.setFieldValue('state_id', '')
    formik.setFieldValue('city_id', '')
    formik.setFieldValue('tnd', today)
  }, [])

  // Just an utility function that doesn't allow users to enter white spaces in the input fields
  const dontAllowWhiteSpaces = (event, whiteSpacesInBetweenTextAllowed = 0) => {
    const value = event.currentTarget.value
    if (value.startsWith(' ')) {
      event.currentTarget.value = ''
      return
    }
    event.currentTarget.value = value.replace(
      ' '.repeat(whiteSpacesInBetweenTextAllowed + 1),
      ' '.repeat(whiteSpacesInBetweenTextAllowed)
    )
  }

  const nameChangeHandler = event => {
    dontAllowWhiteSpaces(event, 1)
    formik.handleChange(event)
  }

  const pincodeChangeHandler = event => {
    dontAllowWhiteSpaces(event)
    formik.handleChange(event)
  }

  const countryChange = (event, value) => {
    if (value == null) {
      setState('')
      setCountry('')
      setCity('')
      clearState()
      formik.setFieldValue('country_id', '')
    } else {
      setCountry(value)
      fetchStateList(value?.id)
      // setCountry(value)
      // setCountrySelect(true);
      formik.setFieldValue('country_id', value?.id)
      formik.setFieldValue('state_id', '')
      formik.setFieldValue('city_id', '')
    }
  }

  const stateChange = (event, value) => {
    // setStateSelect(true);
    if (value == null) {
      setCity('')
      setState('')
      fetchCityList('')
      clearState()
      formik.setFieldValue('state_id', '')
      formik.setFieldValue('city_id', '')
    } else {
      setState(value)
      fetchCityList(value?.id)
      formik.setFieldValue('state_id', value?.id)
      formik.setFieldValue('city_id', '')
      setCity('')
    }
  }

  const cityChange = (event, value) => {
    // setCitySelect(true);
    setCity(value)
    formik.setFieldValue('city_id', value?.id)
  }

  const clinicChange = (event, value) => {
    setClinic(value)
    formik.setFieldValue('clinic_list', value?.id)
  }

  const phoneChange = value => {
    setInputValue(value)
    formik.setFieldValue('phone_number', value ? value : '')
  }

  const showSnackBar = () => {
    setOpenSnackBar(true)
  }

  const hideSnackBar = () => {
    setOpenSnackBar(false)
  }

  const Input = e => {
    e.preventDefault()
    setOpenSnackBar(true)
    setSnackBarMessage('Paste option disabled...')
    setmultiButton(false)
    setAlertType('info')
  }

  const subscriptionChange = (e, value) => {
    let id = e.target.value
    formik.setFieldValue('type', id)
  }

  const handleClickClose = () => {
    setOpen(false)
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleSave = () => {
    // setOpen(false);
    save.current.click()
  }

  return (
    <>
      <SnackbarAlert
        isOpen={isSnackBarOpen}
        message={snackBarMessage}
        alertType={alertType}
        multibutton={multiButton}
        primaryClick={hideSnackBar}
      />
      <Button
        ref={ref}
        className={classes.Modal_popup}
        onClick={handleClickOpen}>
        Open full-screen dialog
      </Button>

      <Dialog
        fullScreen
        open={open}
        onClose={handleClickClose}
        TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              className={classes.closebtn}
              edge="start"
              onClick={handleClickClose}
              aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Add new clinic
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={handleSave}
              style={{
                border: '1px rgba(255,255,255, 0.5) solid',
                borderRadius: '40px',
                padding: '5px 20px'
              }}>
              <b>save</b>
            </Button>
          </Toolbar>
        </AppBar>

        <div style={{ padding: '40px' }}>
          <Paper elevation={3} className={classes.paper}>
            <form
              onSubmit={formik.handleSubmit}
              noValidate={true}
              className={classes.root}>
              <h2>Clinic Details</h2>

              <Grid container spacing={3}>
                <Grid item xs={12} md={8}>
                  {/*  */}
                  <Grid container spacing={3}>
                    <Grid item xs={5}>
                      <TextField
                        id="name"
                        style={{ width: '100%' }}
                        type="text"
                        placeholder="Name"
                        onChange={nameChangeHandler}
                        value={formik && formik.values && formik.values.name}
                        onBlur={formik.handleBlur}
                        name="name"
                        label="Name *"
                        helperText={
                          <div className={classes.errMsg}>
                            {formik.touched.name && formik.errors.name
                              ? formik.errors.name
                              : null}
                          </div>
                        }
                      />
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={5}>
                      <TextField
                        id="registration_number"
                        style={{ width: '100%' }}
                        type="text"
                        placeholder="Registration Number"
                        value={
                          formik &&
                          formik.values &&
                          formik.values.registration_number
                        }
                        name="registration_number"
                        label="Registration Number*"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        // disabled={true}
                        helperText={
                          <div className={classes.errMsg}>
                            {formik.touched.registration_number &&
                            formik.errors.registration_number
                              ? formik.errors.registration_number
                              : null}
                          </div>
                        }
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <TextField
                        id="url_slug_prefix"
                        style={{ width: '100%' }}
                        type="text"
                        placeholder="URL Prefix*"
                        onChange={formik.handleChange}
                        value={
                          formik &&
                          formik.values &&
                          formik.values.url_slug_prefix
                        }
                        onBlur={formik.handleBlur}
                        name="url_slug_prefix"
                        label="URL Prefix*"
                        helperText={
                          <div className={classes.errMsg}>
                            {formik.touched.url_slug_prefix &&
                            formik.errors.url_slug_prefix
                              ? formik.errors.url_slug_prefix
                              : null}
                          </div>
                        }
                      />
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={5}>
                      <TextField
                        id="gst_no"
                        style={{ width: '100%' }}
                        type="text"
                        placeholder="GST Number"
                        value={formik && formik.values && formik.values.gst_no}
                        name="gst_no"
                        label="GST Number"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        // disabled={true}
                        helperText={
                          <div className={classes.errMsg}>
                            {formik.touched.gst_no && formik.errors.gst_no
                              ? formik.errors.gst_no
                              : null}
                          </div>
                        }
                      />
                    </Grid>
                    <Grid item xs={1}></Grid>

                    <Grid item xs={5}>
                      <TextField
                        id="about"
                        style={{ width: '100%' }}
                        placeholder="About"
                        name="about"
                        label="About *"
                        value={formik && formik.values && formik.values.about}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        helperText={
                          <div className={classes.errMsg}>
                            {formik.touched.about && formik.errors.about
                              ? formik.errors.about
                              : null}
                          </div>
                        }
                      />
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={5}>
                      <TextField
                        id="email"
                        style={{ width: '100%' }}
                        placeholder="jane@gmail.com"
                        name="email"
                        label="Email *"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik && formik.values && formik.values.email}
                        helperText={
                          <div className={classes.errMsg}>
                            {formik.touched.email && formik.errors.email
                              ? formik.errors.email
                              : null}
                          </div>
                        }
                      />
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={5}>
                      <PhoneInput
                        placeholder="Enter mobile number"
                        international
                        defaultCountry="IN"
                        id="phone_number"
                        value={inputValue}
                        className={classes.inputChange}
                        name="phone_number"
                        onChange={phoneChange}
                        // onChange={inputValue => console.log(inputValue)}
                        error={
                          inputValue
                            ? isValidPhoneNumber(inputValue)
                              ? undefined
                              : 'Invalid phone number'
                            : 'Phone number required'
                        }
                      />
                      {
                        <div className={classes.errMsg2}>
                          {formik.touched.phone_number &&
                          formik.errors.phone_number
                            ? formik.errors.phone_number
                            : null}
                        </div>
                      }
                    </Grid>
                    <Grid item xs={1}></Grid>

                    <Grid item xs={5}>
                      <TextField
                        id="address"
                        style={{ width: '100%' }}
                        type="text"
                        placeholder="Address"
                        value={formik && formik.values && formik.values.address}
                        name="address"
                        label="Address *"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        // disabled={true}
                        helperText={
                          <div className={classes.errMsg}>
                            {formik.touched.address && formik.errors.address
                              ? formik.errors.address
                              : null}
                          </div>
                        }
                      />
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={5}>
                      <TextField
                        id="pincode"
                        style={{ width: '100%' }}
                        type="text"
                        placeholder="600037"
                        label="Pincode *"
                        onChange={pincodeChangeHandler}
                        onBlur={formik.handleBlur}
                        value={formik && formik.values && formik.values.pincode}
                        name="pincode"
                        helperText={
                          <div className={classes.errMsg}>
                            {formik.touched.pincode && formik.errors.pincode
                              ? formik.errors.pincode
                              : null}
                          </div>
                        }
                      />
                    </Grid>

                    <Grid item xs={1}></Grid>
                    <Grid item xs={5}>
                      <FormControl className={classes.formControl}>
                        <Autocomplete
                          id="size-small-outlined"
                          size="small"
                          style={{ width: '100%' }}
                          options={countryList}
                          name="country_id"
                          getOptionLabel={option => option.name}
                          onChange={countryChange}
                          value={Country}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="Country *"
                              loading={fetchingCountry}
                              style={{ width: '100%' }}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {fetchingCountry ? (
                                      <CircularProgress
                                        color="inherit"
                                        size={20}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                )
                              }}
                            />
                          )}
                        />
                        <div className={classes.errMsg2}>
                          {formik.touched.country_id && formik.errors.country_id
                            ? formik.errors.country_id
                            : null}
                        </div>
                      </FormControl>
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={5}>
                      <FormControl className={classes.formControl}>
                        <Autocomplete
                          id="size-small-outlined"
                          size="small"
                          name="state_id"
                          style={{ width: '100%' }}
                          options={stateList}
                          getOptionLabel={option => option.name}
                          onChange={stateChange}
                          value={State}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="State *"
                              loading={fetchingState}
                              style={{ width: '100%' }}
                              // error={
                              //   (formik.errors.state_id, formik.touched.state_id)
                              // }
                              // helperText={
                              //   (formik.errors.state_id,
                              //   formik.touched.state_id
                              //     ? formik.errors.state_id
                              //     : null)
                              // }
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {fetchingState ? (
                                      <CircularProgress
                                        color="inherit"
                                        size={20}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                )
                              }}
                            />
                          )}
                        />

                        <div className={classes.errMsg2}>
                          {formik.touched.state_id && formik.errors.state_id
                            ? formik.errors.state_id
                            : null}
                        </div>
                        {/* <div className={classes.errMsg2}>
                    {formik?.values?.state_id === undefined ? (
                      <p>
                        <b>{'State is required'}</b>
                      </p>
                    ) : null}
                  </div> */}
                      </FormControl>
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={12} sm={5}>
                      <FormControl className={classes.formControl}>
                        <Autocomplete
                          id="asynchronous-demo"
                          style={{ width: '100%' }}
                          options={cityList}
                          name="city_id"
                          getOptionLabel={option => option.name}
                          getOptionSelected={(option, value) =>
                            option.name === value.name
                          }
                          onChange={cityChange}
                          value={city}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="City *"
                              variant="standard"
                              loading={fetchingCity}
                              margin="normal"
                              style={{ width: '100%' }}
                              // error={(formik.errors.city_id, formik.touched.city_id)}
                              // helperText={
                              //   (formik.errors.city_id,
                              //   formik.touched.city_id ? formik.errors.city_id : null)
                              // }
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {fetchingCity ? (
                                      <CircularProgress
                                        color="inherit"
                                        size={20}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                )
                              }}
                            />
                          )}
                        />

                        <div className={classes.errMsg2}>
                          {formik.touched.city_id && formik.errors.city_id
                            ? formik.errors.city_id
                            : null}
                        </div>
                        {/* <div className={classes.errMsg2}>
                    {formik?.values?.city_id === undefined ? (
                      <p>
                        <b>{'City is required'}</b>
                      </p>
                    ) : null}
                  </div> */}
                      </FormControl>
                    </Grid>
                    {/* </Grid> */}
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Box
                    style={{
                      width: '140px',
                      height: '140px',
                      background: 'white',
                      borderRadius: '50%',
                      boxShadow: `0 1px 1px rgba(0,0,0,0.12), 
                              0 2px 2px rgba(0,0,0,0.12), 
                              0 4px 4px rgba(0,0,0,0.12), 
                              0 8px 8px rgba(0,0,0,0.12),
                              0 16px 16px rgba(0,0,0,0.12)`,
                      margin: '25px auto',
                      textAlign: 'right',
                      padding: '10px',
                      display: 'flex'
                    }}>
                    {Image ? (
                      <Avatar className={classes.AvatarCol} src={Image} />
                    ) : (
                      <Avatar className={classes.AvatarCol} src={profile} />
                    )}
                  </Box>
                  <label
                    style={{
                      textAlign: 'center',
                      margin: '20px auto',
                      display: 'block',
                      fontWeight: 'bold',
                      width: '150px'
                    }}>
                    <div>
                      <Button
                        onClick={handleClick}
                        style={{
                          width: '140px',
                          borderRadius: '50px',
                          background: '#111d4e',
                          border: 'none',
                          height: '40px',
                          textAlign: 'center',
                          color: '#fff',
                          // lineHeight: '40px',
                          cursor: 'pointer'
                        }}>
                        Select
                      </Button>
                      <input
                        id="file"
                        name="file"
                        type="file"
                        accept="image/jpeg, image/png, image/jpg"
                        ref={hiddenFileInput}
                        style={customStyles}
                        onChange={handleChange}
                        // value={formik?.values?.file}
                        className="form-control"
                      />
                    </div>
                  </label>
                </Grid>
              </Grid>

              <h2>Plan Subscription</h2>
              <Grid item xs={12} md={8}>
                <Grid container spacing={3}>
                  <Grid item xs={5}>
                    <KeyboardDatePicker
                      variant="inline"
                      format="DD-MM-YYYY"
                      margin="normal"
                      minDate={today}
                      name="tnd"
                      label="Trial End date"
                      value={formik?.values?.tnd}
                      onChange={value => formik.setFieldValue('tnd', value)}
                      onBlur={formik.handleBlur}
                      KeyboardButtonProps={{
                        'aria-label': 'change date'
                      }}
                      helperText={
                        <div className={classes.errMsg}>
                          {formik.touched.tnd && formik.errors.tnd
                            ? formik.errors.tnd
                            : null}
                        </div>
                      }
                      autoOk={true}
                    />
                  </Grid>
                  <Grid item xs={1} />

                  <Grid item xs={12} sm={5}>
                    {/* <Box style={{ marginTop: '-40px' }}>
                    <p>Subscription Type:</p>
                    <select
                      id="type"
                      className={classes.Input1}
                      value={formik.values.type}
                      name="type"
                      onChange={subscriptionChange}
                      onBlur={formik.handleBlur}
                      helperText={
                        <div className={classes.errMsg}>
                          {formik.touched.type && formik.errors.type
                            ? formik.errors.type
                            : null}
                        </div>
                      }>
                      {subscriptionList.map((d, index) => {
                        return (
                          <>
                            <option key={index} value={d.id}>
                              {d.display_name}
                            </option>
                          </>
                        )
                      })}

                    </select>
                  </Box> */}
                  </Grid>
                </Grid>
              </Grid>

              <h2>Bank Details</h2>
              <Grid item xs={12} md={8}>
                <Grid container spacing={3}>
                  <Grid item xs={5}>
                    <TextField
                      id="primary_account_no"
                      style={{ width: '100%' }}
                      // type="password"
                      type={'password'}
                      placeholder="Account No"
                      onChange={formik.handleChange}
                      value={
                        formik &&
                        formik.values &&
                        formik.values.primary_account_no
                      }
                      onBlur={formik.handleBlur}
                      name="primary_account_no"
                      label="Account No *"
                      helperText={
                        <div className={classes.errMsg}>
                          {formik.touched.primary_account_no &&
                          formik.errors.primary_account_no
                            ? formik.errors.primary_account_no
                            : null}
                        </div>
                      }
                    />
                    <TextField
                      id="confirm_account_no"
                      style={{ width: '100%' }}
                      placeholder="Confirm Account No"
                      name="confirm_account_no"
                      label="Confirm Account No *"
                      onPaste={Input}
                      type="password"
                      value={
                        formik &&
                        formik.values &&
                        formik.values.confirm_account_no
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      helperText={
                        <div className={classes.errMsg}>
                          {formik.touched.confirm_account_no &&
                          formik.errors.confirm_account_no
                            ? formik.errors.confirm_account_no
                            : null}
                        </div>
                      }
                    />
                    <TextField
                      id="primary_bank_name"
                      style={{ width: '100%' }}
                      type="text"
                      placeholder="Bank Name"
                      label="Bank Name *"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={
                        formik &&
                        formik.values &&
                        formik.values.primary_bank_name
                      }
                      name="primary_bank_name"
                      helperText={
                        <div className={classes.errMsg}>
                          {formik.touched.primary_bank_name &&
                          formik.errors.primary_bank_name
                            ? formik.errors.primary_bank_name
                            : null}
                        </div>
                      }
                    />
                  </Grid>
                  <Grid item xs={1} />

                  <Grid item xs={12} sm={5}>
                    <TextField
                      id="primary_ifsc_code"
                      style={{ width: '100%' }}
                      type="text"
                      placeholder="IFSC Code"
                      value={
                        formik &&
                        formik.values &&
                        formik.values.primary_ifsc_code
                      }
                      name="primary_ifsc_code"
                      label="IFSC Code *"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      // disabled={true}
                      helperText={
                        <div className={classes.errMsg}>
                          {formik.touched.primary_ifsc_code &&
                          formik.errors.primary_ifsc_code
                            ? formik.errors.primary_ifsc_code
                            : null}
                        </div>
                      }
                    />
                    <TextField
                      id="primary_beneficiary_name"
                      style={{ width: '100%' }}
                      placeholder="Beneficiary Name"
                      name="primary_beneficiary_name"
                      label="Beneficiary Name *"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={
                        formik &&
                        formik.values &&
                        formik.values.primary_beneficiary_name
                      }
                      helperText={
                        <div className={classes.errMsg}>
                          {formik.touched.primary_beneficiary_name &&
                          formik.errors.primary_beneficiary_name
                            ? formik.errors.primary_beneficiary_name
                            : null}
                        </div>
                      }
                    />

                    <TextField
                      id="primary_branch_name"
                      style={{ width: '100%' }}
                      type="text"
                      placeholder="Branch Name "
                      label="Branch Name  *"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={
                        formik &&
                        formik.values &&
                        formik.values.primary_branch_name
                      }
                      name="primary_branch_name"
                      helperText={
                        <div className={classes.errMsg}>
                          {formik.touched.primary_branch_name &&
                          formik.errors.primary_branch_name
                            ? formik.errors.primary_branch_name
                            : null}
                        </div>
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <button
                      type={'submit'}
                      className={classes.Modal_popup}
                      ref={save}>
                      Save
                    </button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
            {/* </Box> */}
          </Paper>
        </div>
      </Dialog>
    </>
  )
})

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .required('Name is required')
    // .matches(/^([^0-9]*)$/, 'Digits are not allowed')
    .max(30, 'Max 30 characters allowed')
    .matches(VALIDATE_WHITESPACES, 'Remove extra spaces'),

  url_slug_prefix: yup
    .string()
    .required('Prefix is required')
    // .matches(/^([^0-9]*)$/, 'Digits are not allowed')
    // .max(10, 'Max 10 characters allowed')
    .matches(VALIDATE_WHITESPACES, 'Remove extra spaces'),

  about: yup
    .string()
    .required('About is required')
    // .matches(/^([^0-9]*)$/, 'Digits are not allowed')
    .max(50, 'Max 50 characters allowed')
    .matches(VALIDATE_WHITESPACES, 'Remove extra spaces'),

  address: yup
    .string()
    .required('Address is required')
    // .matches(/^([^0-9]*)$/, 'Digits are not allowed')
    .max(50, 'Max 50 characters allowed')
    .matches(VALIDATE_WHITESPACES, 'Remove extra spaces'),

  email: yup
    .string()
    .email('Invalid email address')
    .required('Email is required')
    // .matches(/^([^0-9]*)$/, 'Digits are not allowed')
    .max(50, 'Max 50 characters allowed')
    .matches(VALIDATE_WHITESPACES, 'Remove extra spaces'),
  // .matches(singleSpaceRegex, 'Remove extra spaces'),
  phone_number: yup
    .string()
    .matches(VALIDATE_PHONE, ' Please enter a valid mobile number with +91')
    .max(13, 'You may enter up to 13 digits')
    .typeError('Mobile number cannnot contain characters')
    .required('Mobile number is required'),

  registration_number: yup
    .string()
    .required('Registration Number is required')
    .matches(SINGLE_WHITESPACE, 'Remove extra spaces'),
  gst_no: yup
    .string()
    // .required('GST Number is required')
    .length(15, 'Must be 15 characters')
    // .matches(
    //   /^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$/,
    //   'GST number should be unique'
    // ),
    .matches(VALIDATE_WHITESPACES, 'Remove extra spaces'),
  pincode: yup
    .string()
    .trim()
    .required('Pincode is required')
    .matches(/^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/, 'Incorrect pincode format')
    .matches(VALIDATE_WHITESPACES, 'Remove extra spaces'),

  country_id: yup.string().required('Country is required'),
  state_id: yup.string().required('State is required'),
  city_id: yup.string().required('City is required'),

  primary_account_no: yup
    .string()
    .required('Account No is required')
    .matches(/^[0-9]*$/, 'Only numbers are allowed')
    .min(9, 'Account number should be between 9 to 18 digits')
    .max(18, 'Account number should be between 9 to 18 digits')
    .matches(VALIDATE_WHITESPACES, 'Remove extra spaces'),
  confirm_account_no: yup
    .string()
    .required('Confirm Account No is required')
    .oneOf(
      [yup.ref('primary_account_no'), null],
      'Confirm Account No must match with Account No'
    )
    .matches(/^[0-9]*$/, 'Only numbers are allowed')
    .min(9, 'Account number should be between 9 to 18 digits')
    .max(18, 'Account number should be between 9 to 18 digits')
    .matches(VALIDATE_WHITESPACES, 'Remove extra spaces'),

  primary_bank_name: yup
    .string()
    .required('Bank Name is required')
    .matches(/^([^0-9]*)$/, 'Digits are not allowed')
    .max(50, 'Character exceeded the limit!')
    // .matches(SPECIAL_CHAR, 'Special Characters are not allowed')
    .matches(VALIDATE_WHITESPACES, 'Remove extra spaces')
    .matches(singleSpaceRegex, 'Remove extra spaces'),

  primary_branch_name: yup
    .string()
    .max(50, 'Character exceeded the limit!')
    .required('Branch Name is required')
    .matches(/^([^0-9]*)$/, 'Digits are not allowed')
    .matches(VALIDATE_WHITESPACES, 'Remove extra spaces')
    .matches(singleSpaceRegex, 'Remove extra spaces'),

  primary_ifsc_code: yup
    .string()
    .min(11, 'IFSC_Code must be 11 digit code!')
    .required('IFSC Code is required')
    .matches(VALIDATE_WHITESPACES, 'Remove extra spaces')
    .matches(singleSpaceRegex, 'Remove extra spaces'),

  primary_beneficiary_name: yup
    .string()
    .max(50, 'Character exceeded the limit!')
    .required('Beneficiary Name is required')
    .matches(/^([^0-9]*)$/, 'Digits are not allowed')
    // .matches(VALIDATE_WHITESPACES, 'Remove extra spaces')
    .matches(singleSpaceRegex, 'Remove extra spaces and special characters'),

  tnd: yup
    .date()
    .typeError('Please enter your date in the correct format')
    .min(today, `Date should be later than today's date`)
    .required('Date is required')
    
})

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
    backgroundColor: 'rgb(16 29 71)'
  },
  paper: {
    padding: '10px',
    '@media (max-width:767px)': {
      marginTop: '40px'
    }
  },
  drawer: {
    width: '20%',
    flexBasis: '20%',
    maxWidth: '260px'
  },
  closebtn: {
    '& svg path': {
      fill: '#fff !important'
    }
  },
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch'
    }
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  aside: {
    // padding: '25px 0 !important'
  },
  backbtn: {
    display: 'flex',
    '& svg path': {
      fill: '#fff !important'
    }
  },
  appointmentPage: {
    background: '#F7F5F6',
    flexWrap: 'nowrap',
    justifyContent: 'center'
  },
  errMsg: {
    color: 'red'
  },
  errMsg2: {
    color: 'red',
    fontSize: '12px',
    paddingLeft: '5px',
    paddingTop: '4px'
  },
  Inputtext: {
    color: '#fff !important',
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#fff !important'
    },
    '& label': {
      color: '#ccc'
    },
    '& .MuiInput-input': {
      color: '#fff !important'
    }
  },
  // paper: {
  //   position: 'absolute',
  //   width: 400,
  //   backgroundColor: theme.palette.background.paper,
  //   border: '2px solid #000',
  //   boxShadow: theme.shadows[5],
  //   padding: theme.spacing(2, 4, 3),
  //   height: 300
  // },
  avatar: {
    width: '140px',
    height: '140px',
    background: 'white',
    borderRadius: '50%',
    boxShadow: `0 1px 1px rgba(0,0,0,0.12), 
        0 2px 2px rgba(0,0,0,0.12), 
        0 4px 4px rgba(0,0,0,0.12), 
        0 8px 8px rgba(0,0,0,0.12),
        0 16px 16px rgba(0,0,0,0.12)`,
    margin: '0px auto',
    textAlign: 'center',
    padding: '10px',
    display: 'flex'
  },
  image: {
    width: '100%',
    height: '100%'
  },
  AvatarCol: {
    width: '100%',
    height: '100%'
  },
  AppBar: {
    '& .MuiBox-root': {
      padding: '0'
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff'
    },
    checkboxes: {
      marginTop: '15px'
    }
  },
  inputChange: {
    width: '100%',
    paddingLeft: '8px',
    paddingTop: '23px',
    '& .PhoneInputInput': {
      fontSize: '16px',
      border: 'none',
      borderBottom: '1px solid #9E9E9E',
      margin: '10px'
    }
  },
  formControl: {
    width: '100%',
    margin: '1px 0px 8px'
  },
  Input1: {
    margin: theme.spacing(0),
    width: '100%',
    paddingTop: '10px',
    '@media (max-width:767px)': {
      paddingLeft: '0px',
      margin: theme.spacing(0),
      paddingTop: '20px'
    }
  },
  Modal_popup: {
    height: '100%',
    position: 'absolute',
    top: '0',
    right: '0',
    zIndex: '99',
    cursor: 'pointer',
    display: 'none',
    /*This makes the button huge. If you want a bigger button, increase the font size*/
    fontSize: '50px',
    /*Opacity settings for all browsers*/
    opacity: '0',
    mozOpacity: '0',
    msFilter: 'progid:DXImageTransform.Microsoft.Alpha(opacity=0)'
  }
}))

export default AddClinic
