import React from 'react'
import { Button, makeStyles, CircularProgress } from '@material-ui/core'
import classNames from 'classnames'

const PrimaryButton = ({
  children,
  rounded,
  type,
  circle,
  isLoading,
  stopLoading,
  classVariation,
  disabled,
  ...rest
}) => {
  const classes = useStyles()
  var stopLoading = stopLoading ? true : false
  return (
    <Button
      variant="contained"
      color="primary"
      className={classNames(classes.primaryButton, {
        [classes.rounded]: rounded,
        [classes.circle]: circle,
        [classes.variant]: classVariation
      })}
      type={type}
      disabled={disabled}
      disableElevation
      {...rest}>
      {isLoading && !stopLoading && <CircularProgress size={24} color="#fff" />}
      {(!isLoading || stopLoading) && children}
    </Button>
  )
}

const useStyles = makeStyles(theme => ({
  primaryButton: {
    backgroundColor: '#111d4e',
    color: '#fff'
    // width: '100%',
  },
  rounded: {
    borderRadius: '40px'
  },
  circle: {
    borderRadius: '50%',
    minWidth: 'unset'
  },
  variant: {
    width: '135px',
    height: '40px',
    background: '#3f4c79',
    borderRadius: '40px',
    textAlign: 'center',
    fontSize: '16px',
    color: '#fff',
    lineHeight: '40px',
    border: 'none',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#3f4c79'
    }
  }
}))

export default PrimaryButton
