import { create } from 'apisauce'

import { LOCAL_STORAGE_AUTHN_KEY_NAME } from '../Constants'
import { BASE_URL } from '../Environment'

import localforage from 'localforage'
export const URIS = {
  AUTHENTICATION: 'auth/user-login',
  SEND_OTP: '/public/send-otp',
  PUBLIC_GET_USER_ROLE: 'public/get-user-roles',
  PUBLIC_USER_DETAILS: '/public/get-user',
  PUBLIC_COUNTRY_STATE_CITY: 'public/get-country-state-city',
  //ADMIN SECTION APIS
  GET_DOCTOR_LIST: '/hospital-admin/doctor/list',
  GET_HOSPITAL_APPOINTMENTS: '/hospital-admin/appointments/list',
  ADD_DOCTOR_DETAILS: '/hospital-admin/clinic/user/add',
  ADD_DOCTOR_BANK_DETAILS: '/doctor/bankdetails/add-edit',
  GET_SUBSCRIPTION_PLAN_LIST: '/public/subscription/plan-list',
  SUBSCRIPTION_CHOICE: '/doctor/subscription/choice',
  CANCEL_HOSPITAL_APPOINTMENT: '/user/appointments/cancel',
  AUTH_ADMIN_USER: '/auth/user-login',
  GET_DOCTOR_DETAILS: '/public/get-user',
  UPDATE_DOCTOR_PROFILE: '/doctor/profile/update',
  DOCTOR_UPDATE_FEES: '/doctor/fees/update',
  GET_DOCTOR_BANK_DETAILS: '/doctor/bankdetails/view',
  GET_WALLET_REQUESTS: '/hospital-admin/doctor/request-payout/list',
  GET_TRANSACTIONS: '/hospital-admin/transactions/appointments-list',
  LOCK_PAYOUT_REQUEST: '/hospital-admin/request-payout/lock',
  //schedule slots
  DOCTOR_SCHEDULE_SLOTS: '/public/calendar-slots',
  //cancel/approve
  CANCEL_APPROVE_TRANSACTIONS: '/hospital-admin/request/approve-cancel',
  //Clinic
  GET_CLINIC_LIST: '/user/clinic-list',
  ADD_CLINIC_PROFILE: '/clinic/profile/add',
  SUBSCRIPTION_HISTORY_LIST : '/hospital-admin/clinic/subscription-history/list'
}

const createApiClient = (baseURL = BASE_URL) => {
  const api = create({
    baseURL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
    // timeout: 15000
  })
  let idToken = ''
  localforage
    .getItem(LOCAL_STORAGE_AUTHN_KEY_NAME)
    .then(data => {
      if (data?.user?.id_token) {
        idToken = data.user.id_token
      } else {
        // console.log('nothing here!')
      }
    })
    .catch(err => {
      // console.log('Error retreiving item from local storage', err)
    })
  const setHeaders = params => {
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const element = params[key]
        api.setHeader(key, element)
      }
    }
  }

  const setAuthHeaders = params => {
    api.deleteHeader('authorization')
    api.deleteHeader('Authorization')
    setHeaders(params)
  }

  return {
    setHeaders,

    setAuthHeaders,

    authenticateUser: payload => {
      return new Promise((resolve, reject) => {
        api.post(URIS.AUTHENTICATION, payload).then(response => {
          if (response.ok) {
            resolve(response)
          } else {
            reject(response)
          }
        })
      })
    },
    sendOtp: payload => {
      return new Promise((resolve, reject) => {
        api.post(URIS.SEND_OTP, payload).then(response => {
          if (response.ok) {
            resolve(response)
          } else {
            reject(response)
          }
        })
      })
    },

    getUserRole: payload => {
      return api.post(URIS.PUBLIC_GET_USER_ROLE, payload)
    },

    getUserDetails: payload => {
      return api.post(URIS.PUBLIC_USER_DETAILS, payload)
    },

    getCountryStateCity: payload => {
      return api.post(URIS.PUBLIC_COUNTRY_STATE_CITY, payload)
    },

    getDoctorSchedule: payload => {
      return api.post(URIS.PUBLIC_DOCTOR_GET_SCHEDULES_BY_DATE, payload)
    },

    getDoctorScheduleSlots: payload => {
      return api.post(URIS.DOCTOR_SCHEDULE_SLOTS, payload)
    },

    createPatient: payload => {
      return api.post(URIS.PUBLIC_CREATE_PATIENT, payload)
    },

    createAppointment: payload => {
      return api.post(URIS.PUBLIC_CREATE_APPOINTMENT, payload)
    },

    hospital: {
      getMyDoctorsList: payload => {
        return api.post(URIS.GET_DOCTOR_LIST, payload)
      },
      getHospitalAppointments: payload => {
        return api.post(URIS.GET_HOSPITAL_APPOINTMENTS, payload)
      },
      cancelHospitalAppointment: payload => {
        return api.post(URIS.CANCEL_HOSPITAL_APPOINTMENT, payload)
      },
      createDoctorDetails: payload => {
        return api.post(URIS.ADD_DOCTOR_DETAILS, payload)
      },
      addDoctorBankDetails: payload => {
        return api.post(URIS.ADD_DOCTOR_BANK_DETAILS, payload)
      },
      getSubscriptionList: payload => {
        return api.post(URIS.GET_SUBSCRIPTION_PLAN_LIST, payload)
      },
      subscriptionChoice: payload => {
        return api.post(URIS.SUBSCRIPTION_CHOICE, payload)
      },
      authenticateAdminUser: payload => {
        return api.post(URIS.AUTH_ADMIN_USER, payload)
      },
      getDoctorProfileDetails: payload => {
        return api.post(URIS.GET_DOCTOR_DETAILS, payload)
      },
      getDoctorBankDetails: payload => {
        return api.post(URIS.GET_DOCTOR_BANK_DETAILS, payload)
      },
      updateDoctorProfile: payload => {
        return api.post(URIS.UPDATE_DOCTOR_PROFILE, payload)
      },
      updateConsultationFees: payload => {
        return api.post(URIS.DOCTOR_UPDATE_FEES, payload)
      },
      getWalletList: payload => {
        return api.post(URIS.GET_WALLET_REQUESTS, payload)
      },
      getTransactions: payload => {
        return api.post(URIS.GET_TRANSACTIONS, payload)
      },
      cancelApprove: payload => {
        return api.post(URIS.CANCEL_APPROVE_TRANSACTIONS, payload)
      },
      payoutLock: payload => {
        return api.post(URIS.LOCK_PAYOUT_REQUEST, payload)
      },
      getClinicsList: payload => {
        return api.post(URIS.GET_CLINIC_LIST, payload)
      },
      addClinicProfile: payload => {
        return api.post(URIS.ADD_CLINIC_PROFILE, payload)
      },
      subscriptionhistory : payload => {
        return api.post(URIS.SUBSCRIPTION_HISTORY_LIST , payload)
      }
    }
  }
}

export default { createApiClient }
