import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import {
  makeStyles,
  createMuiTheme,
  ThemeProvider
} from '@material-ui/core/styles'
import {
  Grid,
  FormControl,
  FormGroup,
  FormControlLabel,
  InputAdornment
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import SnackbarAlert from '../../components/commonComponents/SnackbarAlert'
import VisibilityIcon from '@material-ui/icons/Visibility'
import TextField from '@material-ui/core/TextField'
import { AdminStore } from '../../stores'
import Loading from 'react-loading'
import { useForm, Controller } from 'react-hook-form'
import { FaSearch } from 'react-icons/fa'
import { Typography } from '@material-ui/core'
import { debounce } from 'lodash'
import NoRecordImg from '../../images/noRecordFound.png'
import ViewClinicList from './ViewClinicList'
import { Box } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import ClinicForm from './ClinicForm'
import moment from 'moment'
import copy from 'copy-to-clipboard'
const useStyles = makeStyles(theme => ({
    root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3)
    },
    contactButton: {
        borderRadius: '40px',
        // backgroundColor:"rgb(16 29 71)",
        backgroundColor: '#111d4e',
        color: '#fff',
        padding: '10px 20px',
        textTransform: 'initial',
        '&:hover': {
          backgroundColor: '#111d4e'
        }
      }
  }))
const SuperClinicList = () => {
    const classes = useStyles()
    return (
        <div>
           <Paper elevation={3} className={classes.paper}>
           <Button className={classes.contactButton}>
              Add Clinic
            </Button>
           </Paper>
        </div>
    )
}
export default SuperClinicList