export const LOCAL_STORAGE_NAME = 'carenowdoc'
export const LOCAL_STORAGE_AUTHN_KEY_NAME = 'admin_auth'
export const LOCAL_STORAGE_AUTHZ_KEY_NAME = 'authz'
export const LOCAL_STORAGE_STORE_NAME = 'store'
// export const VALIDATE_PHONE = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/; //--- Numeric, min 10 and max 13, fromat: 9876543210
// export const VALIDATE_PHONE = /^([+]\d{2})?\d{10}$/
export const VALIDATE_PHONE = /^((\+){1}91){1}[1-9]{1}[0-9]{9}$/ // --- Keeps a check with number with a prefix of +91
export const USER_ID = 'user_id'
export const HOSPITAL_ID = 'hospital_id'
export const DOCTOR_ID = 'doctor_id'
export const PATIENT_ID = 'patient_id'
export const VALIDATE_WHITESPACES = /^[^\s]+(\s+[^\s]+)*$/gm
export const SINGLE_WHITESPACE = /^\S+$/
export const SPECIAL_CHAR = /^([a-zA-Z0-9-]+\s)*[a-zA-Z0-9-]+$/
//use this if the other one doesnt work
// export const SPECIAL_CHAR = /[^\w\s]/
// export const TODAY = Moment().format("YYYY-MM-DD");

export const NETWORK_STATUS = {
  SUCCESS: 'SUCCESS',
  NOT_STARTED: 'NOT_STARTED',
  FETCHING: 'FETCHING',
  FAILED: 'FAILED'
}

export const AUTH_STATE = {
  NOT_STARTED: 'NOT_STARTED',
  AUTHENTICATING: 'AUTHENTICATING',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED'
}

export const API_REQUEST_DATE_FORMAT = 'YYYY-MM-DD'
export const API_REQUEST_TIME_FORMAT = 'HH:mm:ss'
export const API_REQUEST_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'

export const USER_TYPE = {
  ADMIN: { id: 4, value: 4, label: 'Admin' },
  // { id: 2, value: 2, label: 'Nurse' },
  // DOCTOR: { id: 2, value: 2, label: "Doctor" },
  PATIENT: { id: 3, value: 3, label: 'Patient' }
}

export const USER_ROLE = {
  1: 'Admin',
  2: 'Doctor',
  3: 'Patient'
}

export const BOOKING_STATUS = {
  ALL: {
    value: '0',
    label: 'all'
  },
  BOOKED: {
    value: '1',
    label: 'booked'
  },
  CONFIRMED: {
    value: '2',
    label: 'confirmed'
  },
  CHECKED_IN: {
    value: '3',
    label: 'checked-in'
  },
  IN_PROGRESS: {
    value: '4',
    label: 'in-progress'
  },
  CANCELLED: {
    value: '5',
    label: 'cancelled'
  },
  NO_SHOW: {
    value: '6',
    label: 'no-show'
  },
  COMPLETED: {
    value: '7',
    label: 'completed'
  },
  RESCHEDULED: {
    value: '8',
    label: 'rescheduled'
  }
}

export const VISIT_TYPE = {
  ALL: { id: 0, label: 'All' },
  INITIAL: { id: 1, label: 'initial consult' },
  FOLLOW_UP: { id: 2, label: 'follow-up' }
}

export const LIST_TYPE = {
  ALL: { id: 0, label: 'all' },
  UPCOMING: { id: 1, label: 'upcoming' },
  PAST: { id: 2, label: 'past' }
}

export const PAYMENTSTATUS = {
  PAID: 1,
  UNPAID: 0
}
