import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import AdminNavbar from '../../components/commonComponents/AdminNavbar'
import { Layout } from '../../layouts'
import { useHistory } from 'react-router-dom'
import AdminMenu from './AdminMenu'

const AdminDashboard = () => {
  const classes = useStyles()
  const { push } = useHistory()

  return (
    <Layout hideNavbar>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Box>
            <AdminNavbar />
          </Box>
        </Grid>
        <Grid item md={12}>
          <Box color="primary.dark">
            <AdminMenu />
          </Box>
        </Grid>
      </Grid>
    </Layout>
  )
}

const useStyles = makeStyles(theme => ({
  image: {
    width: '100%'
  },
  count: {
    color: '#fff'
  },
  mobileImage: {
    width: '100%',
    objectFit: 'cover',
    height: '30vh'
  },
  annotation: {
    marginBottom: theme.spacing(8),
    '@media (max-width:780px)': {
      fontSize: '24px'
    }
  },
  colorWhiteBg: {
    backgroundColor: '#fff'
  },
  colorDarkBg: {
    backgroundColor: '#2d3864'
  }
}))

export default AdminDashboard
