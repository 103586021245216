import React from 'react'
import { Box, Link } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Link as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import Button from '@material-ui/core/Button'
import Logo from '../../images/logo.png'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import MenuIcon from '../../images/menu.png'
import { LOCAL_STORAGE_AUTHN_KEY_NAME, USER_ID } from '../../Constants'

const PublicNavbar = props => {
  const classes = useStyles()
  const [loggedIn, setLoggedIn] = React.useState(false)
  const [patientInfo, setPatientInfo] = React.useState([])

  const logout = () => {
    localStorage.removeItem('user_id')
    localStorage.removeItem('patient_id')
    window.location.href = '/admin/login'
  }
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  })

  const toggleDrawer = (anchor, open) => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setState({ ...state, [anchor]: open })
  }

  const list = anchor => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom'
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}>
      <List className={classes.Listitem}>
        <RouterLink
          className={classes.loginButton}
          to="https://demo-patient.carenowdoc.com/patient/login">
          Patient Login
        </RouterLink>
        <RouterLink
          className={classes.loginButton}
          to="https://demo-doctor.carenowdoc.com/doctor/login">
          Doctor Login
        </RouterLink>
        <Button className={classes.contactButton}>Business Contact</Button>
      </List>
    </div>
  )
  return (
    <Box
      w="100%"
      h="100%"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      {...props}
      className={classes.headerSection}>
      <Box className={classes.responsivenav}>
        {['left'].map(anchor => (
          <React.Fragment key={anchor}>
            <Button
              className={classes.responsivebtn}
              onClick={toggleDrawer(anchor, true)}>
              <img src={MenuIcon} alt="nav" />
            </Button>
            <Drawer
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}>
              {list(anchor)}
            </Drawer>
          </React.Fragment>
        ))}
      </Box>
      <Box className={classes.Logo}>
        <img className={classes.Logoimg} src={Logo} alt="Logo" />
      </Box>

      <Box justifyContent="right" className={classes.Headerright}>
        {loggedIn ? (
          <>
            <RouterLink to="/patient/dashboard">
              <Button className={classes.loginButton}>
                HI {patientInfo?.first_name} {patientInfo?.last_name}
              </Button>
              <Button onClick={logout} className={classes.logoutButton}>
                Logout
              </Button>
            </RouterLink>
          </>
        ) : (
          ''
        )}
        {loggedIn ? (
          ''
        ) : (
          <>
            <RouterLink className={classes.loginButton} to="/patient/login">
              Patient Login
            </RouterLink>
            <RouterLink className={classes.loginButton} to="/doctor/login">
              Doctor Login
            </RouterLink>
          </>
        )}
        <Button className={classes.contactButton}>Business Contact</Button>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles(() => ({
  headerSection: {
    padding: '4px 40px',
    '@media (max-width:767px)': {
      padding: '10px 20px',
      flexWrap: 'wrap'
    }
  },
  list: {
    width: 300,
    padding: '20px'
  },
  fullList: {
    width: 'auto'
  },
  Listitem: {
    display: 'flex',
    flexDirection: 'column'
  },
  loginButton: {
    color: '#212121',
    marginRight: '4rem',
    fontWeight: '400',
    cursor: 'pointer',
    '@media (max-width:767px)': {
      marginRight: '1.5rem',
      marginBottom: '15px'
    },
    '&:hover': {
      textDecoration: 'none',
      color: '535f8f'
    }
  },
  responsivebtn: {
    background: 'transparent',
    '&:hover': {
      background: 'transparent'
    }
  },
  contactButton: {
    borderRadius: '40px',
    backgroundImage: 'linear-gradient(to right, #46558d , #212b50)',
    color: '#fff',
    padding: '10px 20px',
    cursor:"pointer",
    textTransform: 'initial'
  },
  logoutButton: {
    borderRadius: '40px',
    marginRight: '20px',
    backgroundImage: 'linear-gradient(to right, #46558d , #212b50)',
    color: '#fff',
    padding: '10px 20px',
    textTransform: 'initial'
  },
  Logoimg: {
    '@media only screen and (min-width : 768px) and (max-width : 1024px) and (orientation : landscape)': {
      height: '60px'
    },
    '@media only screen and (min-width : 768px) and (max-width : 1024px) and (orientation : portrait)': {
      height: '60px'
    },
    '@media (max-width:767px)': {
      height: '60px'
    }
  },
  responsivenav: {
    display: 'none',
    position: 'absolute',
    left: '0px',
    top: '18px',
    // background: `url(${CardBgImg}) center top no-repeat`,
    '@media (max-width:767px)': {
      display: 'block'
    }
  },
  Logo: {
    '@media (max-width:767px)': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      width: '100%',
      marginBottom: '0px',
      marginLeft: '30px'
    }
  },
  Headerright: {
    '@media (max-width:767px)': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      display: 'none'
    }
  }
}))

export default PublicNavbar
