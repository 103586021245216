import { action, thunk } from 'easy-peasy'
import api from '../../api'
import localforage from 'localforage'
import BaseModel from '../Base'
import {
  NETWORK_STATUS,
  LOCAL_STORAGE_AUTHN_KEY_NAME,
  LOCAL_STORAGE_AUTHZ_KEY_NAME,
  HOSPITAL_ID,
  USER_ROLE
} from '../../Constants'

const updateLocalStorage = async data => {
  const { admin_hospital_details, user, access_specificatin ,id_token} = data
 
  const authn = {
    user,
   admin_hospital_details,
   id_token
  }

  const authz = {
    access_specification: access_specificatin
  }


  return Promise.all([
    localforage.setItem(LOCAL_STORAGE_AUTHN_KEY_NAME, authn),
    localforage.setItem(LOCAL_STORAGE_AUTHZ_KEY_NAME, authz),
    // localStorage.setItem(HOSPITAL_ID,admin_hospital_details.hospital_id),
    localStorage.setItem('admin_id_token', user.id_token)
  ])
}

export default {
  ...BaseModel(),

  adminLogin: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const { email, password, urlCode, userType } = payload
    // Setting up the password for the payload for update profile details.

    const responseAuth = await api.hospital.authenticateAdminUser({
      user_name: email,
      password,
      type: 'password',
      // user_type: `${userType}`,
      user_type: '4',
      user_role: '66d2347d-8efe-4dc4-8704-1530a341909c',
      url_code: 'craftveda-demo-hospital'
    })

    if (responseAuth.data.statusCode === 200) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      updateLocalStorage(responseAuth.data)
      localStorage.setItem("user_details_id" , responseAuth.data.user.user_details_id)
      return responseAuth.data
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
      return { statusCode: responseAuth.data.statusCode }
    }
  })
}
