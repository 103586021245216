import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import ProtectedRoute from './components/commonComponents/ProtectedRoute'
import { AdminLogin, AdminDashboard, RxDetails } from './pages'
import ReactGA from 'react-ga'
import { GA_KEY, DOC_URL } from './Environment'
import AddClinic from './pages/Admin/AddClinic'

const App = () => {
  //google analytics configuration
  ReactGA.initialize(GA_KEY)
  ReactGA.pageview(window.location.pathname)

  return (
    <>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Router>
          <Switch>
            <Route exact path="/">
              <Redirect to="/admin/login" />
            </Route>
            <Route
              path="/doctor"
              render={() => (window.location = DOC_URL + 'doctor/login')}
            />
            <Route
              path="/patient"
              render={() => (window.location = DOC_URL + 'patient/login')}
            />
            <Route exact path="/admin/login">
              {localStorage.getItem('admin_id_token') !== null ? (
                <Redirect to="/admin/dashboard" />
              ) : (
                <AdminLogin />
              )}
            </Route>
            <Route exact path="/admin/rxdetails">
              {localStorage.getItem('admin_id_token') === null ? (
                <Redirect to="/admin/login" />
              ) : (
                <RxDetails />
              )}
            </Route>

            <ProtectedRoute>
              <Route exact path="/admin/:menu">
                <AdminDashboard />
              </Route>
            </ProtectedRoute>
          </Switch>
        </Router>
      </MuiPickersUtilsProvider>
    </>
  )
}

export default App
