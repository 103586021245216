import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import {
  makeStyles,
  createMuiTheme,
  ThemeProvider
} from '@material-ui/core/styles'
import {
  Grid,
  FormControl,
  FormGroup,
  FormControlLabel,
  InputAdornment
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import SnackbarAlert from '../../components/commonComponents/SnackbarAlert'
import VisibilityIcon from '@material-ui/icons/Visibility'
import TextField from '@material-ui/core/TextField'
import { AdminStore } from '../../stores'
import Loading from 'react-loading'
import { useForm, Controller } from 'react-hook-form'
import { FaSearch } from 'react-icons/fa'
import { Typography } from '@material-ui/core'
import { debounce } from 'lodash'
import NoRecordImg from '../../images/noRecordFound.png'
import ViewClinicList from './ViewClinicList'
import { Box } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import ClinicForm from './ClinicForm'
import moment from 'moment'
import copy from 'copy-to-clipboard'

const columns = [
  { id: 'logo', label: 'Logo' },
  { id: 'display_name', label: 'Clinic Name', maxWidth: 140 },
  { id: 'email', label: 'Email', maxWidth: 140 },
  {
    id: 'phone_number',
    label: 'Mobile Number',
    maxWidth: 100,
    align: 'right'
  },
  {
    id: 'onboarded_on',
    label: 'Onboarding Date',
    maxWidth: 170,
    align: 'center'
  }
  //   {
  //     id: 'country',
  //     label: 'Country',
  //     minWidth: 170,
  //     align: 'center'
  //   }
]

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  container: {
    maxHeight: 440
  },
  loadingSpinner: {
    position: 'absolute',
    top: '42px',
    right: '150px',
    left: '430px',
    '@media (max-width:976px)': {
      right: '5px'
    }
  },
  searchicon: {
    position: 'absolute',
    top: '40px',
    right: '175px',
    '@media (max-width:976px)': {
      right: '25px'
    }
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    padding: '10px',
    '@media (max-width:767px)': {
      marginTop: '40px'
    }
  },
  h2: {
    justifyContent: 'center',
    display: 'flex',
    color: '#3f4b79'
  },
  p: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between'
  },
  viewModal: {
    padding: theme.spacing(7, 4, 3)
  },
  editModal: {
    padding: theme.spacing(2, 4, 3)
  },
  button: {
    margin: theme.spacing(3, 0)
  },
  error: {
    color: 'error'
  },
  tablecontent: {
    '& h6': {
      fontSize: '16px',
      padding: '15px 10px'
    },
    '& h5': {
      fontSize: '16px',
      fontWeight: 'bold',
      color: '#fff',
      margin: '0px'
    },
    '& p': {
      fontSize: '16px',
      marginBottom: '0px',
      fontWeight: '400'
    },
    '& .MuiTableCell-head': {
      color: '#fff',
      background: 'rgb(16 29 71)'
    },
    '& .MuiTableCell-root': {
      padding: '20px 10px'
    },
    '& .MuiPaper-rounded': {
      boxShadow: 'none !important',
      borderRadius: '0px !important'
    }
  },
  Searchinput: {
    '& input': {
      padding: '0 40px 0 0'
    },
    '& .MuiInput-formControl': {
      marginTop: '30px'
    }
  },
  searchicon: {
    position: 'absolute',
    top: '40px',
    right: '10px',
    '@media (max-width:976px)': {
      right: '10px'
    }
  },
  Searchcol: {
    '@media (max-width:767px)': {
      marginBottom: '15px'
    }
  },
  contactButton: {
    borderRadius: '40px',
    // backgroundColor:"rgb(16 29 71)",
    backgroundColor: '#111d4e',
    color: '#fff',
    padding: '10px 20px',
    textTransform: 'initial',
    '&:hover': {
      backgroundColor: '#111d4e'
    }
  }
}))

const menuTheme = createMuiTheme({
  overrides: {
    MuiMenu: {
      paper: {
        boxShadow: 'none',
        border: '1px solid #000',
        marginRight: 10
      }
    }
  }
})

const ClinicList = ({ tabState }) => {
  const classes = useStyles()
  const { control, handleSubmit, register, errors } = useForm()
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [clinicData, setClinicData] = React.useState([])
  const [searchString, setSearchString] = React.useState('')
  const [open, setOpen] = React.useState(false)
  const [clinicDetails, setClinicDetails] = React.useState([])
  const [edit, setEdit] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [target, setTarget] = React.useState(null)
  const [id, setID] = React.useState(null)
  const [multiButton, setmultiButton] = React.useState(false)
  const [alertType, setAlertType] = React.useState('')
  const [snackBarMessage, setSnackBarMessage] = React.useState('')
  const [isSnackBarOpen, setOpenSnackBar] = React.useState(false)
  const [clinicList, setclinicList] = React.useState([])

  const history = useHistory()
  const [viewClinicList, setClinicList] = React.useState(false)

  const opens = Boolean(anchorEl)

  const profilePopup = React.useRef(null)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const { getMyClinicsList, clearSnack } = AdminStore.useStoreActions(
    actions => ({
      ...actions.doctorList
    })
  )

  const {
    clinicsList,
    clinicsListRowCount,
    networkStatus,
    clinicAddedResponse,
    lastPage
  } = AdminStore.useStoreState(state => ({
    ...state.doctorList
  }))

  React.useEffect(() => {
    if (clinicsList == undefined) {
      setclinicList(undefined)
    } else {
      setclinicList(clinicsList)
    }
  }, [clinicsList])

  const debouncedGetMyClinicsList = React.useCallback(
    debounce(
      searchString =>
        getMyClinicsList({
          pattern: searchString,
          table_config_page_no: 1,
          table_config_rows_per_page: rowsPerPage
        }),
      1000
    ),
    []
  )
  //initial undebounced call
  useEffect(() => {
    getMyClinicsList({
      pattern: searchString,
      table_config_page_no: page + 1,
      table_config_rows_per_page: rowsPerPage
    })
  }, [rowsPerPage, page])

  //reset to 1st page on mount
  useEffect(() => {
    if (tabState == true) {
      setPage(0)
    }
  }, [tabState])

  React.useEffect(() => {
    if (clinicAddedResponse === undefined || clinicAddedResponse === null) {
      //
    } else if (clinicAddedResponse.password) {
      copy(clinicAddedResponse.password)
      setTimeout(() => {
        setOpenSnackBar(true)
        setSnackBarMessage('Your password is in stored in your clipboard')
        // setSnackBarMessage('Patient profile not updated successfully')
        setmultiButton(false)
        setAlertType('info')
      }, 1000)
      getMyClinicsList({})
      clearSnack()
    }
  }, [clinicAddedResponse])

  const onSearchUsed = e => {
    setSearchString(e.target.value)
    debouncedGetMyClinicsList(e.target.value)
  }

  // To view the details of the data from api and open the Modal
  const viewModal = (event, data) => {
    setClinicList(true)
    setClinicDetails(data)
    setAnchorEl(event.currentTarget)
  }

  const closeClinicList = () => {
    setClinicList(false)
  }

  // Closes the Modal
  const handleClose = () => {
    setOpen(false)
    setAnchorEl(null)
  }

  // form submit
  const onSubmit = data => {
    setOpen(false)
  }

  const profile = e => {
    profilePopup.current.click()
  }

  // let sorted_data = clinicsList.sort((a, b) => {
  //   return new Date(a.onboarded_on) - new Date(b.onboarded_on)
  // })

  const hideSnackBar = () => {
    setOpenSnackBar(false)
  }

  return (
    <Paper elevation={3} className={classes.paper}>
      <SnackbarAlert
        isOpen={isSnackBarOpen}
        message={snackBarMessage}
        alertType={alertType}
        multibutton={multiButton}
        primaryClick={hideSnackBar}
      />
      <React.Fragment>
        <ViewClinicList
          open={viewClinicList}
          close={closeClinicList}
          clinicDetails={clinicDetails}
        />
      </React.Fragment>
      <React.Fragment>
        <ClinicForm ref={profilePopup} />
      </React.Fragment>

      <Grid item xs={12} md={5}>
        <FormControl component="fieldset" style={{ width: '100%' }}>
          <FormGroup
            aria-label="position"
            row
            style={{ width: '100%', position: 'relative' }}>
            <FormControlLabel
              className={classes.formcol}
              style={{ width: '100%' }}
              control={
                <TextField
                  className={classes.Searchinput}
                  id="standard-basic"
                  label="Search Clinic"
                  style={{ margin: '20px 0 20px 20px', width: '100%' }}
                  value={searchString}
                  onChange={onSearchUsed}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                />
              }
            />

            {networkStatus == 'FETCHING' ? (
              <Loading
                type="spin"
                textalign="center"
                color="grey"
                height={20}
                width={20}
                className={classes.loadingSpinner}
              />
            ) : null}

            <Button className={classes.contactButton} onClick={profile}>
              Add Clinic
            </Button>
          </FormGroup>
        </FormControl>
      </Grid>

      <Grid item xs={12} md={5}>
        <Box>
          <h2>Total Clinics: {clinicsListRowCount}</h2>
        </Box>
      </Grid>

      <Grid item xs={12} sm={12} md={12} className={classes.tablecontent}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ maxWidth: column.maxWidth }}>
                    {column.label}
                  </TableCell>
                ))}
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {clinicList == undefined ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                  <img
                    alt="No data"
                    style={{
                      width: '200px',
                      left: '500px',
                      position: 'relative'
                    }}
                    src={NoRecordImg}
                  />
                </div>
              ) : (
                clinicList.map(data => {
                  return (
                    <TableRow>
                      {columns.map(column => {
                        const val = column.id
                        if (val == 'logo') {
                          // console.log(val, data[val])
                          return (
                            <TableCell key={column.id} align={column.align}>
                              <img src={data[val]} width={50} height={50} />
                            </TableCell>
                          )
                        } else if (val == 'onboarded_on') {
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {moment(data[val]).format('DD-MM-YYYY')}
                            </TableCell>
                          )
                        } else
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {data[val]}
                            </TableCell>
                          )
                      })}
                      <TableCell align="right">
                        <VisibilityIcon
                          onClick={e => viewModal(e, data)}
                          style={{ color: '#202020', cursor: 'pointer' }}
                        />
                      </TableCell>
                    </TableRow>
                  )
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      {clinicData ? (
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={clinicsListRowCount !== undefined ? clinicsListRowCount : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            disabled: page === 0,
            style: { color: page === 0 ? '#b5b8c4' : '#000' }
          }}
          nextIconButtonProps={{
            disabled: page === lastPage - 1,
            style: { color: page === lastPage - 1 ? '#b5b8c4' : '#000' }
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      ) : null}
    </Paper>
  )
}

export default ClinicList
