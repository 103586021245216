import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'

export const defaultTheme = createMuiTheme()

let theme = createMuiTheme({
  palette: {
    primary: {
      main: '#3f4b79', //#21caf1 , #21caf1
      light: '#E2E2E2',
      dark: '#414141',
      danger: '#f44336'
    },
    secondary: {
      // Secondary main is not decided yet
      main: '#fff',
      dark: '#272727',
      light: '#F7F6F6'
    },
    text: {
      secondary: '#979797'
    }
  },
  typography: {
    body1: {
      fontFamily: '"Lato", sans-serif'
    },
    body2: {
      fontSize: '1rem',
      fontFamily: 'Lato, sans-serif',
      fontWeight: '100',
      lineHeight: '1.5',
      letterSpacing: ' 0.00938em'
    }
  }
})

theme = responsiveFontSizes(theme)

export default theme
