let home_url = {
  dev: 'https://carenowdoc.com/',
  demo: 'https://carenowdoc.com/',
  prod: 'https://carenowdoc.com/',
  qa: 'https://qa.carenowdoc.com/'
}

let backend_url = {
  //   dev: 'https://api-development.carenowdoc.com/', //to be replaced
  dev: 'https://api-development.carenowdoc.com/',
  demo: 'https://api-demo.carenowdoc.com/',
  prod: 'https://api.carenowdoc.com/',
  qa: 'https://api-qa.carenowdoc.com/'
}

let doc_url = {
  dev: 'https://dev.carenowdoc.com/',
  demo: 'https://demo.carenowdoc.com/',
  prod: 'https://www.carenowdoc.com/',
  qa: 'https://qa.carenowdoc.com/'
}

let google_analytics = {
  dev: 'UA-184651243-1',
  demo: 'UA-184651243-1',
  prod: 'UA-186307611-1',
  qa: 'UA-184651243-1'
}

let environment = process?.env?.REACT_APP_ENV
  ? process.env.REACT_APP_ENV
  : 'dev'

const api_type = window.location.hostname === 'localhost' ? 'dev' : environment
console.log(environment)


export const BASE_URL = backend_url[api_type]
export const DOC_URL = doc_url[api_type]
export const GA_KEY = google_analytics[api_type]
export const HOME_URL = home_url[api_type]
