import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Button, makeStyles } from '@material-ui/core'
import ConfirmCancelModal from './ConfirmCancel'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  modalView: {
    width: '22rem !important',
    height: '13rem'
  },
  modalSecondaryButton: {
    backgroundColor: '#e4e4e4',
    padding: '5px 20px',
    borderRadius: '40px',
    color: '#111d4e',
    '&:hover': {
      backgroundColor: '#e4e4e4',
      color: '#111d4e'
    }
  },
  modalPrimaryButton: {
    backgroundColor: '#111d4e',
    padding: '5px 20px',
    borderRadius: '40px',
    color: '#e4e4e4',
    '&:hover': {
      backgroundColor: '#111d4e',
      color: '#e4e4e4'
    }
  }
}))

const CancelModal = ({ open, close, request }) => {
  const classes = useStyles()
  const [openConfirm, setOpenConfirm] = React.useState(false)

  const confirmCancelClicked = () => {
    close()
    setOpenConfirm(true)
  }

  const confirmCancelClose = () => {
    setOpenConfirm(false)
  }
  return (
    <div>
      <Dialog
        open={open}
        onClose={close}
        classes={{ paper: classes.modalView }}>
        <DialogTitle>Cancel Transaction</DialogTitle>
        <DialogContent>Do you want to cancel this transaction?</DialogContent>
        <DialogActions style={{ padding: '20px 40px' }}>
          <Button
            onClick={confirmCancelClicked}
            className={classes.modalPrimaryButton}>
            YES
          </Button>
          <Button onClick={close} className={classes.modalSecondaryButton}>
            NO
          </Button>
        </DialogActions>
      </Dialog>
      <React.Fragment>
        <ConfirmCancelModal
          open={openConfirm}
          close={confirmCancelClose}
          request={request}
        />
      </React.Fragment>
    </div>
  )
}

export default CancelModal
