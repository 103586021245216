import React, { useEffect } from 'react'
import Paper from '@material-ui/core/Paper'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { AdminStore } from '../../stores'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Loading from 'react-loading'
import { NETWORK_STATUS } from '../../Constants'
import { Avatar } from '@material-ui/core'
import moment from 'moment'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  visibilityModal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '500px',
    zIndex: '1000005'
  },
  modalMain: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    width: '100%',
    height: '45%',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  modalFields: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 50%)',
    alignItems: 'start'
  },
  modalLabelField: {
    margin: '8px 0',
    fontWeight: 'bold',
  },
  modalValueField: {
    margin: '8px 0',
    maxWidth: '100%',
    overflowWrap: 'break-word',
    wordWrap: 'break-word'
  },
  viewModalButton: {
    backgroundColor: '#3f4b79',
    color: 'white',
    borderRadius: 3,
    fontWeight: 200,
    padding: '0 30px',
    marginBottom: '10px',
    height: 40,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  modalView: {
    width: '35rem !important',
    height: '38rem'
  },
  ModalHead: {
    backgroundColor: '#111d4e',
    color: '#fff'
  },
  modalSecondaryButton: {
    backgroundColor: '#e4e4e4',
    padding: '5px 20px',
    borderRadius: '40px',
    color: '#111d4e',
    '&:hover': {
      backgroundColor: '#e4e4e4',
      color: '#111d4e'
    }
  },
  loading: {
    position: 'relative',
    top: '50%',
    left: '42%'
  },
  AvatarCol: {
    width: '25%',
    height: '25%',
    left: '40%'
  }
}))

const ViewDoctorDetails = ({ open, clinicDetails, close }) => {
  const classes = useStyles()
  const [clinicList, setClininList] = React.useState([])

  //Doctor Details
  const { networkStatus } = AdminStore.useStoreState(state => ({
    ...state.doctorList
  }))

  useEffect(() => {
    if (clinicDetails) {
      setClininList(clinicDetails)
    }
  }, [clinicDetails])

  return (
    <Paper className={classes.root}>
      <Dialog
        open={open}
        classes={{ paper: classes.modalView }}
        onClose={close}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" className={classes.ModalHead}>
          Clinic Details
          {/* <span
            style={styles}
            onMouseEnter={() => setColor('red')}
            onMouseLeave={() => setColor('')}
            onClick={handleClose}>
            X
          </span> */}
        </DialogTitle>
        <DialogContent>
          {networkStatus === NETWORK_STATUS.FETCHING ? (
            <Loading
              type="bubbles"
              color="#9138aa"
              height={50}
              className={classes.loading}
            />
          ) : (
            <React.Fragment>
              <Avatar className={classes.AvatarCol} src={clinicList.logo} />
              <div style={{ padding: '80px 20px 10px 20px' }}>
                <div className={classes.modalFields}>
                  <p className={classes.modalLabelField}>Clinic Name:</p>
                  <p className={classes.modalValueField}>
                    {clinicList.display_name}
                  </p>
                </div>
                <div className={classes.modalFields}>
                  <p className={classes.modalLabelField}>Slug Name:</p>
                  <p className={classes.modalValueField}>
                    {clinicList.url_slug_prefix}
                  </p>
                </div>
                <div className={classes.modalFields}>
                  <p className={classes.modalLabelField}>Email:</p>
                  <p className={classes.modalValueField}>{clinicList.email}</p>
                </div>
                <div className={classes.modalFields}>
                  <p className={classes.modalLabelField}>Mobile Number:</p>
                  <p className={classes.modalValueField}>
                    {clinicList.phone_number}
                  </p>
                </div>
                <div className={classes.modalFields}>
                  <p className={classes.modalLabelField}>About:</p>
                  <p className={classes.modalValueField}>{clinicList.about}</p>
                </div>
                <div className={classes.modalFields}>
                  <p className={classes.modalLabelField}>Onboarded On:</p>
                  <p className={classes.modalValueField}>
                    {moment(clinicList.onboarded_on).format('DD-MM-YYYY')}
                  </p>
                </div>
                <div className={classes.modalFields}>
                  <p className={classes.modalLabelField}>Address:</p>
                  <p className={classes.modalValueField}>
                    {clinicList.address}
                  </p>
                </div>
                <div className={classes.modalFields}>
                  <p className={classes.modalLabelField}>Pincode:</p>
                  <p className={classes.modalValueField}>
                    {clinicList.pincode}
                  </p>
                </div>

                {/* <div className={classes.modalFields}>
                <p>Clinic:</p>
                {doctorDetails.clinic_name}, {doctorDetails.clinic_address}
              </div> */}
              </div>
            </React.Fragment>
          )}
        </DialogContent>
        <DialogActions style={{ padding: '20px 40px' }}>
          <Button onClick={close} className={classes.modalSecondaryButton}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  )
}

export default ViewDoctorDetails
