import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import Typography from '@material-ui/core/Typography'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import EditIcon from '@material-ui/icons/Edit'
import VisibilityIcon from '@material-ui/icons/Visibility'
import TextField from '@material-ui/core/TextField'
import Chip from '@material-ui/core/Chip'
import FiberSmartRecordIcon from '@material-ui/icons/FiberSmartRecord'
import LocalDrinkIcon from '@material-ui/icons/LocalDrink'
import Modal from 'react-modal'
import { useFormik } from 'formik'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Swal from 'sweetalert2'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'

const columns = [
  { id: 'name', label: 'Rx Name', minWidth: 170 },
  { id: 'type', label: 'Rx Type', minWidth: 170 },
  { id: 'date', label: 'Date Added', minWidth: 100 },
  {
    id: 'presc',
    label: 'Prescribed Vol',
    minWidth: 170,
    align: 'right'
  },
  {
    id: 'demand',
    label: 'Market Demand',
    minWidth: 170,
    align: 'right'
  },
  {
    id: 'state',
    label: 'State',
    minWidth: 170,
    align: 'right'
  }
]

function createData(name, type, date, presc, demand, state) {
  return { name, type, date, presc, demand, state }
}

const rows = [
  createData('Azithromicin', 1, '20-01-2020', 78999, 2, 'Maharashtra'),
  createData('Paracetemol', 2, '20-01-2020', 180078911, 1, 'West Bengal'),
  createData('Covaxin', 1, '‎12-05-2020', 89000, 3, 'Tamil Nadu'),
  createData('Aspirin', 1, '20-01-2020', 145677711, 1, 'Kerala'),
  createData('Chlorpromazine', 2, '10-02-2020', 72381, 3, 'Maharashtra'),
  createData('Amlodipine', 1, '26-08-2019', 6770018, 2, 'Punjab'),
  createData('Prednisone', 2, '20-01-2020', 89991111, 1, 'Maharashtra'),
  createData('Alprazolam', 1, '17-11-2019', 167555, 3, 'West Bengal'),
  createData('Sildenafil', 1, '02-12-2019', 788111, 3, 'Uttar Pradesh'),
  createData('Azithromicin', 1, '20-01-2020', 78999, 2, 'Delhi'),
  createData('Paracetemol', 2, '20-01-2020', 180078911, 1, 'Delhi'),
  createData('Covaxin', 1, '‎12-05-2020', 89000, 3, 'Delhi'),
  createData('Aspirin', 1, '20-01-2020', 145677711, 1, 'Delhi'),
  createData('Chlorpromazine', 2, '10-02-2020', 72381, 3, 'Delhi'),
  createData('Amlodipine', 1, '26-08-2019', 6770018, 2, 'Delhi'),
  createData('Prednisone', 2, '20-01-2020', 89991111, 1, 'Delhi'),
  createData('Alprazolam', 1, '17-11-2019', 167555, 3, 'Delhi'),
  createData('Sildenafil', 1, '02-12-2019', 788111, 3, 'Delhi')
]

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  container: {
    maxHeight: 440
  },
  visibilityModal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '500px',
    zIndex: '1000005'
  },
  viewModalButton: {
    backgroundColor: '#3f4b79',
    color: 'white',
    borderRadius: 3,
    fontWeight: 200,
    padding: '0 30px',
    marginBottom: '10px',
    height: 40,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  visibilityIconMain: {
    color: '#202020',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  editIconMain: {
    color: '#9aca09',
    '&:hover': {
      cursor: 'pointer',
      color: '#9aca09'
    }
  },
  editModal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '500px',
    zIndex: '1000005',
    alignItems: 'center',
    justifyContent: 'center',
    border: '2px solid #3f4b79'
  },
  modalMain: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    width: '100%',
    height: '45%',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  textField: {
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: 0,
    marginTop: 0,
    fontWeight: 500
  },
  selectPtag: {
    fontSize: '14px',
    width: 'auto',
    fontWeight: 500
  },
  modalFields: {
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '20px'
  }
}))

export default function StickyHeadTable() {
  const classes = useStyles()
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [viewModal, setViewModal] = React.useState(false)
  const [viewModalData, setViewModalData] = React.useState({})
  const [editModal, setEditModal] = React.useState(false)
  const [editModalData, setEditModalData] = React.useState({})

  //Working fine
  const handleChangePage = async (event, newPage) => {
    // alert(`${newPage}`);
    await setPage(newPage)
  }

  //#bug ------ async await needs to be added
  const handleChangeRowsPerPage = async event => {
    // alert(`${parseInt(event.target.value)}`);
    await setRowsPerPage(+event.target.value)
    await setPage(0)
  }

  const visibilityIconClicked = rowData => {
    setViewModal(true)
    setViewModalData(rowData)
    // alert("visibility icon clicked!!!!");
  }

  const editIconClicked = rowData => {
    // alert("Edit icon clicked!");
    setEditModal(true)
    setEditModalData(rowData)
  }

  const renderSlicedRows = () => {
    return rows
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map(row => {
        return (
          <>
            <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
              {columns.map(column => {
                const value = row[column.id]
                return (
                  <>
                    {column.id == 'demand' ? (
                      <TableCell key={column.id} align={column.align}>
                        {value == 1 ? (
                          <Chip
                            label="High"
                            style={{ backgroundColor: 'red' }}
                          />
                        ) : null}
                        {value == 2 ? (
                          <Chip
                            label="Moderate"
                            style={{ backgroundColor: 'yellow' }}
                          />
                        ) : null}
                        {value == 3 ? (
                          <Chip
                            label="Low"
                            style={{ backgroundColor: 'green' }}
                          />
                        ) : null}
                      </TableCell>
                    ) : column.id == 'type' ? (
                      <TableCell key={column.id} align={column.align}>
                        {value == 1 ? (
                          <FiberSmartRecordIcon style={{ color: '#202020' }} />
                        ) : null}
                        {value == 2 ? (
                          <LocalDrinkIcon style={{ color: '#202020' }} />
                        ) : null}
                      </TableCell>
                    ) : (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === 'number'
                          ? column.format(value)
                          : value}
                      </TableCell>
                    )}
                  </>
                )
              })}
              <TableCell align="right">
                <EditIcon
                  className={classes.editIconMain}
                  onClick={() => editIconClicked(row)}
                />
                <VisibilityIcon
                  className={classes.visibilityIconMain}
                  onClick={() => visibilityIconClicked(row)}
                />
              </TableCell>
            </TableRow>
          </>
        )
      })
  }

  const getDemand = demand => {
    switch (demand) {
      case 1:
        return <Chip label="High" style={{ backgroundColor: 'red' }} />
      case 2:
        return <Chip label="Moderate" style={{ backgroundColor: 'yellow' }} />
      case 3:
        return <Chip label="Low" style={{ backgroundColor: 'green' }} />
      default:
        return null
    }
  }

  const getType = type => {
    switch (type) {
      case 1:
        return <FiberSmartRecordIcon style={{ color: '#202020' }} />
      case 2:
        return <LocalDrinkIcon style={{ color: '#202020' }} />
      default:
        return null
    }
  }

  const submit = values => {
    setEditModal(false)
    Swal.fire({
      title: 'RX Analytics Data Updated',
      text: `Name: ${values.name} Type: ${values.type} Date: ${values.dateAdded} Vol: ${values.prescribedVol} Demand: ${values.demand} State: ${values.state}`,
      icon: 'info',
      confirmButtonText: 'Fantastic!'
    })
  }

  const formik = useFormik({
    initialValues: {
      name: editModalData.name,
      type: editModalData.type,
      dateAdded: editModalData.date,
      prescribedVol: editModalData.presc,
      demand: editModalData.demand,
      state: editModalData.state
    },
    enableReinitialize: true,
    onSubmit: submit
  })

  return (
    <React.Fragment>
      <React.Fragment>
        <Paper className={classes.root}>
          <TextField
            id="standard-basic"
            label="Search Rx"
            style={{ margin: '20px 30px', width: '400px' }}
          />
          <TableContainer className={classes.container}>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map(column => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}>
                      {column.label}
                    </TableCell>
                  ))}
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{renderSlicedRows()}</TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </React.Fragment>
      <React.Fragment>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          isOpen={viewModal}
          onRequestClose={() => {
            setViewModal(false)
          }}
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
          className={classes.visibilityModal}>
          <Fade in={viewModal}>
            <div className={classes.modalMain}>
              <p style={{ fontWeight: 'bold', textAlign: 'center' }}>
                RX ANALYTICS
              </p>
              <hr style={{ border: '1px solid black' }} />

              <div style={{ paddingLeft: '20px' }}>
                <div className={classes.modalFields}>
                  <b>RX Name:</b>
                  {viewModalData.name}
                </div>
                <div className={classes.modalFields}>
                  <b>RX Type:</b>
                  {getType(viewModalData.type)}
                </div>
                <div className={classes.modalFields}>
                  <b>Date Added:</b>
                  {viewModalData.date}
                </div>
                <div className={classes.modalFields}>
                  <b>Prescribed Vol:</b>
                  {viewModalData.presc}
                </div>
                <div className={classes.modalFields}>
                  <b>Market Demand:</b>
                  {getDemand(viewModalData.demand)}
                </div>
                <div className={classes.modalFields}>
                  <b>State:</b>
                  {viewModalData.state}
                </div>
              </div>
              <div style={{ textAlign: 'center' }}>
                <button
                  onClick={() => setViewModal(false)}
                  className={classes.viewModalButton}>
                  Close
                </button>
              </div>
            </div>
          </Fade>
        </Modal>
      </React.Fragment>
      <React.Fragment>
        <Modal
          isOpen={editModal}
          onRequestClose={() => {
            setEditModal(false)
          }}
          className={classes.editModal}>
          <Fade in={editModal}>
            <div className={classes.modalMain}>
              <p style={{ fontWeight: 'bold', textAlign: 'center' }}>
                EDIT RX ANALYTICS
              </p>
              <hr style={{ border: '1px solid black' }} />
              <form onSubmit={formik.handleSubmit}>
                <Grid>
                  <Grid>
                    <p className={classes.selectPtag}>RX Name</p>
                    <TextField
                      id="name"
                      className={classes.textField}
                      type="text"
                      placeholder="RX Name"
                      onChange={formik.handleChange}
                      value={formik && formik.values && formik.values.name}
                      onBlur={formik.handleBlur}
                      name="name"
                      helperText={
                        <div className={classes.errMsg}>
                          {formik.touched.name && formik.errors.name
                            ? formik.errors.name
                            : null}
                        </div>
                      }
                    />
                  </Grid>
                  <Grid>
                    <FormControl>
                      {/* <InputLabel
                        shrink
                        id="demo-simple-select-placeholder-label-label"
                      >
                        RX Type
                      </InputLabel> */}
                      <div style={{ display: 'inline-flex' }}>
                        <p className={classes.selectPtag}>RX Type:</p>
                        <Select
                          id="gender"
                          value={formik.values.type}
                          name="type"
                          label="RX Type"
                          className={classes.textField}
                          onBlur={formik.handleBlur}
                          helperText={
                            <div className={classes.errMsg}>
                              {formik.touched.type && formik.errors.type
                                ? formik.errors.type
                                : null}
                            </div>
                          }>
                          <MenuItem value="1">
                            <FiberSmartRecordIcon
                              style={{ color: '#202020' }}
                            />
                          </MenuItem>
                          <MenuItem value="2">
                            <LocalDrinkIcon style={{ color: '#202020' }} />
                          </MenuItem>
                        </Select>
                      </div>
                    </FormControl>
                  </Grid>
                  <Grid>
                    <p className={classes.selectPtag}>Date Added:</p>
                    <TextField
                      id="dateAdded"
                      className={classes.textField}
                      type="text"
                      placeholder="Date Added"
                      onChange={formik.handleChange}
                      value={formik && formik.values && formik.values.dateAdded}
                      onBlur={formik.handleBlur}
                      name="dateAdded"
                      // label="Date Added"
                      helperText={
                        <div className={classes.errMsg}>
                          {formik.touched.dateAdded && formik.errors.dateAdded
                            ? formik.errors.dateAdded
                            : null}
                        </div>
                      }
                    />
                  </Grid>
                  <Grid>
                    <p className={classes.selectPtag}>Prescribed Vol:</p>
                    <TextField
                      id="prescribedVol"
                      className={classes.textField}
                      type="text"
                      placeholder="Prescribed Vol"
                      onChange={formik.handleChange}
                      value={
                        formik && formik.values && formik.values.prescribedVol
                      }
                      onBlur={formik.handleBlur}
                      name="prescribedVol"
                      helperText={
                        <div className={classes.errMsg}>
                          {formik.touched.prescribedVol &&
                          formik.errors.prescribedVol
                            ? formik.errors.prescribedVol
                            : null}
                        </div>
                      }
                    />
                  </Grid>
                  <Grid>
                    <FormControl>
                      <div style={{ display: 'inline-flex', width: '100%' }}>
                        {/* <InputLabel
                          shrink
                          id="demo-simple-select-placeholder-label-label"
                        >
                          Market Demand
                        </InputLabel> */}
                        <p className={classes.selectPtag}>Market Demand</p>
                        <Select
                          id="gender"
                          value={formik.values.demand}
                          name="type"
                          label="Market Demand"
                          className={classes.textField}
                          onBlur={formik.handleBlur}
                          helperText={
                            <div className={classes.errMsg}>
                              {formik.touched.demand && formik.errors.demand
                                ? formik.errors.demand
                                : null}
                            </div>
                          }>
                          <MenuItem value="1">
                            <Chip
                              label="High"
                              style={{ backgroundColor: 'red' }}
                            />
                          </MenuItem>
                          <MenuItem value="2">
                            <Chip
                              label="Moderate"
                              style={{ backgroundColor: 'yellow' }}
                            />
                          </MenuItem>
                          <MenuItem value="3">
                            <Chip
                              label="Low"
                              style={{ backgroundColor: 'green' }}
                            />
                          </MenuItem>
                        </Select>
                      </div>
                    </FormControl>
                  </Grid>
                  <Grid>
                    <p className={classes.selectPtag}>State:</p>
                    <TextField
                      id="state"
                      className={classes.textField}
                      type="text"
                      placeholder="State"
                      onChange={formik.handleChange}
                      value={formik && formik.values && formik.values.state}
                      onBlur={formik.handleBlur}
                      name="state"
                      helperText={
                        <div className={classes.errMsg}>
                          {formik.touched.state && formik.errors.state
                            ? formik.errors.state
                            : null}
                        </div>
                      }
                    />
                  </Grid>
                  <Grid style={{ textAlign: 'center' }}>
                    <button type={'submit'} className={classes.viewModalButton}>
                      Confirm
                    </button>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Fade>
        </Modal>
      </React.Fragment>
    </React.Fragment>
  )
}
