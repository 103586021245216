import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import DoctorList from './DoctorList'
import RxList from './RxList'
import Revenue from './Revenue'
import RxAdd from './RxAdd'
import AddDoctor from './AddDoctor'
import Appointments from './Appointments'
import WalletList from './WalletList'
import TransactionAppointment from './TransactionAppointment'
import ClinicList from './ClinicList'
import Personaldetails from './Personaldetails'
import SubscriptionHistory from './SubscriptionHistory'
import { Link, useParams } from 'react-router-dom'
import SuperClinicList from './SuperClinicList'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  adminTab: {
    backgroundImage: 'linear-gradient(to right, #f5f5f5 , #f5f5f5)',
    padding: '0px',
    borderRadius: '5px',
    overflowX: 'auto',
    width: '97%',
    margin: '0 auto',
    '& .MuiTab-textColorPrimary.Mui-selected': {
      color: '#fff',
      fontWeight: 'lighter',
      background: '#9138aa',
      '@media (max-width:767px)': {
        fontSize: '14px',
        width: '200px',
        flexBasis: '200px'
      }
    },
    '& .MuiTab-textColorPrimary': {
      color: '#979797',
      fontSize: '12px',
      fontWeight: 'bold'
    }
  },
  tabcontentimg: {
    backgroundColor: '#fff'
    // height: '545px'
  }
}))

export default function SimpleTabs() {
  const classes = useStyles()
  const params = useParams()
  const [value, setValue] = React.useState('dashboard')

  //tab change state
  const [tabState, setTabState] = React.useState(false)

  const handleChange = (event, newValue) => {
    setValue(newValue)
    window.location.href = '/admin/' + newValue
  }

  React.useEffect(() => {
    if (params?.menu) {
      setValue(params.menu)
      setTabState(true)
    }
  }, [params])

  const initalizeTab = () => {
    setValue('dashboard')
  }

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        className={classes.adminTab}
        style={{ boxShadow: 'none' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="white"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example">
          <Tab
            label="My Doctors"
            value="dashboard"
            {...a11yProps('dashboard')}
          />
          <Tab
            label="Appointments"
            value="appointments"
            {...a11yProps('appointments')}
          />
          <Tab
            label="Add Doctor"
            value="add_doctor"
            {...a11yProps('add_doctor')}
          />
          <Tab
            label="Wallet Requests"
            value="wallet"
            {...a11yProps('wallet')}
          />
          <Tab
            label="Transactions(Appointments)"
            value="transactions"
            {...a11yProps('transactions')}
          />
          <Tab label="Clinics" value="clinics" {...a11yProps('clinics')} />
          <Tab label="Super Clinic" value="super_clinics" {...a11yProps('super_clinics')} />
          <Tab
            label="Subscription History"
            value="subscription"
            {...a11yProps('subscription')}
          />
        </Tabs>
      </AppBar>
      <TabPanel
        className={classes.tabcontentimg}
        value={value}
        index={'dashboard'}>
        <DoctorList tabState={tabState} />
      </TabPanel>
      <TabPanel
        className={classes.tabcontentimg}
        value={value}
        index={'appointments'}>
        <Appointments />
      </TabPanel>
      <TabPanel
        className={classes.tabcontentimg}
        value={value}
        index={'add_doctor'}>
        <Personaldetails setactiveTab={initalizeTab} />
      </TabPanel>
      <TabPanel
        className={classes.tabcontentimg}
        value={value}
        index={'wallet'}>
        <WalletList />
      </TabPanel>
      <TabPanel
        className={classes.tabcontentimg}
        value={value}
        index={'transactions'}>
        <TransactionAppointment />
      </TabPanel>
      <TabPanel
        className={classes.tabcontentimg}
        value={value}
        index={'clinics'}>
        <ClinicList tabState={tabState} />
      </TabPanel>
      <TabPanel
        className={classes.tabcontentimg}
        value={value}
        index={'super_clinics'}>
        <SuperClinicList />
      </TabPanel>
      <TabPanel
        className={classes.tabcontentimg}
        value={value}
        index={'subscription'}>
        <SubscriptionHistory />
      </TabPanel>
      {/* <TabPanel className={classes.tabcontentimg} value={value} index={6}>
        <Revenue />
      </TabPanel>
      <TabPanel className={classes.tabcontentimg} value={value} index={7}>
        Virtual MR
      </TabPanel>
      <TabPanel className={classes.tabcontentimg} value={value} index={8}>
        <RxAdd />
      </TabPanel> */}
    </div>
  )
}
