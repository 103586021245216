import React, { useEffect, useState, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import MaterialUIInput from '@material-ui/core/Input'
import PhoneInput, {
  isValidPhoneNumber,
  isPossiblePhoneNumber
} from 'react-phone-number-input'
import Box from '@material-ui/core/Box'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { Avatar } from '@material-ui/core'
import Profile from '../../components/commonComponents/Profile'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Slide from '@material-ui/core/Slide'
import swal from 'sweetalert'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import Loader from '../../components/commonComponents/Loader'
import {
  USER_ID,
  VALIDATE_PHONE,
  NETWORK_STATUS,
  VALIDATE_WHITESPACES,
  SINGLE_WHITESPACE
} from '../../Constants'
import { AdminStore } from '../../stores'
import SnackbarAlert from '../../components/commonComponents/SnackbarAlert'

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
    backgroundColor: 'rgb(16 29 71)'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  closebtn: {
    '& svg path': {
      fill: '#fff !important'
    }
  },
  Modal_popup: {
    height: '100%',
    position: 'absolute',
    top: '0',
    right: '0',
    zIndex: '99',
    cursor: 'pointer',
    display: 'none',
    /*This makes the button huge. If you want a bigger button, increase the font size*/
    fontSize: '50px',
    /*Opacity settings for all browsers*/
    opacity: '0',
    mozOpacity: '0',
    msFilter: 'progid:DXImageTransform.Microsoft.Alpha(opacity=0)'
  },
  drawer: {
    width: '20%',
    flexBasis: '20%',
    maxWidth: '260px'
  },
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch'
    }
  },
  Inputfield: {
    '@media (max-width:767px)': {
      width: '100% !important'
    }
  },
  aside: {
    // padding: '25px 0 !important'
  },
  appointmentPage: {
    background: '#F7F5F6',
    flexWrap: 'nowrap',
    justifyContent: 'center'
  },
  errMsg: {
    color: 'red'
  },
  errMsg2: {
    color: 'red',
    fontSize: '12px',
    paddingLeft: '5px',
    paddingTop: '4px'
  },
  Inputtext: {
    color: '#fff !important',
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#fff !important'
    },
    '& label': {
      color: '#ccc'
    },
    '& .MuiInput-input': {
      color: '#fff !important'
    }
  },
  Editprofilecol: {
    padding: '40px',
    '@media (max-width:767px)': {
      padding: '40px 15px 15px 0'
    },
    position: 'relative'
    // top: '10%'
  },
  paper: {
    padding: '10px',
    '@media (max-width:767px)': {
      marginTop: '40px'
    }
  },
  avatar: {
    width: '140px',
    height: '140px',
    background: 'white',
    borderRadius: '50%',
    boxShadow: `0 1px 1px rgba(0,0,0,0.12), 
          0 2px 2px rgba(0,0,0,0.12), 
          0 4px 4px rgba(0,0,0,0.12), 
          0 8px 8px rgba(0,0,0,0.12),
          0 16px 16px rgba(0,0,0,0.12)`,
    margin: '0px auto',
    textAlign: 'center',
    padding: '10px',
    display: 'flex'
  },
  image: {
    width: '100%',
    height: '100%'
  },
  AvatarCol: {
    width: '100%',
    height: '100%'
  },
  AppBar: {
    '& .MuiBox-root': {
      padding: '0'
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff'
    },
    checkboxes: {
      marginTop: '15px'
    }
  },
  inputChange: {
    width: '100%',
    paddingLeft: '8px',
    paddingTop: '23px',
    '& .PhoneInputInput': {
      fontSize: '16px',
      border: 'none',
      borderBottom: '1px solid #9E9E9E',
      margin: '10px'
    }
  },
  colorInherit: {
    color: '#f44336 !important'
  }
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const DoctorUpdate = React.forwardRef((props, ref) => {
  const { shortCode, doctor_id } = props
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [Image, previewImage] = useState('')
  const [file, setFile] = useState('')
  const [doctordetails, setDoctorDetails] = useState('')
  const [consultationFees, setConsultationFees] = useState([])
  const [inputValue, setInputValue] = useState('')
  const [multiButton, setmultiButton] = useState(false)
  const [alertType, setAlertType] = useState('')
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [isSnackBarOpen, setOpenSnackBar] = React.useState(false)
  const [docName, setDocName] = React.useState('')

  const {
    getDoctorDetails,
    updatedDoctorProfile,
    updateDoctorConsultationFees
  } = AdminStore.useStoreActions(actions => ({
    ...actions.profile
  }))

  const {
    networkStatus,
    getDoctorList,
    DoctorProfileStatus,
    DoctorFeesStatus
  } = AdminStore.useStoreState(state => ({
    ...state.profile
  }))

  //doctor list
  const { getMyDoctorsList } = AdminStore.useStoreActions(actions => ({
    ...actions.doctorList
  }))

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClickClose = () => {
    setOpen(false)
  }

  const handleSave = () => {
    // setOpen(false);
    save.current.click()
  }

  const submit = async values => {
    const file = values.file
    if (file) {
      if (
        file.name.split('.').pop().search('pdf') === 0 ||
        file.name.split('.').pop().search('docx') === 0
      ) {
        setOpenSnackBar(true)
        setSnackBarMessage(
          'Pdf and Docx file are not supported for profile picture!'
        )
        setmultiButton(false)
        setAlertType('info')
      } else if (
        file.name.split('.').pop().search('pdf') !== 0 ||
        file.name.split('.').pop().search('docx') !== 0
      ) {
        let base64 = await convertBase64(file)
        let format = base64.split(',')
        updatedDoctorProfile({
          ...values,
          doctor_id,
          format,
          docName: docName
        })
      }
    } else {
      updatedDoctorProfile({
        ...values,
        doctor_id
      })
    }
    if (values.mobile_no && isValidPhoneNumber(values.mobile_no)) {
      // console.log('true')
    } else {
      swal(
        'Wrong!',
        'The mobile number entered is not a valid number!',
        'error'
      )
    }

    // if (networkStatus == 'SUCCESS') {
    //   setOpen(false)
    // }
  }

  useEffect(() => {
    if (shortCode !== undefined && open) {
      formik.resetForm()
      getDoctorDetails({ user_short_code: shortCode })
    }
  }, [open])

  useEffect(() => {
    if (getDoctorList && getDoctorList.user_details) {
      setDoctorDetails(getDoctorList.user_details)

      setConsultationFees(getDoctorList.user_details.fees)

      previewImage(getDoctorList.user_details.picture)

      setInputValue(getDoctorList.user_details.mobile_no)
    }
  }, [getDoctorList])

  useEffect(() => {
    if (DoctorProfileStatus === undefined || DoctorProfileStatus === '') {
      // setSnackOpen(true)
    } else if (
      DoctorProfileStatus.statusCode === 400 ||
      DoctorProfileStatus.statusCode === 533
    ) {
      setOpenSnackBar(true)
      setSnackBarMessage(DoctorProfileStatus.message.replace(/['"]+/g, ''))
      // setSnackBarMessage('Patient profile not updated successfully')
      setmultiButton(false)
      setAlertType('error')
    } else {
      updateDoctorConsultationFees({
        in_person_fees: formik?.values?.in_person_fees,
        doctor_id,
        tele_fees: formik?.values?.tele_fees
      }).then(() => {
        setOpenSnackBar(true)
        setSnackBarMessage('Doctor profile updated successfully')
        setmultiButton(false)
        setAlertType('success')
        setTimeout(() => {
          setOpen(false)
        }, 2000)
      })
    }
    if (DoctorFeesStatus === undefined || DoctorFeesStatus === '') {
      // setSnackOpen(true)
    } else if (
      DoctorFeesStatus.statusCode === 400 ||
      DoctorFeesStatus.statusCode === 533
    ) {
      setOpenSnackBar(true)
      setSnackBarMessage(DoctorFeesStatus.message.replace(/['"]+/g, ''))
      // setSnackBarMessage('Patient profile not updated successfully')
      setmultiButton(false)
      setAlertType('error')
    } else {
      setOpenSnackBar(true)
      setSnackBarMessage('Doctor consultation fees updated successfully')
      setmultiButton(false)
      setAlertType('success')
    }
  }, [DoctorProfileStatus, DoctorFeesStatus])

  const convertBase64 = file => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result)
      }

      fileReader.onerror = error => {
        reject(error)
      }
    })
  }
  const formik = useFormik({
    initialValues: {
      name: doctordetails.name ? doctordetails.name : '',
      username: doctordetails.username ? doctordetails.username : '',
      mobile_no: doctordetails.mobile_no ? doctordetails.mobile_no : '',
      email: doctordetails.email ? doctordetails.email : '',
      picture: doctordetails.picture ? doctordetails.picture : '',
      prefix: doctordetails.prefix ? doctordetails.prefix : '',
      user_short_code: doctordetails.user_short_code
        ? doctordetails.user_short_code
        : '',
      experience: doctordetails.experience ? doctordetails.experience : '',
      qualification: doctordetails.qualification
        ? doctordetails.qualification
        : '',
      specialization: doctordetails.specialization
        ? doctordetails.specialization
        : '',
      bio: doctordetails.bio ? doctordetails.bio : '',
      clinic_address: doctordetails.clinic_address
        ? doctordetails.clinic_address
        : '',

      clinic_name: doctordetails.clinic_name ? doctordetails.clinic_name : '',

      associated_with: doctordetails?.associated_with
        ? doctordetails?.associated_with
        : '',
      in_person_fees:
        consultationFees.length === 0
          ? ''
          : Number(
              consultationFees.find(
                i =>
                  i.appointment_type === 'in-person appointment' &&
                  i.visit_type == 1
              ).fees
            ),

      tele_fees:
        consultationFees.length === 0
          ? ''
          : Number(
              consultationFees.find(
                i =>
                  i.appointment_type === 'tele appointment' && i.visit_type == 1
              ).fees
            )
    },

    //Control whether Formik should reset the form if initialValues changes (enableReinitialize)
    enableReinitialize: true,
    validationSchema,
    onSubmit: submit
  })

  const hiddenFileInput = useRef()
  const save = useRef()

  const handleClick = event => {
    hiddenFileInput.current.click()
  }

  const handleChange = event => {
    if (event.target.files[0] == undefined) {
    } else {
      formik.setFieldValue('file', event.currentTarget.files[0])
      event.preventDefault()

      let reader = new FileReader()
      let fileimage = event.target.files[0]
      setDocName(fileimage.name)
      reader.onloadend = () => {
        setFile(fileimage)
        previewImage(reader.result)
      }
      reader.readAsDataURL(fileimage)
    }
  }

  const phoneChange = value => {
    setInputValue(value)
    formik.setFieldValue('mobile_no', value ? value : '')
  }
  const showSnackBar = () => {
    setOpenSnackBar(true)
  }

  const hideSnackBar = () => {
    setOpenSnackBar(false)
  }

  return (
    <div>
      <Button
        ref={ref}
        className={classes.Modal_popup}
        onClick={handleClickOpen}>
        Open full-screen dialog
      </Button>

      <Dialog
        fullScreen
        open={open}
        onClose={handleClickClose}
        TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              className={classes.closebtn}
              edge="start"
              onClick={handleClickClose}
              aria-label="close"
              style={{ color: '#fff !important' }}>
              <CloseIcon style={{ color: '#fff !important' }} />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Update Profile
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={handleSave}
              style={{
                border: '1px rgba(255,255,255, 0.5) solid',
                borderRadius: '40px',
                padding: '5px 20px'
              }}>
              <b>save</b>
            </Button>
          </Toolbar>
        </AppBar>
        {/* <List>
            <ListItem button>
              <ListItemText primary="Phone ringtone" secondary="Titania" />
            </ListItem>
            <Divider />
            <ListItem button>
              <ListItemText primary="Default notification ringtone" secondary="Tethys" />
            </ListItem>
          </List> */}
        <div className={classes.Editprofilecol}>
          <Paper elevation={3} className={classes.paper}>
            {networkStatus === NETWORK_STATUS.FETCHING ? (
              <Loader open={true} />
            ) : (
              <Loader />
            )}

            <SnackbarAlert
              isOpen={isSnackBarOpen}
              message={snackBarMessage}
              alertType={alertType}
              multibutton={multiButton}
              primaryClick={hideSnackBar}
            />
            <form
              onSubmit={formik.handleSubmit}
              noValidate={true}
              className={classes.root}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={8} className={classes.Editprofileleft}>
                  <TextField
                    className={classes.Inputfield}
                    id="prefix"
                    style={{ width: '92%' }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Prefix"
                    type="text"
                    name="prefix"
                    label="Prefix"
                    value={formik && formik.values && formik.values.prefix}
                    helperText={
                      <div className={classes.errMsg}>
                        {formik.touched.prefix && formik.errors.prefix
                          ? formik.errors.prefix
                          : null}
                      </div>
                    }
                  />
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={5}>
                      <TextField
                        id="name"
                        style={{ width: '100%' }}
                        type="text"
                        placeholder="Name"
                        onChange={formik.handleChange}
                        value={formik && formik.values && formik.values.name}
                        onBlur={formik.handleBlur}
                        name="name"
                        label="Name *"
                        helperText={
                          <div className={classes.errMsg}>
                            {formik.touched.name && formik.errors.name
                              ? formik.errors.name
                              : null}
                          </div>
                        }
                      />
                      <TextField
                        id="user_short_code"
                        style={{ width: '100%' }}
                        placeholder="user short code"
                        name="user_short_code"
                        label="User short code *"
                        value={
                          formik &&
                          formik.values &&
                          formik.values.user_short_code
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        helperText={
                          <div className={classes.errMsg}>
                            {formik.touched.user_short_code &&
                            formik.errors.user_short_code
                              ? formik.errors.user_short_code
                              : null}
                          </div>
                        }
                      />
                      <PhoneInput
                        placeholder="Enter mobile number"
                        international
                        id="mobile_no"
                        value={inputValue}
                        className={classes.inputChange}
                        name="mobile_no"
                        onChange={phoneChange}
                        // onChange={inputValue => console.log(inputValue)}
                        error={
                          formik.values.mobile_no
                            ? isValidPhoneNumber(formik.values.mobile_no)
                              ? undefined
                              : 'Invalid mobile number'
                            : 'Mobile number required'
                        }
                      />
                      {
                        <div className={classes.errMsg2}>
                          {formik.touched.mobile_no && formik.errors.mobile_no
                            ? formik.errors.mobile_no
                            : null}
                        </div>
                      }
                      <TextField
                        id="experience"
                        style={{ width: '100%' }}
                        type="text"
                        placeholder="5"
                        label="Experience *"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={
                          formik && formik.values && formik.values.experience
                        }
                        name="experience"
                        helperText={
                          <div className={classes.errMsg}>
                            {formik.touched.experience &&
                            formik.errors.experience
                              ? formik.errors.experience
                              : null}
                          </div>
                        }
                      />
                    </Grid>
                    <Grid item xs={1} />
                    <Grid item xs={12} sm={5}>
                      <TextField
                        id="username"
                        style={{ width: '100%' }}
                        type="text"
                        placeholder="User name"
                        value={
                          formik && formik.values && formik.values.username
                        }
                        name="username"
                        label="User name *"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        // disabled={true}
                        helperText={
                          <div className={classes.errMsg}>
                            {formik.touched.username && formik.errors.username
                              ? formik.errors.username
                              : null}
                          </div>
                        }
                      />
                      <TextField
                        id="email"
                        style={{ width: '100%' }}
                        placeholder="jane@gmail.com"
                        name="email"
                        label="Email *"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik && formik.values && formik.values.email}
                        helperText={
                          <div className={classes.errMsg}>
                            {formik.touched.email && formik.errors.email
                              ? formik.errors.email
                              : null}
                          </div>
                        }
                      />
                      <TextField
                        id="qualification"
                        style={{ width: '100%' }}
                        type="text"
                        name="qualification"
                        label=" Qualification*"
                        placeholder="Qualification"
                        value={
                          formik && formik.values && formik.values.qualification
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        helperText={
                          <div className={classes.errMsg}>
                            {formik.touched.qualification &&
                            formik.errors.qualification
                              ? formik.errors.qualification
                              : null}
                          </div>
                        }
                      />
                      <TextField
                        id="specialization"
                        style={{ width: '100%' }}
                        type="text"
                        name="specialization"
                        label="Specialization *"
                        value={
                          formik &&
                          formik.values &&
                          formik.values.specialization
                        }
                        placeholder="Specalization"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        helperText={
                          <div className={classes.errMsg}>
                            {formik.touched.specialization &&
                            formik.errors.specialization
                              ? formik.errors.specialization
                              : null}
                          </div>
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      className={classes.Inputfield}
                      id="bio"
                      style={{ width: '92%' }}
                      type="text"
                      label="Bio *"
                      value={formik && formik.values && formik.values.bio}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="bio"
                      placeholder="Bio"
                      helperText={
                        <div className={classes.errMsg}>
                          {formik.touched.bio && formik.errors.bio
                            ? formik.errors.bio
                            : null}
                        </div>
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="associated_with"
                      style={{ width: '92%' }}
                      type="text"
                      label="Associated With *"
                      value={
                        formik && formik.values && formik.values.associated_with
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="associated_with"
                      placeholder="Associated with"
                      helperText={
                        <div className={classes.errMsg}>
                          {formik.touched.associated_with &&
                          formik.errors.associated_with
                            ? formik.errors.associated_with
                            : null}
                        </div>
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      className={classes.Inputfield}
                      id="clinic_name"
                      disabled="true"
                      style={{ width: '92%' }}
                      type="text"
                      label="Clinic Name"
                      value={
                        formik && formik.values && formik.values.clinic_name
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="clinic_name"
                      placeholder="Clinic name"
                      helperText={
                        <div className={classes.errMsg}>
                          {formik.touched.clinic_name &&
                          formik.errors.clinic_name
                            ? formik.errors.clinic_name
                            : null}
                        </div>
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      className={classes.Inputfield}
                      id="clinic_address"
                      disabled="true"
                      style={{ width: '92%' }}
                      type="text"
                      label="Clinic address"
                      value={
                        formik && formik.values && formik.values.clinic_address
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="clinic_address"
                      placeholder="Clinic address"
                      helperText={
                        <div className={classes.errMsg}>
                          {formik.touched.clinic_address &&
                          formik.errors.clinic_address
                            ? formik.errors.clinic_address
                            : null}
                        </div>
                      }
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4} className={classes.Editprofileright}>
                  <Box
                    style={{
                      width: '140px',
                      height: '140px',
                      background: 'white',
                      borderRadius: '50%',
                      margin: '25px auto',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}>
                    {Image ? (
                      <Avatar className={classes.AvatarCol} src={Image} />
                    ) : (
                      <Profile />
                    )}
                  </Box>
                  <label
                    style={{
                      textAlign: 'center',
                      margin: '20px auto',
                      display: 'block',
                      fontWeight: 'bold',
                      width: '150px'
                    }}>
                    <div>
                      <Button
                        onClick={handleClick}
                        style={{
                          width: '140px',
                          borderRadius: '50px',
                          background: '#3f4b79',
                          border: 'none',
                          height: '40px',
                          textAlign: 'center',
                          color: '#fff',
                          // lineHeight: '40px',
                          cursor: 'pointer'
                        }}>
                        Edit Picture
                      </Button>
                      <input
                        id="file"
                        name="file"
                        type="file"
                        accept=" image/jpeg, image/png"
                        ref={hiddenFileInput}
                        onChange={handleChange}
                        className={classes.Modal_popup}
                      />
                    </div>
                  </label>
                  <Box
                    style={{
                      width: '95%',

                      padding: '45px',

                      background: '#111d4e',

                      fontSize: '16px',

                      color: '#fff',

                      borderRadius: '20px'
                    }}>
                    <p style={{ margin: '0px auto' }}>Consultation Fees</p>
                    <p>
                      <TextField
                        className={classes.Inputtext}
                        id="in_person_fees"
                        style={{ width: '92%' }}
                        placeholder=""
                        type="number"
                        name="in_person_fees"
                        label="In-Person Appointment Fees *"
                        value={
                          formik &&
                          formik.values &&
                          formik.values.in_person_fees
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        helperText={
                          <div className={classes.errMsg}>
                            {formik.touched.in_person_fees &&
                            formik.errors.in_person_fees
                              ? formik.errors.in_person_fees
                              : null}
                          </div>
                        }
                      />{' '}
                    </p>
                    <p>
                      <TextField
                        className={classes.Inputtext}
                        id="tele_fees"
                        style={{ width: '92%' }}
                        placeholder=""
                        type="number"
                        name="tele_fees"
                        label="TeleConsultation Fees *"
                        value={
                          formik && formik.values && formik.values.tele_fees
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        helperText={
                          <div className={classes.errMsg}>
                            {formik.touched.tele_fees && formik.errors.tele_fees
                              ? formik.errors.tele_fees
                              : null}
                          </div>
                        }
                      />{' '}
                    </p>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <button
                    type={'submit'}
                    className={classes.Modal_popup}
                    ref={save}>
                    Save
                  </button>
                </Grid>
                {/* </Grid> */}
              </Grid>
            </form>
            {/* </Grid> */}
          </Paper>
        </div>
      </Dialog>
    </div>
  )
})

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .required('Name is required')

    .matches(/^([^0-9]*)$/, 'Digits are not allowed')
    .max(30, 'Max 30 characters allowed')
    .matches(VALIDATE_WHITESPACES, 'Remove extra spaces'),
  username: yup
    .string()
    .required('Username is required')

    // .matches(/^([^0-9]*)$/, 'Digits are not allowed')
    .max(50, 'Max 50 characters allowed')
    .matches(SINGLE_WHITESPACE, 'Remove extra spaces'),
  email: yup
    .string()
    .email('Invalid email address')
    .required('Email is required')

    // .matches(/^([^0-9]*)$/, 'Digits are not allowed')
    .max(50, 'Max 50 characters allowed')
    .matches(VALIDATE_WHITESPACES, 'Remove extra spaces'),
  mobile_no: yup
    .string()
    .matches(VALIDATE_PHONE, ' Please enter a valid mobile number with +91')
    .max(13, 'You may enter up to 13 digits')
    .typeError('Phone number cant contain characters')
    .required('Mobile number is Required'),
  bio: yup
    .string()
    .required('Bio is required')
    .max(200, 'Max 200 characters allowed')
    // .matches(/^([^0-9]*)$/, 'Digits are not allowed')
    .matches(VALIDATE_WHITESPACES, 'Remove extra spaces'),
  user_short_code: yup
    .string()
    .required('User short code is required')
    .max(30, 'Max 30 characters allowed')
    .matches(
      VALIDATE_WHITESPACES,
      'Space should not come before and after text!'
    ),
  qualification: yup
    .string()
    .required('Qualification is required')
    .max(50, 'Max 50 characters allowed')
    .matches(/^([^0-9]*)$/, 'Digits are not allowed')
    .matches(VALIDATE_WHITESPACES, 'Remove extra spaces'),
  prefix: yup
    .string()

    .required('Prefix is required')
    .matches(VALIDATE_WHITESPACES, 'Remove extra spaces'),
  specialization: yup
    .string()
    .required('Specalization is required')
    .matches(/^([^0-9]*)$/, 'Digits are not allowed')
    .max(50, 'Max 50 characters allowed')
    .matches(VALIDATE_WHITESPACES, 'Remove extra spaces'),
  experience: yup
    .string()
    .required('Experience is required')
    .matches(/^[0-9]*$/, 'Only numbers are allowed')
    .max(2, 'Experience should be within 0-99 years')
    .matches(SINGLE_WHITESPACE, 'Remove extra spaces'),
  in_person_fees: yup
    .number()
    .typeError('Enter a valid number')
    .moreThan(-1, 'Fees cannot be a negative value')
    .lessThan(100000, 'Fees cannot exceed 1 Lakh')
    .required('In-person fees is required'),
  tele_fees: yup
    .number()
    .typeError('Enter a valid number')
    .moreThan(-1, 'Fees cannot be a negative value')
    .lessThan(100000, 'Fees cannot exceed 1 Lakh')
    .required('Tele fees is required'),
  clinic_name: yup
    .string()
    // .trim()
    // .required('Clinic Name is required')
    .max(100, 'Max 100 characters allowed')
    .matches(VALIDATE_WHITESPACES, 'Remove extra spaces'),

  clinic_address: yup
    .string()
    // .trim()
    // .required('Clinic Address is required')
    .max(100, 'Max 100 characters allowed')
    .matches(VALIDATE_WHITESPACES, 'Remove extra spaces'),
  associated_with: yup
    .string()
    .required('Associated with is required')
    .max(100, 'Max 100 characters allowed')
    .matches(VALIDATE_WHITESPACES, 'Remove extra spaces')
})
export default DoctorUpdate
