import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  FormControl,
  FormGroup,
  FormControlLabel,
  InputAdornment
} from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import { AdminStore } from '../../stores'
import Loading from 'react-loading'
import { debounce } from 'lodash'
import NoRecordImg from '../../images/noRecordFound.png'
import moment from 'moment'
import { FaSearch } from 'react-icons/fa'
import SearchIcon from '@material-ui/icons/Search'

const columns = [
  {
    id: 'appointment_id',
    label: 'Appointment ID',
    maxWidth: 220
    // align: 'center'
  },
  {
    id: 'doctor_name',
    label: 'Doctor Name',
    minWidth: 200
    //  align: 'center'
  },
  {
    id: 'patient_name',
    label: 'Patient Name',
    maxWidth: 180
    //  align: 'center'
  },
  {
    id: 'appointment_type',
    label: 'Appointment Type',
    maxWidth: 150
    // align: 'center'
  },
  {
    id: 'date_and_time',
    label: 'Date and Time',
    minWidth: 170
    // align: 'center'
  },
  {
    id: 'amount_paid',
    label: 'Amount Paid',
    maxWidth: 150
    //  align: 'center'
  },
  {
    id: 'amount_recived',
    label: 'Amount Received',
    maxWidth: 150
    // align: 'center'
  },
  {
    id: 'gst',
    label: 'GST',
    maxWidth: 150
    //  align: 'center'
  }
]

const useStyles = makeStyles({
  root: {
    width: '100%'
  },
  paper: {
    padding: '10px',
    '@media (max-width:767px)': {
      marginTop: '40px'
    }
  },
  container: {
    maxHeight: 440
  },
  tablecontent: {
    '& h6': {
      fontSize: '16px',
      padding: '15px 10px'
    },
    '& h5': {
      fontSize: '16px',
      fontWeight: 'bold',
      color: '#fff',
      margin: '0px'
    },
    '& p': {
      fontSize: '16px',
      marginBottom: '0px',
      fontWeight: '400'
    },
    '& .MuiTableCell-head': {
      color: '#fff',
      background: '#111d4e'
    },
    '& .MuiTableCell-root': {
      padding: '20px 10px'
    },
    '& .MuiPaper-rounded': {
      boxShadow: 'none !important',
      borderRadius: '0px !important'
    }
  },
  loadingSpinner: {
    position: 'absolute',
    top: '42px',
    right: '150px',
    left: '430px',
    '@media (max-width:976px)': {
      right: '5px'
    }
  },
  searchicon: {
    position: 'absolute',
    top: '40px',
    right: '175px',
    '@media (max-width:976px)': {
      right: '25px'
    }
  },
  Searchcol: {
    '@media (max-width:767px)': {
      marginBottom: '15px'
    }
  }
})
const TransactionAppointment = () => {
  const classes = useStyles()
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [transactionsData, setTransactionsData] = React.useState([])
  const [searchString, setSearchString] = React.useState('')

  const { getTransactionsList } = AdminStore.useStoreActions(actions => ({
    ...actions.doctorList
  }))

  const {
    transactionsList,
    transactionsRowCount,
    networkStatus
  } = AdminStore.useStoreState(state => ({
    ...state.doctorList
  }))

  useEffect(() => {
    setTransactionsData(transactionsList)
  }, [transactionsList])

  const debouncedGetTransactionsList = React.useCallback(
    debounce(
      searchString =>
        getTransactionsList({
          pattern: searchString,
          table_config_page_no: 1,
          table_config_rows_per_page: rowsPerPage
        }),
      1000
    ),
    []
  )

  useEffect(() => {
    getTransactionsList({
      pattern: searchString,
      table_config_page_no: page + 1,
      table_config_rows_per_page: rowsPerPage
    })
  }, [rowsPerPage, page])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = async event => {
    await setRowsPerPage(+event.target.value)
    await setPage(0)
  }

  const onSearchUsed = e => {
    setSearchString(e.target.value)
    debouncedGetTransactionsList(e.target.value)
  }

  function convertTime(time) {
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time]

    if (time.length > 1) {
      time = time.slice(1)
      time[5] = +time[0] < 12 ? 'AM' : 'PM'
      time[0] = +time[0] % 12 || 12
    }
    return time.join('')
  }

  return (
    <React.Fragment>
      <Paper elevation={3} className={classes.paper}>
        <Grid item xs={12} md={5}>
          <FormControl component="fieldset" style={{ width: '100%' }}>
            <FormGroup aria-label="position" row style={{ width: '100%' }}>
              <FormControlLabel
                className={classes.formcol}
                style={{ width: '100%' }}
                control={
                  <TextField
                    id="standard-basic"
                    label="Search Doctor"
                    style={{ margin: '20px 30px', width: '400px' }}
                    value={searchString}
                    onChange={onSearchUsed}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      )
                    }}
                  />
                }
              />
              {/* <FormControlLabel
                className={classes.searchicon}
                control={<FaSearch />}
              /> */}
              {networkStatus == 'FETCHING' ? (
                <Loading
                  type="spin"
                  textalign="center"
                  color="grey"
                  height={20}
                  width={20}
                  className={classes.loadingSpinner}
                />
              ) : null}
            </FormGroup>
          </FormControl>
        </Grid>
        {transactionsData == undefined ||
        (transactionsData.length == 0 && networkStatus !== 'FETCHING') ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <img alt="No data" style={{ width: '200px' }} src={NoRecordImg} />
          </div>
        ) : (
          <Grid item xs={12} sm={12} md={12} className={classes.tablecontent}>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead stickyHeader aria-label="sticky table">
                  <TableRow
                    style={{ background: 'rgb(16 29 71)', color: '#fff' }}>
                    {columns.map(column => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}>
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transactionsData.slice(0, rowsPerPage).map(data => {
                    return (
                      <TableRow>
                        {columns.map(column => {
                          const val = column.id
                          if (val == 'date_and_time') {
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {`${moment(data['date']).format('DD/MM/YYYY')},
                              ${convertTime(data['time'])}`}
                              </TableCell>
                            )
                          } else if (val == 'appointment_type') {
                            if (data[val] == '1')
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  In-Person
                                </TableCell>
                              )
                            else
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  Tele
                                </TableCell>
                              )
                          } else {
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {data[val]}
                              </TableCell>
                            )
                          }
                        })}
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
        {transactionsData ? (
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={
              transactionsRowCount !== undefined ? transactionsRowCount : 0
            }
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        ) : null}
      </Paper>
    </React.Fragment>
  )
}

export default TransactionAppointment
