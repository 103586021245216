import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import EditIcon from '@material-ui/icons/Edit'
import VisibilityIcon from '@material-ui/icons/Visibility'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

const columns = [
  { id: 'id', label: 'Doctor ID', minWidth: 170 },
  { id: 'name', label: 'Doctor Name', minWidth: 170 },
  { id: 'start', label: 'Start Date', minWidth: 100 },
  {
    id: 'end',
    label: 'End Date',
    minWidth: 170,
    align: 'right'
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 170,
    align: 'right'
  },
  {
    id: 'amt',
    label: 'Subscription Paid',
    minWidth: 170,
    align: 'right'
  },
  {
    id: 'gst',
    label: 'GST',
    minWidth: 170,
    align: 'right'
  },
  {
    id: 'payable',
    label: 'Total',
    minWidth: 170,
    align: 'right'
  }
]

function createData(id, name, start, end, status, amt, gst, payable) {
  return { id, name, start, end, status, amt, gst, payable }
}

const rows = [
  createData(
    1890 - 466 - 11861,
    'Dr Subho Paul ',
    '12-09-2020',
    '11-10-2020',
    'Active',
    1499,
    99,
    1400
  ),
  createData(
    8890 - 886 - 77861,
    'Dr Swaroop Anand',
    '12-09-2020',
    '11-10-20201',
    'Active',
    14999,
    899,
    14100
  ),
  createData(
    3822 - 109 - 21263,
    'Dr Abhisek Sengupta ',
    '12-09-2020',
    '11-10-2020',
    'Active',
    1499,
    99,
    1400
  ),
  createData(
    5772 - 300 - 99869,
    'Dr A D Sharma',
    '12-09-2020',
    '11-10-2020',
    'Active',
    1499,
    99,
    1400
  ),
  createData(
    1890 - 466 - 11861,
    'Dr Ajay Bannerjee ',
    '12-09-2020',
    '11-10-2020',
    'Active',
    1499,
    99,
    1400
  ),
  createData(
    3822 - 109 - 21263,
    'Dr Monosij',
    '12-09-2020',
    '11-10-2020',
    'Active',
    1499,
    99,
    1400
  ),
  createData(
    2622 - 222 - 11298,
    'Dr Pathikrit Sanyal',
    '12-09-2020',
    '11-10-2020',
    'Active',
    1499,
    99,
    1400
  ),
  createData(
    9824 - 645 - 91293,
    'Dr B Dutta',
    '12-09-2020',
    '11-10-2020',
    'Active',
    1499,
    99,
    1400
  ),
  createData(
    2222 - 402 - 21931,
    'Dr John Doe ',
    '12-09-2020',
    '11-10-2020',
    'Active',
    1499,
    99,
    1400
  ),
  createData(
    1831 - 773 - 39763,
    'Dr Rahul A K',
    '12-09-2020',
    '11-10-2020',
    'Active',
    1499,
    99,
    1400
  ),
  createData(
    1831 - 773 - 39763,
    'Dr Indroneel ray',
    '12-09-2020',
    '11-10-2020',
    'Active',
    1499,
    99,
    1400
  ),
  createData(
    1831 - 773 - 39763,
    'Dr Indroneel ray',
    '12-09-2020',
    '11-10-2020',
    'Active',
    1499,
    99,
    1400
  ),
  createData(
    1831 - 773 - 39763,
    'Dr Indroneel ray',
    '12-09-2020',
    '11-10-2020',
    'Active',
    1499,
    99,
    1400
  ),
  createData(
    1831 - 773 - 39763,
    'Dr Indroneel ray',
    '12-09-2020',
    '11-10-2020',
    'Active',
    1499,
    99,
    1400
  ),
  createData(
    1831 - 773 - 39763,
    'Dr Indroneel ray',
    '12-09-2020',
    '11-10-2020',
    'Active',
    1499,
    99,
    1400
  ),
  createData(
    1831 - 773 - 39763,
    'Dr Indroneel ray',
    '12-09-2020',
    '11-10-2020',
    'Active',
    1499,
    99,
    1400
  ),
  createData(
    1831 - 773 - 39763,
    'Dr Indroneel ray',
    '12-09-2020',
    '11-10-2020',
    'Active',
    1499,
    99,
    1400
  ),
  createData(
    1831 - 773 - 39763,
    'Dr Indroneel ray',
    '12-09-2020',
    '11-10-2020',
    'Active',
    1499,
    99,
    1400
  ),
  createData(
    1831 - 773 - 39763,
    'Dr Indroneel ray',
    '12-09-2020',
    '11-10-2020',
    'Active',
    1499,
    99,
    1400
  )
]

const useStyles = makeStyles({
  root: {
    width: '100%'
  },
  container: {
    maxHeight: 440
  }
})
export default function StickyHeadTable() {
  const classes = useStyles()
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = async event => {
    await setRowsPerPage(+event.target.value)
    await setPage(0)
  }

  return (
    <Paper className={classes.root}>
      <Grid container>
        <Grid item xs={12} md={3} style={{ padding: '15px' }}>
          <Paper elevation={3} style={{ padding: '15px' }}>
            <Typography variant="h4" className={classes.annotation}>
              1248
            </Typography>
            <p>Active Doctors</p>
          </Paper>
        </Grid>
        <Grid item xs={12} md={3} style={{ padding: '15px' }}>
          <Paper elevation={3} style={{ padding: '15px' }}>
            <Typography variant="h4" className={classes.annotation}>
              2311
            </Typography>
            <p>Total Doctors</p>
          </Paper>
        </Grid>
        <Grid item xs={12} md={3} style={{ padding: '15px' }}>
          <Paper elevation={3} style={{ padding: '15px' }}>
            <Typography variant="h4" className={classes.annotation}>
              INR 1289671
            </Typography>
            <p>Total Revenue</p>
          </Paper>
        </Grid>
        <Grid item xs={12} md={3} style={{ padding: '15px' }}>
          <Paper elevation={3} style={{ padding: '15px' }}>
            <Typography variant="h4" className={classes.annotation}>
              INR 79112
            </Typography>
            <p>GST</p>
          </Paper>
        </Grid>

        <TextField
          id="standard-basic"
          label="Search Doctor"
          style={{ margin: '20px 30px', width: '400px' }}
        />
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(row => {
                  return (
                    <>
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}>
                        {columns.map(column => {
                          const value = row[column.id]
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format && typeof value === 'number'
                                ? column.format(value)
                                : value}
                            </TableCell>
                          )
                        })}
                      </TableRow>
                    </>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  )
}
