import React, { useState, useEffect } from 'react'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import Paper from '@material-ui/core/Paper'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'
import StarIcon from '@material-ui/icons/StarBorder'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { AdminStore } from '../../stores'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import copy from 'copy-to-clipboard'
import swal from 'sweetalert'

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

const useStyles = makeStyles(theme => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none'
    }
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  toolbar: {
    flexWrap: 'wrap'
  },
  toolbarTitle: {
    flexGrow: 1
  },
  link: {
    margin: theme.spacing(1, 1.5)
  },
  heroContent: {
    padding: theme.spacing(3.5, 0, 3.5)
  },
  cardHeader: {
    //   backgroundColor:
    //     theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
    backgroundColor: '#111d4e'
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2)
  },
  // footer: {
  //   borderTop: `1px solid ${theme.palette.divider}`,
  //   marginTop: theme.spacing(8),
  //   paddingTop: theme.spacing(3),
  //   paddingBottom: theme.spacing(3),
  //   [theme.breakpoints.up('sm')]: {
  //     paddingTop: theme.spacing(6),
  //     paddingBottom: theme.spacing(6),
  //   },
  // },
  box: {
    padding: '40px'
  },
  paper: {
    padding: '10px',
    '@media (max-width:767px)': {
      marginTop: '40px'
    }
  }
}))

const Plandetails = React.forwardRef((props, ref) => {
  const classes = useStyles()
  const [textValue, setTextValue] = useState('')
  const [planid, setPlanId] = useState('')

  const { addSubscriptionChoice } = AdminStore.useStoreActions(actions => ({
    ...actions.profile
  }))

  const {
    getSubscriptionList,
    subscriptionChoiceStatus
  } = AdminStore.useStoreState(state => ({
    ...state.profile
  }))
  const tiers = getSubscriptionList.response

  const submit = e => {
    e.preventDefault()
    const plan = e.target.textContent.split(':')
    const plan_id = plan[3].charAt(1)

    addSubscriptionChoice({ subscription_id: plan_id })
  }

  // if(textValue !== ""){
  //     const plan = textValue.split(" ")
  //     const plan_id = plan[8].charAt(0)
  //     setPlanId(plan_id)
  //     }

  // swal("Profile Created!", "Your password is in clipboard!", "success");
  useEffect(() => {
    if (
      subscriptionChoiceStatus &&
      subscriptionChoiceStatus.statusCode === 201
    ) {
      copy(subscriptionChoiceStatus.password)
      swal(
        'Profile Created!',
        'Your Password is stored in Clipboard!',
        'success'
      )
    }
  }, [subscriptionChoiceStatus])

  return (
    <div className={classes.box}>
      {/* <Box border={1} style={{ boxShadow: '5px 10px #3f4b79' }}> */}
      <Paper elevation={3} className={classes.paper}>
        <React.Fragment>
          {/* <CssBaseline /> */}
          {/* Hero unit */}
          <Container
            maxWidth="sm"
            component="main"
            className={classes.heroContent}>
            <Typography
              variant="h5"
              align="center"
              color="textSecondary"
              component="p">
              Select from our curated list of plans to enhance your experience.
            </Typography>
          </Container>
          {/* End hero unit */}
          <Container maxWidth="md" component="main">
            <Grid
              container
              spacing={5}
              style={{
                margin: '0px',
                display: 'flex',
                position: 'relative',
                right: '35%'
              }}
              alignItems="flex-end">
              {tiers.map(tier => (
                // Enterprise card is full width at sm breakpoint
                <Grid
                  item
                  key={tier.title}
                  xs={12}
                  sm={tier.title === 'Enterprise' ? 12 : 6}
                  md={4}>
                  <form onSubmit={submit}>
                    <Card>
                      {/* <CardHeader
                        style={{ textTransform: 'capitalize', color: 'white' }}
                        title={tier.subscription_name}
                        subheader={
                          tier.subscription_name === 'premium extension'
                            ? 'Most Popular'
                            : ''
                        }
                        titleTypographyProps={{ align: 'center' }}
                        subheaderTypographyProps={{ align: 'center' }}
                        action={
                          tier.subscription_name === 'premium extension' ? (
                            <StarIcon style={{ color: 'gold' }} />
                          ) : null
                        }
                        className={classes.cardHeader}
                      />
                      <CardContent>
                        <div className={classes.cardPricing}>
                          <Typography
                            component="h2"
                            variant="h3"
                            color="textPrimary">
                            ${tier.price}
                          </Typography>
                          <Typography variant="h6" color="textSecondary">
                            /mo
                          </Typography>
                        </div>
                        <ul>
                          <React.Fragment>
                            <Typography
                              component="li"
                              variant="subtitle1"
                              align="center"
                              key={tier.id}>
                              {''} ID: {tier.id}
                            </Typography>
                            <Typography
                              component="li"
                              variant="subtitle1"
                              align="center"
                              key={tier.id}>
                              {''} Details: {tier.details}
                            </Typography>
                            <Typography
                              component="li"
                              variant="subtitle1"
                              align="center"
                              key={tier.id}>
                              {''}Status: {tier.status}
                            </Typography>
                            <Typography
                              component="li"
                              variant="subtitle1"
                              align="center"
                              key={tier.id}>
                              {''}Subscription: {tier.subscription_type}
                            </Typography>
                            <Typography
                              component="li"
                              variant="subtitle1"
                              align="center"
                              key={tier.id}>
                              {''}Currency_Type: {tier.subscription_currency}
                            </Typography>
                          </React.Fragment>
                        </ul>
                      </CardContent>
                      <CardActions>
                        <Button
                          fullWidth
                          variant={
                            tier.subscription_name === 'premium extension'
                              ? 'contained'
                              : null
                          }
                          color="primary"
                          onMouseEnter={e => (e.target.style.color = 'primary')}
                          onMouseLeave={e => (e.target.style.color = '')}
                          type="submit">
                          GET STARTED
                        </Button>
                      </CardActions> */}
                      {tier.subscription_name === 'trial' ? (
                        <CardHeader
                          style={{
                            textTransform: 'capitalize',
                            color: 'white'
                          }}
                          title={tier.subscription_name}
                          subheader={
                            tier.subscription_name === 'trial'
                              ? 'Most Popular'
                              : ''
                          }
                          titleTypographyProps={{ align: 'center' }}
                          subheaderTypographyProps={{ align: 'center' }}
                          action={
                            tier.subscription_name === 'trial' ? (
                              <StarIcon style={{ color: 'gold' }} />
                            ) : null
                          }
                          className={classes.cardHeader}
                        />
                      ) : null}
                      {tier.subscription_name === 'trial' ? (
                        <CardContent>
                          <div className={classes.cardPricing}>
                            <Typography
                              component="h2"
                              variant="h3"
                              color="textPrimary">
                              ₹{tier.price}
                            </Typography>
                            <Typography variant="h6" color="textSecondary">
                              /mo
                            </Typography>
                          </div>
                          <ul>
                            <React.Fragment>
                              <Typography
                                component="li"
                                variant="subtitle1"
                                align="center"
                                key={tier.id}>
                                {''} ID: {tier.id}
                              </Typography>
                              <Typography
                                component="li"
                                variant="subtitle1"
                                align="center"
                                key={tier.id}>
                                {''} Details: {tier.details}
                              </Typography>
                              <Typography
                                component="li"
                                variant="subtitle1"
                                align="center"
                                key={tier.id}>
                                {''}Status: {tier.status}
                              </Typography>
                              <Typography
                                component="li"
                                variant="subtitle1"
                                align="center"
                                key={tier.id}>
                                {''}Subscription: {tier.subscription_type}
                              </Typography>
                              <Typography
                                component="li"
                                variant="subtitle1"
                                align="center"
                                key={tier.id}>
                                {''}Currency_Type: {tier.subscription_currency}
                              </Typography>
                            </React.Fragment>
                          </ul>
                        </CardContent>
                      ) : null}
                      {tier.subscription_name === 'trial' ? (
                        <CardActions>
                          <Button
                            fullWidth
                            variant={
                              tier.subscription_name === 'trial'
                                ? 'contained'
                                : null
                            }
                            color="primary"
                            onMouseEnter={e =>
                              (e.target.style.color = 'primary')
                            }
                            onMouseLeave={e => (e.target.style.color = '')}
                            type="submit">
                            GET STARTED
                          </Button>
                        </CardActions>
                      ) : null}
                    </Card>
                  </form>
                </Grid>
              ))}
            </Grid>
          </Container>
          {/* Footer */}
          {/* End footer */}
        </React.Fragment>
        {/* </Box> */}
      </Paper>
    </div>
  )
})

export default Plandetails
