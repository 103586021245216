import React, { useState, useEffect, useReducer } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import localforage from 'localforage'
import Loading from 'react-loading'
import { AUTH_STATE, LOCAL_STORAGE_AUTHN_KEY_NAME } from '../../Constants'
import api from '../../api'

const initialState = {
  authState: AUTH_STATE.NOT_STARTED
}

const ProtectedRoute = ({ unAuthorizedComponent, children, ...rest }) => {
  const location = useLocation()

  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    dispatch({ type: 'setAuthState', payload: AUTH_STATE.AUTHENTICATING })

    localforage
      .getItem(LOCAL_STORAGE_AUTHN_KEY_NAME)
      .then(data => {
        // console.log(data.user.id_token)
        if (data?.user?.id_token) {
          api.setAuthHeaders({ Authorization: data.user.id_token })
          dispatch({ type: 'setAuthState', payload: AUTH_STATE.SUCCESS })
        } else {
          dispatch({ type: 'setAuthState', payload: AUTH_STATE.FAILED })
        }
      })
      .catch(err => {
        localStorage.clear()
        localforage.clear()
        dispatch({ type: 'setAuthState', payload: AUTH_STATE.FAILED })
        // console.log('Error retreiving item from local storage', err)
      })
  }, [])

  if (state.authState === AUTH_STATE.AUTHENTICATING) {
    return (
      <div style={{ margin: '25% 45%' }}>
        Authenticating...
        <Loading type="cylon" textAlign="center" color="grey" height={40} />
      </div>
    )
  }

  if (state.authState === AUTH_STATE.FAILED) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: location, unauthorized: true }
        }}
      />
    )
  }

  return children
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'setAuthState':
      return {
        ...state,
        authState: action.payload
      }
    default:
      throw new Error()
  }
}

export default ProtectedRoute
