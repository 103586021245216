import { createContextStore } from 'easy-peasy'
import localforage from 'localforage'

import { LOCAL_STORAGE_NAME } from '../Constants'
import Hospital from './Hospital'

localforage.config({
  name: LOCAL_STORAGE_NAME
})

const AdminStore = createContextStore(Hospital)

export { AdminStore }
