import React, { useEffect, useState, useRef } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Fade from '@material-ui/core/Fade'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { Avatar, CircularProgress, FormControl } from '@material-ui/core'
import localforage from 'localforage'
import PhoneInput, {
  isValidPhoneNumber,
  isPossiblePhoneNumber
} from 'react-phone-number-input'
import { useDropzone } from 'react-dropzone'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import swal from 'sweetalert'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Swal from 'sweetalert2'
import FormItemLabel from 'antd/lib/form/FormItemLabel'
import copy from 'copy-to-clipboard'
import 'react-phone-number-input/style.css'

// local imports
import {
  VALIDATE_PHONE,
  NETWORK_STATUS,
  VALIDATE_WHITESPACES,
  SINGLE_WHITESPACE
} from '../../Constants'
import SnackbarAlert from '../../components/commonComponents/SnackbarAlert'
import Loader from '../../components/commonComponents/Loader'
import { AdminStore } from '../../stores'

import profile from '../../images/profile-img.png'

function rand() {
  return Math.round(Math.random() * 20) - 10
}

function getModalStyle() {
  const top = 50 + rand()
  const left = 50 + rand()
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  }
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

// const singleSpaceRegex = /^[a-zA-Z0-9]+\s?[a-zA-Z0-9]+$/

const singleSpaceRegex = /^(\w+\s)*\w+$/

const Personaldetails = React.forwardRef((props, ref) => {
  const [Image, previewImage] = useState('')
  const classes = useStyles()
  const theme = useTheme()
  const [modalStyle] = React.useState(getModalStyle)
  const [docfile, setDocFile] = useState('')
  const [name, setName] = useState(undefined)
  const [inputValue, setInputValue] = useState('')
  const [docdetails, setDocDetails] = React.useState(undefined)
  const [docName, setDocName] = useState('')
  const [Country, setCountry] = React.useState({})
  const [State, setState] = React.useState({})
  const [city, setCity] = React.useState({})
  const [multiButton, setmultiButton] = useState(false)
  const [alertType, setAlertType] = useState('')
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [isSnackBarOpen, setOpenSnackBar] = React.useState(false)
  const [filename, setFileName] = useState('')
  const [clinic, setClinic] = useState({})

  const {
    doctorDetails,
    fetchCountryList,
    fetchStateList,
    fetchCityList,
    clearState,
    clearDocDetails
  } = AdminStore.useStoreActions(actions => ({
    ...actions.profile
  }))

  const { getMyClinicsList } = AdminStore.useStoreActions(actions => ({
    ...actions.doctorList
  }))

  const { clinicsList } = AdminStore.useStoreState(state => ({
    ...state.doctorList
  }))

  const {
    docdetailAddedStatus,
    networkStatus,
    countryList,
    stateList,
    cityList,
    fetchingCountry,
    fetchingCity,
    fetchingState
  } = AdminStore.useStoreState(state => ({
    ...state.profile
  }))

  React.useEffect(() => {
    fetchCountryList()
    getMyClinicsList()
    clearDocDetails()
  }, [])

  useEffect(() => {
    if (docdetailAddedStatus === undefined || docdetailAddedStatus === '') {
      //
    } else if (
      docdetailAddedStatus.statusCode === 400 ||
      docdetailAddedStatus.statusCode === 515
    ) {
      setOpenSnackBar(true)
      setSnackBarMessage(docdetailAddedStatus.message.replace(/['"]+/g, ''))
      // setSnackBarMessage('Patient profile not updated successfully')
      setmultiButton(false)
      setAlertType('error')
    } else if (
      docdetailAddedStatus.statusCode === 200 ||
      docdetailAddedStatus.statusCode === 201
    ) {
      setOpenSnackBar(true)
      setSnackBarMessage('Doctor Account created successfully')
      // setSnackBarMessage('Patient profile not updated successfully')
      setmultiButton(false)
      setAlertType('success')

      if (docdetailAddedStatus.password) {
        copy(docdetailAddedStatus.password)
        setOpenSnackBar(true)
        setSnackBarMessage('Your password is in stored in your clipboard')
        // setSnackBarMessage('Patient profile not updated successfully')
        setmultiButton(false)
        setAlertType('info')
      }
      return Swal.fire(
        'Success!',
        'Doctor added succesfully',
        'success'
      ).then(() => window.location.reload())
    }
  }, [docdetailAddedStatus])

  const submit = async values => {
    const file = values.file
    if (file && docfile) {
      if (
        file.name.split('.').pop().search('pdf') === 0 ||
        file.name.split('.').pop().search('docx') === 0
      ) {
        setOpenSnackBar(true)
        setSnackBarMessage(
          'Pdf and Docx file are not supported for profile picture!'
        )
        setmultiButton(false)
        setAlertType('info')
      } else if (
        file.name.split('.').pop().search('pdf') !== 0 ||
        file.name.split('.').pop().search('docx') !== 0
      ) {
        let base64 = await convertBase64(file)
        let base64doc = await convertBase64(docfile)
        let format = base64.split(',')
        let docformat = base64doc.split(',')
        await doctorDetails({
          ...values,
          format,
          docformat,
          docName,
          fileName: filename
        })
      }
    } else {
      // doctorDetails({
      //   ...values
      // })
      setOpenSnackBar(true)
      setSnackBarMessage('Profile Picture and Document are mandatory')
      setmultiButton(false)
      setAlertType('info')
    }
    if (values.mobile_no && isValidPhoneNumber(values.mobile_no)) {
      // console.log('true')
    } else {
      swal(
        'Wrong!',
        'The mobile number entered is not a valid number!',
        'error'
      )
    }
  }

  const convertBase64 = file => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result)
      }

      fileReader.onerror = error => {
        reject(error)
      }
    })
  }

  const formik = useFormik({
    initialValues: {
      name: '',
      username: '',
      mobile_no: '',
      email: '',
      user_short_code: '',
      experience: '',
      qualification: '',
      specialization: '',
      registration_no: '',
      associated_with: '',
      bio: '',
      pincode: '',
      file: null
    },

    //Control whether Formik should reset the form if initialValues changes (enableReinitialize)
    enableReinitialize: true,
    validationSchema,
    onSubmit: submit
  })

  const hiddenFileInput = useRef()

  const handleClick = event => {
    hiddenFileInput.current.value = null
    hiddenFileInput.current.click()
  }

  const handleChange = event => {
    if (event.target.files[0] == undefined) {
    } else {
      if (event.target.files[0].size / 1000000 <= 4) {
        formik.setFieldValue('file', event.currentTarget.files[0])
        setFileName(event.currentTarget.files[0].name)
        event.preventDefault()

        // setDocName(event?.currentTarget?.files[0]?.name)
        let reader = new FileReader()
        let fileimage = event.target.files[0]

        reader.onloadend = () => {
          previewImage(reader.result)
        }
        reader.readAsDataURL(fileimage)
      } else {
        setOpenSnackBar(true)
        setSnackBarMessage(`Oops, File is larger than 4000000 bytes`)
        setmultiButton(false)
        setAlertType('info')
        // formik.setFieldValue('file', null)
        return
      }
    }
  }
  const customStyles = {
    height: '100%',
    position: 'absolute',
    top: '0',
    right: '0',
    zIndex: '99',
    cursor: 'pointer',
    display: 'none',
    /*This makes the button huge. If you want a bigger button, increase the font size*/
    fontSize: '50px',
    /*Opacity settings for all browsers*/
    opacity: '0',
    mozOpacity: '0',
    msFilter: 'progid:DXImageTransform.Microsoft.Alpha(opacity=0)'
  }

  const maxSize = 1048576

  const {
    acceptedFiles,
    fileRejections,
    isDragActive,
    isDragReject,
    getRootProps,
    getInputProps
  } = useDropzone({
    accept: '.jpeg, .png , .pdf , .jpg , .docx',
    minSize: 0,
    maxSize: 4194304
  })

  React.useEffect(() => {
    if (acceptedFiles) {
      acceptedFiles.map(file => {
        setDocName(file?.name)
        setDocFile(file)
      })
    }
  }, [acceptedFiles])

  React.useEffect(() => {
    if (fileRejections) {
      fileRejections.map(file => {
        setOpenSnackBar(true)
        setSnackBarMessage('Document size must be less than 4MB')
        setmultiButton(false)
        setAlertType('error')
      })
    }
  }, [fileRejections])

  //on mount country, state and city init values to empty
  React.useEffect(() => {
    formik.setFieldValue('country_id', '')
    formik.setFieldValue('state_id', '')
    formik.setFieldValue('city_id', '')
    formik.setFieldValue('clinic_list', '')
  }, [])

  const countryChange = (event, value) => {
    if (value == null) {
      setState('')
      setCountry('')
      setCity('')
      clearState()
      formik.setFieldValue('country_id', '')
    } else {
      setCountry(value)
      fetchStateList(value?.id)
      // setCountry(value)
      // setCountrySelect(true);
      formik.setFieldValue('country_id', value?.id)
      formik.setFieldValue('state_id', '')
      formik.setFieldValue('city_id', '')
    }
  }

  const stateChange = (event, value) => {
    // setStateSelect(true);
    if (value == null) {
      setCity('')
      setState('')
      fetchCityList('')
      clearState()
      formik.setFieldValue('state_id', '')
      formik.setFieldValue('city_id', '')
    } else {
      setState(value)
      fetchCityList(value?.id)
      formik.setFieldValue('state_id', value?.id)
      formik.setFieldValue('city_id', '')
      setCity('')
    }
  }

  const cityChange = (event, value) => {
    // setCitySelect(true);
    setCity(value)
    formik.setFieldValue('city_id', value?.id)
  }

  const clinicChange = (event, value) => {
    setClinic(value)
    formik.setFieldValue('clinic_list', value?.id)
  }

  const phoneChange = value => {
    setInputValue(value)
    formik.setFieldValue('mobile_no', value ? value : '')
  }

  const showSnackBar = () => {
    setOpenSnackBar(true)
  }

  const hideSnackBar = () => {
    setOpenSnackBar(false)
  }

  return (
    <div style={{ padding: '40px' }}>
      {networkStatus === NETWORK_STATUS.FETCHING && <Loader open={true} />}
      {/* <Box border={1} style={{ boxShadow: '5px 10px #3f4b79' }}> */}
      <Paper elevation={3} className={classes.paper}>
        {/* {docdetailAddedStatus ===
        undefined ? null : docdetailAddedStatus.statusCode === 400 ||
          docdetailAddedStatus.statusCode === 515 ? (
          <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'bottom' }}>
            <Alert onClose={handleClose} severity="error">
              {docdetailAddedStatus.message.replace(/['"]+/g, '')}
            </Alert>
          </Snackbar>
        ) : null} */}

        <SnackbarAlert //note for satadru:snackbar message goes right here
          isOpen={isSnackBarOpen}
          message={snackBarMessage}
          alertType={alertType}
          multibutton={multiButton}
          primaryClick={hideSnackBar}
        />

        <form
          onSubmit={formik.handleSubmit}
          noValidate={true}
          className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              {/*  */}
              <Grid container spacing={3}>
                <Grid item xs={5}>
                  <TextField
                    id="name"
                    style={{ width: '100%' }}
                    type="text"
                    placeholder="Name"
                    onChange={formik.handleChange}
                    value={formik && formik.values && formik.values.name}
                    onBlur={formik.handleBlur}
                    name="name"
                    label="Name *"
                    helperText={
                      <div className={classes.errMsg}>
                        {formik.touched.name && formik.errors.name
                          ? formik.errors.name
                          : null}
                      </div>
                    }
                  />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={5}>
                  <TextField
                    id="username"
                    style={{ width: '100%' }}
                    type="text"
                    placeholder="User name"
                    value={formik && formik.values && formik.values.username}
                    name="username"
                    label="Username *"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    // disabled={true}
                    helperText={
                      <div className={classes.errMsg}>
                        {formik.touched.username && formik.errors.username
                          ? formik.errors.username
                          : null}
                      </div>
                    }
                  />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={5}>
                  <TextField
                    id="user_short_code"
                    style={{ width: '100%' }}
                    placeholder="user short code"
                    name="user_short_code"
                    label="User short code *"
                    value={
                      formik && formik.values && formik.values.user_short_code
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={
                      <div className={classes.errMsg}>
                        {formik.touched.user_short_code &&
                        formik.errors.user_short_code
                          ? formik.errors.user_short_code
                          : null}
                      </div>
                    }
                  />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={5}>
                  <TextField
                    id="registration_no"
                    style={{ width: '100%' }}
                    type="text"
                    placeholder="Registration Number"
                    value={
                      formik && formik.values && formik.values.registration_no
                    }
                    name="registration_no"
                    label="Registration Number*"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    // disabled={true}
                    helperText={
                      <div className={classes.errMsg}>
                        {formik.touched.registration_no &&
                        formik.errors.registration_no
                          ? formik.errors.registration_no
                          : null}
                      </div>
                    }
                  />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={5}>
                  <PhoneInput
                    placeholder="Enter mobile number"
                    international
                    defaultCountry="IN"
                    id="mobile_no"
                    value={inputValue}
                    className={classes.inputChange}
                    name="mobile_no"
                    onChange={phoneChange}
                    // onChange={inputValue => console.log(inputValue)}
                    error={
                      inputValue
                        ? isValidPhoneNumber(inputValue)
                          ? undefined
                          : 'Invalid phone number'
                        : 'Phone number required'
                    }
                  />
                  {
                    <div className={classes.errMsg2}>
                      {formik.touched.mobile_no && formik.errors.mobile_no
                        ? formik.errors.mobile_no
                        : null}
                    </div>
                  }
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={5}>
                  <TextField
                    id="email"
                    style={{ width: '100%' }}
                    placeholder="jane@gmail.com"
                    name="email"
                    label="Email *"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik && formik.values && formik.values.email}
                    helperText={
                      <div className={classes.errMsg}>
                        {formik.touched.email && formik.errors.email
                          ? formik.errors.email
                          : null}
                      </div>
                    }
                  />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={5}>
                  <TextField
                    id="pincode"
                    style={{ width: '100%' }}
                    type="text"
                    placeholder="Pincode"
                    value={formik && formik.values && formik.values.pincode}
                    name="pincode"
                    label="Pincode *"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    // disabled={true}
                    helperText={
                      <div className={classes.errMsg}>
                        {formik.touched.pincode && formik.errors.pincode
                          ? formik.errors.pincode
                          : null}
                      </div>
                    }
                  />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={5}>
                  <TextField
                    id="qualification"
                    style={{ width: '100%' }}
                    type="text"
                    name="qualification"
                    label="Qualification *"
                    placeholder="Qualification"
                    value={
                      formik && formik.values && formik.values.qualification
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={
                      <div className={classes.errMsg}>
                        {formik.touched.qualification &&
                        formik.errors.qualification
                          ? formik.errors.qualification
                          : null}
                      </div>
                    }
                  />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={5}>
                  <TextField
                    id="experience"
                    style={{ width: '100%' }}
                    type="text"
                    placeholder="5"
                    label="Experience *"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik && formik.values && formik.values.experience}
                    name="experience"
                    helperText={
                      <div className={classes.errMsg}>
                        {formik.touched.experience && formik.errors.experience
                          ? formik.errors.experience
                          : null}
                      </div>
                    }
                  />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={5}>
                  <TextField
                    id="specialization"
                    style={{ width: '100%' }}
                    type="text"
                    name="specialization"
                    label="Specialization *"
                    value={
                      formik && formik.values && formik.values.specialization
                    }
                    placeholder="Specalization"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={
                      <div className={classes.errMsg}>
                        {formik.touched.specialization &&
                        formik.errors.specialization
                          ? formik.errors.specialization
                          : null}
                      </div>
                    }
                  />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={5}>
                  <TextField
                    id="bio"
                    style={{ width: '100%' }}
                    type="text"
                    label="Bio *"
                    value={formik && formik.values && formik.values.bio}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="bio"
                    placeholder="Bio"
                    helperText={
                      <div className={classes.errMsg}>
                        {formik.touched.bio && formik.errors.bio
                          ? formik.errors.bio
                          : null}
                      </div>
                    }
                  />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={5}>
                  <TextField
                    id="associated_with"
                    style={{ width: '100%' }}
                    type="text"
                    label="Associated With *"
                    value={
                      formik && formik.values && formik.values.associated_with
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="associated_with"
                    placeholder="Associated with"
                    helperText={
                      <div className={classes.errMsg}>
                        {formik.touched.associated_with &&
                        formik.errors.associated_with
                          ? formik.errors.associated_with
                          : null}
                      </div>
                    }
                  />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={5}>
                  <FormControl className={classes.formControl}>
                    <Autocomplete
                      id="size-small-outlined"
                      size="small"
                      style={{ width: '100%' }}
                      options={clinicsList}
                      name="clinic_list"
                      getOptionLabel={option => option.display_name}
                      onChange={clinicChange}
                      value={clinic}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Clinic *"
                          loading={fetchingCountry}
                          style={{ width: '100%' }}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {fetchingCountry ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            )
                          }}
                        />
                      )}
                    />
                    <div className={classes.errMsg2}>
                      {formik.touched.clinic_list && formik.errors.clinic_list
                        ? formik.errors.clinic_list
                        : null}
                    </div>
                  </FormControl>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={5}>
                  <FormControl className={classes.formControl}>
                    <Autocomplete
                      id="size-small-outlined"
                      size="small"
                      style={{ width: '100%' }}
                      options={countryList}
                      name="country_id"
                      getOptionLabel={option => option.name}
                      onChange={countryChange}
                      value={Country}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Country *"
                          loading={fetchingCountry}
                          style={{ width: '100%' }}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {fetchingCountry ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            )
                          }}
                        />
                      )}
                    />
                    <div className={classes.errMsg2}>
                      {formik.touched.country_id && formik.errors.country_id
                        ? formik.errors.country_id
                        : null}
                    </div>
                  </FormControl>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={5}>
                  <FormControl className={classes.formControl}>
                    <Autocomplete
                      id="size-small-outlined"
                      size="small"
                      name="state_id"
                      style={{ width: '100%' }}
                      options={stateList}
                      getOptionLabel={option => option.name}
                      onChange={stateChange}
                      value={State}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="State *"
                          loading={fetchingState}
                          style={{ width: '100%' }}
                          // error={
                          //   (formik.errors.state_id, formik.touched.state_id)
                          // }
                          // helperText={
                          //   (formik.errors.state_id,
                          //   formik.touched.state_id
                          //     ? formik.errors.state_id
                          //     : null)
                          // }
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {fetchingState ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            )
                          }}
                        />
                      )}
                    />

                    <div className={classes.errMsg2}>
                      {formik.touched.state_id && formik.errors.state_id
                        ? formik.errors.state_id
                        : null}
                    </div>
                    {/* <div className={classes.errMsg2}>
                    {formik?.values?.state_id === undefined ? (
                      <p>
                        <b>{'State is required'}</b>
                      </p>
                    ) : null}
                  </div> */}
                  </FormControl>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={12} sm={5}>
                  <FormControl className={classes.formControl}>
                    <Autocomplete
                      id="asynchronous-demo"
                      style={{ width: '100%' }}
                      options={cityList}
                      name="city_id"
                      getOptionLabel={option => option.name}
                      getOptionSelected={(option, value) =>
                        option.name === value.name
                      }
                      onChange={cityChange}
                      value={city}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="City *"
                          variant="standard"
                          loading={fetchingCity}
                          margin="normal"
                          style={{ width: '100%' }}
                          // error={(formik.errors.city_id, formik.touched.city_id)}
                          // helperText={
                          //   (formik.errors.city_id,
                          //   formik.touched.city_id ? formik.errors.city_id : null)
                          // }
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {fetchingCity ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            )
                          }}
                        />
                      )}
                    />

                    <div className={classes.errMsg2}>
                      {formik.touched.city_id && formik.errors.city_id
                        ? formik.errors.city_id
                        : null}
                    </div>
                    {/* <div className={classes.errMsg2}>
                    {formik?.values?.city_id === undefined ? (
                      <p>
                        <b>{'City is required'}</b>
                      </p>
                    ) : null}
                  </div> */}
                  </FormControl>
                </Grid>
                {/* </Grid> */}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box
                style={{
                  width: '140px',
                  height: '140px',
                  background: 'white',
                  borderRadius: '50%',
                  boxShadow: `0 1px 1px rgba(0,0,0,0.12), 
                              0 2px 2px rgba(0,0,0,0.12), 
                              0 4px 4px rgba(0,0,0,0.12), 
                              0 8px 8px rgba(0,0,0,0.12),
                              0 16px 16px rgba(0,0,0,0.12)`,
                  margin: '25px auto',
                  textAlign: 'right',
                  padding: '10px',
                  display: 'flex'
                }}>
                {Image ? (
                  <Avatar className={classes.AvatarCol} src={Image} />
                ) : (
                  <Avatar className={classes.AvatarCol} src={profile} />
                )}
              </Box>
              <label
                style={{
                  textAlign: 'center',
                  width: '150px',
                  margin: '20px auto',
                  display: 'block',
                  fontWeight: 'bold'
                }}>
                <div>
                  <Button
                    onClick={handleClick}
                    style={{
                      width: '140px',
                      borderRadius: '50px',
                      background: '#111d4e',
                      border: 'none',
                      height: '40px',
                      textAlign: 'center',
                      color: '#fff',
                      // lineHeight: '40px',
                      cursor: 'pointer'
                    }}>
                    Select
                  </Button>
                  <input
                    id="file"
                    name="file"
                    type="file"
                    accept="image/jpeg, image/png, image/jpg"
                    ref={hiddenFileInput}
                    style={customStyles}
                    onChange={handleChange}
                    // value={formik?.values?.file}
                    className="form-control"
                  />
                </div>
              </label>
              <Box
                style={{
                  width: '95%',
                  padding: '60px',
                  background: '#111d4e',
                  fontSize: '16px',
                  color: '#fff',
                  borderRadius: '20px'
                }}>
                <section>
                  <div
                    {...getRootProps({ className: 'dropzone' })}
                    style={{
                      cursor: 'pointer',
                      border: '1px rgba(255,255,255, 0.3) solid',
                      borderRadius: '5px',
                      padding: '10px'
                    }}>
                    <input {...getInputProps()} />
                    {!isDragActive &&
                      'Click here or drop a document to upload!'}
                    {isDragActive && !isDragReject && "Drop it like it's hot!"}
                    {isDragReject && 'Document type not accepted, sorry!'}
                  </div>
                  <aside>
                    <h4>Accepted files</h4>
                    <ul>{docName}</ul>
                  </aside>
                </section>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              style={{
                display: 'grid',
                gridTemplateColumns: 'max-content min-content',
                gridTemplateRows: 'min-content',
                gridGap: '2rem',
                gap: '2rem',
                alignItems: 'center'
              }}>
              <Button
                type="submit"
                style={{
                  width: '140px',
                  borderRadius: '50px',
                  background: '#111d4e',
                  border: 'none',
                  height: '40px',
                  textAlign: 'center',
                  color: '#fff',
                  lineHeight: '40px',
                  cursor: 'pointer'
                }}>
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
        {/* </Box> */}
      </Paper>
    </div>
  )
})

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .required('Name is required')
    .matches(/^([^0-9]*)$/, 'Digits are not allowed')
    .max(30, 'Max 30 characters allowed')
    .matches(VALIDATE_WHITESPACES, 'Remove extra spaces'),

  username: yup
    .string()
    .required('Username is required')
    // .matches(/^([^0-9]*)$/, 'Digits are not allowed')
    .max(50, 'Max 50 characters allowed')
    .matches(SINGLE_WHITESPACE, 'Remove extra spaces'),

  email: yup
    .string()
    .email('Invalid email address')
    .required('Email is required')
    // .matches(/^([^0-9]*)$/, 'Digits are not allowed')
    .max(50, 'Max 50 characters allowed')
    .matches(VALIDATE_WHITESPACES, 'Remove extra spaces'),
  // .matches(singleSpaceRegex, 'Remove extra spaces'),
  mobile_no: yup
    .string()
    .matches(VALIDATE_PHONE, ' Please enter a valid mobile number with +91')
    .max(13, 'You may enter up to 13 digits')
    .typeError('Mobile number cannnot contain characters')
    .required('Mobile number is required'),
  bio: yup
    .string()
    .required('Bio is required')
    .max(200, 'Maximum character limit exceeded')
    // .matches(/^([^0-9]*)$/, 'Digits are not allowed')
    .matches(VALIDATE_WHITESPACES, 'Remove extra spaces'),

  user_short_code: yup
    .string()
    .required('User short code is required')
    .max(30, 'Max 30 characters allowed')
    .matches(VALIDATE_WHITESPACES, 'Remove extra spaces'),

  qualification: yup
    .string()
    .required('Qualification is required')
    .max(50, 'Max 50 characters allowed')
    .matches(/^([^0-9]*)$/, 'Digits are not allowed')
    .matches(VALIDATE_WHITESPACES, 'Remove extra spaces'),

  specialization: yup
    .string()
    .required('Specalization is required')
    .max(50, 'Max 50 characters allowed')
    .matches(/^([^0-9]*)$/, 'Digits are not allowed')
    .matches(VALIDATE_WHITESPACES, 'Remove extra spaces'),

  experience: yup
    .string()
    .required('Experience is required')
    .matches(/^[0-9]*$/, 'Only numbers are allowed (spaces not allowed)')
    .max(2, 'Experience should be within 0-99 years'),

  registration_no: yup
    .string()
    .required('Registration number is required')
    .matches(VALIDATE_WHITESPACES, 'Remove extra spaces'),

  pincode: yup
    .string()
    .trim()
    .required('Pincode is required')
    .matches(/^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/, 'Incorrect pincode format')
    .matches(VALIDATE_WHITESPACES, 'Remove extra spaces'),

  country_id: yup.string().required('Country is required'),
  state_id: yup.string().required('State is required'),
  city_id: yup.string().required('City is required'),
  clinic_list: yup.string().required('Clinic name is required'),
  associated_with: yup
    .string()
    .required('Associated with is required')
    .matches(VALIDATE_WHITESPACES, 'Remove extra spaces')
    .max(100, 'Max 100 characters allowed')
})

const useStyles = makeStyles(theme => ({
  paper: {
    padding: '10px',
    '@media (max-width:767px)': {
      marginTop: '40px'
    }
  },
  drawer: {
    width: '20%',
    flexBasis: '20%',
    maxWidth: '260px'
  },
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch'
    }
  },
  aside: {
    // padding: '25px 0 !important'
  },
  appointmentPage: {
    background: '#F7F5F6',
    flexWrap: 'nowrap',
    justifyContent: 'center'
  },
  errMsg: {
    color: 'red'
  },
  errMsg2: {
    color: 'red',
    fontSize: '12px',
    paddingLeft: '5px',
    paddingTop: '4px'
  },
  Inputtext: {
    color: '#fff !important',
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#fff !important'
    },
    '& label': {
      color: '#ccc'
    },
    '& .MuiInput-input': {
      color: '#fff !important'
    }
  },
  // paper: {
  //   position: 'absolute',
  //   width: 400,
  //   backgroundColor: theme.palette.background.paper,
  //   border: '2px solid #000',
  //   boxShadow: theme.shadows[5],
  //   padding: theme.spacing(2, 4, 3),
  //   height: 300
  // },
  avatar: {
    width: '140px',
    height: '140px',
    background: 'white',
    borderRadius: '50%',
    boxShadow: `0 1px 1px rgba(0,0,0,0.12), 
        0 2px 2px rgba(0,0,0,0.12), 
        0 4px 4px rgba(0,0,0,0.12), 
        0 8px 8px rgba(0,0,0,0.12),
        0 16px 16px rgba(0,0,0,0.12)`,
    margin: '0px auto',
    textAlign: 'center',
    padding: '10px',
    display: 'flex'
  },
  image: {
    width: '100%',
    height: '100%'
  },
  AvatarCol: {
    width: '100%',
    height: '100%'
  },
  AppBar: {
    '& .MuiBox-root': {
      padding: '0'
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff'
    },
    checkboxes: {
      marginTop: '15px'
    }
  },
  inputChange: {
    width: '100%',
    paddingLeft: '8px',
    paddingTop: '23px',
    '& .PhoneInputInput': {
      fontSize: '16px',
      border: 'none',
      borderBottom: '1px solid #9E9E9E',
      margin: '10px'
    }
  },
  formControl: {
    width: '100%',
    margin: '1px 0px 8px'
  }
}))

export default Personaldetails
