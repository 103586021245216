import React from 'react'
import {
  makeStyles,
  MenuItem,
  Select,
  Box,
  Typography
} from '@material-ui/core'
import { useForm, Controller } from 'react-hook-form'
import { AdminStore } from '../../stores'
import { USER_TYPE, NETWORK_STATUS } from '../../Constants'
import PrimaryButton from '../../components/commonComponents/PrimaryButton'
import PrimaryInputField from '../../components/commonComponents/PrimaryInputField'

function AdminLoginForm({
  onSuccess = () => {},
  forgotPasswordClicked = () => {}
}) {
  const classes = useStyles()

  const { adminLogin } = AdminStore.useStoreActions(actions => ({
    ...actions.auth
  }))

  const { networkStatus } = AdminStore.useStoreState(state => ({
    ...state.auth
  }))

  const { control, handleSubmit, errors } = useForm()

  const [loginLoader, setLoginLoader] = React.useState(false)

  const usernameRef = React.useRef()
  const passwordRef = React.useRef()

  React.useEffect(() => {
    usernameRef.current.focus()
  }, [])

  const keyPressed = e => {
    if (e.keyCode === 13) {
      passwordRef.current.focus()
    }
  }

  const onSubmit = formData => {
    adminLogin(formData).then(data => {
      if (data.statusCode === 200) {
        localStorage.setItem('user_id', data.user.user_id)
      }
      if (onSuccess) {
        onSuccess({ request: formData, response: data })
      }
    })
  }

  return (
    <div
      style={{
        backgroundColor: '#fff',
        padding: '5%',
        borderRadius: '20px',
        boxShadow: '0px 0px 5px 2px #0000000d'
      }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* <Box display="flex" alignItems="center" mb={4}>
          <Typography className={classes.labelText}>I am a </Typography>
          <Box width={10} />
          <Controller
            disabled
            as={
              <Select>
                {Object.keys(USER_TYPE)
                  .map(key => USER_TYPE[key])
                  .map(item => (
                    <MenuItem id={item.id} key={item.id} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
              </Select>
            }
            name="userType"
            rules={{ required: 'User Type is required' }}
            control={control}
            defaultValue={USER_TYPE.ADMIN.value}
          />
          {errors.userType ? (
            <Typography color={'error'}>{errors.userType?.message}</Typography>
          ) : null}
        </Box> */}

        {/* <Box display="flex" alignItems="center" mb={4}>
          <Typography variant="h4">Welcome Back!</Typography>
        </Box> */}
        <Typography className={classes.labelText}>
          <strong>Admin Login</strong>
        </Typography>
        <br />
        <br />
        <Box mb={4}>
          <Controller
            as={PrimaryInputField}
            name="email"
            control={control}
            defaultValue=""
            placeholder="Username"
            rules={{ required: 'Username is required' }}
            inputRef={usernameRef}
            onKeyDown={e => keyPressed(e)}
          />
          {errors.email ? (
            <Typography color={'error'}>{errors.email?.message}</Typography>
          ) : null}
        </Box>
        <Box mb={4}>
          <Controller
            as={PrimaryInputField}
            name="password"
            type="password"
            control={control}
            defaultValue=""
            placeholder="Password"
            rules={{ required: 'Password is required' }}
            inputRef={passwordRef}
          />
          {errors.password ? (
            <Typography color={'error'}>{errors.password?.message}</Typography>
          ) : null}
        </Box>
        <PrimaryButton
          rounded
          type="submit"
          style={{ backgroundColor: '#111d4e' }}
          // disabled={loginLoader}
          // stopLoading={loginLoader}
          isLoading={networkStatus === NETWORK_STATUS.FETCHING}>
          Login
        </PrimaryButton>
      </form>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(0),
    maxWidth: '100%',
    width: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(0)
  },
  selectElement: {
    width: '90px',
    borderWidth: 0,
    fontSize: '14px',
    '&>div': {
      '&::before': {
        borderWidth: '0px',
        '&::after': {
          borderWidth: '0px'
        }
      }
    }
  },
  labelText: {
    fontSize: '14px',
    float: 'left',
    marginRight: '10px',
    lineHeight: '29px',
    color: '#111d4e'
  },
  selectGroup: {
    display: 'flex',
    marginBottom: '30px'
  },
  inputField: {
    marginBottom: 40,
    display: 'flex',
    flexFlow: 'column'
  },
  forgotPassword: {
    fontSize: 12,
    cursor: 'pointer',
    width: 'fit-content',
    alignSelf: 'flex-end',
    marginTop: 5,
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  submitButton: {
    width: 150
  }
}))

export default AdminLoginForm
