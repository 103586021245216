import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Button, TextField, Grid, makeStyles } from '@material-ui/core'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { AdminStore } from '../../stores'
import {
  VALIDATE_WHITESPACES,
} from '../../Constants'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  modalView: {
    width: '32rem !important',
    height: '30rem'
  },
  ModalHead: {
    backgroundColor: '#111d4e',
    color: '#fff'
  },
  modalSecondaryButton: {
    backgroundColor: '#e4e4e4',
    padding: '5px 20px',
    borderRadius: '40px',
    color: '#111d4e',
    '&:hover': {
      backgroundColor: '#e4e4e4',
      color: '#111d4e'
    }
  },
  modalPrimaryButton: {
    backgroundColor: '#111d4e',
    padding: '5px 20px',
    borderRadius: '40px',
    color: '#e4e4e4',
    '&:hover': {
      backgroundColor: '#111d4e',
      color: '#e4e4e4'
    }
  },
  errMsg: {
    color: 'red'
  },
  Content:{
    overflowY :"hidden",
    padding:"20px 24px",
    '& .MuiDialogContent-root':{
      overflowY :"hidden",
      padding:"20px 24px"
    }
  }
  
}))

const ConfirmApprove = ({ open, close, request }) => {
  const classes = useStyles()
  const { approveTransaction } = AdminStore.useStoreActions(actions => ({
    ...actions.doctorList
  }))


  const submit = () => {
    approveTransaction({
      branch_name: formik.values.branch_name,
      account_no: formik.values.account_no,
      transaction: formik.values.transaction,
      ifsc: formik.values.ifsc,
      request_id: request
    })
    close()
  }
  const formik = useFormik({
    initialValues: {
      branch_name: '',
      account_no: '',
      transaction: '',
      ifsc: ''
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: submit
  })

  return (
    <div>
      <Dialog
        open={open}
        onClose={close}
        classes={{ paper: classes.modalView }}>
        <DialogTitle className={classes.ModalHead}>Details</DialogTitle>
        <DialogContent className ={classes.Content}>
          <div>
            <form onSubmit={formik.handleSubmit}>
              <Grid>
                <Grid>
                  <TextField
                    id="branch_name"
                    style={{ width: '100%' }}
                    type="text"
                    placeholder="Branch Name"
                    label="Branch Name *"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik && formik.values && formik.values.branch_name}
                    name="branch_name"
                    helperText={
                      <div className={classes.errMsg}>
                        {formik.touched.branch_name && formik.errors.branch_name
                          ? formik.errors.branch_name
                          : null}
                      </div>
                    }
                  />
                </Grid>
                <br />
                <Grid>
                  <TextField
                    id="account_no"
                    style={{ width: '100%' }}
                    type="text"
                    placeholder="Account Number"
                    label="Account Number *"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik && formik.values && formik.values.account_no}
                    name="account_no"
                    helperText={
                      <div className={classes.errMsg}>
                        {formik.touched.account_no && formik.errors.account_no
                          ? formik.errors.account_no
                          : null}
                      </div>
                    }
                  />
                </Grid>
                <br />
                <Grid>
                  <TextField
                    id="transaction"
                    style={{ width: '100%' }}
                    type="text"
                    placeholder="Transaction ID"
                    label="Transaction ID *"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik && formik.values && formik.values.transaction}
                    name="transaction"
                    helperText={
                      <div className={classes.errMsg}>
                        {formik.touched.transaction && formik.errors.transaction
                          ? formik.errors.transaction
                          : null}
                      </div>
                    }
                  />
                </Grid>
                <br />
                <Grid>
                  <TextField
                    id="ifsc"
                    style={{ width: '100%' }}
                    type="text"
                    placeholder="IFSC Code"
                    label="IFSC Code *"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik && formik.values && formik.values.ifsc}
                    name="ifsc"
                    helperText={
                      <div className={classes.errMsg}>
                        {formik.touched.ifsc && formik.errors.ifsc
                          ? formik.errors.ifsc
                          : null}
                      </div>
                    }
                  />
                </Grid>
              </Grid>
              <br />
              <DialogActions style={{ padding: '30px 0px' }}>
                <Button type={'submit'} className={classes.modalPrimaryButton}>
                  Confirm Approve
                </Button>
                <Button
                  onClick={close}
                  className={classes.modalSecondaryButton}>
                  Close
                </Button>
              </DialogActions>
            </form>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}

const validationSchema = yup.object().shape({
  branch_name: yup
    .string()
    .required('Branch Name is required')
    .matches(/^([^0-9]*)$/, 'Digits are not allowed')
    .max(50, 'Maximum character limit exceeded')
    .matches(VALIDATE_WHITESPACES, 'Space should not come at begining and end'),

  account_no: yup
    .string()
    .required('Account Number is required')
    .matches(/^[0-9]*$/, 'Only numbers are allowed')
    .min(9, 'Account number should be between 9 to 18 digits')
    .max(18, 'Account number should be between 9 to 18 digits')
    .matches(VALIDATE_WHITESPACES, 'Space should not come at begining and end'),

  ifsc: yup
    .string()
    .required('IFSC Code is required')
    .max(11, 'IFSC_Code must be off 11 digits')
    .matches(VALIDATE_WHITESPACES, 'Space should not come at begining and end'),

  transaction: yup
    .string()
    .required('Transaction ID is required')
    .max(50, 'Maximum character limit exceeded')
    .matches(VALIDATE_WHITESPACES, 'Space should not come at begining and end'),

})

export default ConfirmApprove
