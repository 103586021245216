import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import EditIcon from '@material-ui/icons/Edit'
import VisibilityIcon from '@material-ui/icons/Visibility'
import TextField from '@material-ui/core/TextField'
import Chip from '@material-ui/core/Chip'
import FiberSmartRecordIcon from '@material-ui/icons/FiberSmartRecord'
import LocalDrinkIcon from '@material-ui/icons/LocalDrink'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import AdminNavbar from '../../components/commonComponents/AdminNavbar'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import { Chart } from 'react-google-charts'

const columns = [
  { id: 'state', label: 'State', minWidth: 170 },
  { id: 'volume', label: 'Prescribed Vol', minWidth: 170 },
  { id: 'change', label: 'Change (Monthly)', minWidth: 100 }
]

const columns2 = [
  { id: 'doctor', label: 'Doctor', minWidth: 170 },
  { id: 'volume', label: 'Prescribed Vol', minWidth: 170 },
  { id: 'change', label: 'Change (Monthly)', minWidth: 100 }
]

function createData(state, volume, change) {
  return { state, volume, change }
}

function createData2(doctor, volume, change) {
  return { doctor, volume, change }
}

const rows = [
  createData('Maharashtra', 180001, '+12%'),
  createData('West Bengal', 125784, '+6.5%'),
  createData('Tamil Nadu', 90784, '-7.2%'),
  createData('Punjab', 85784, '+1.6%'),
  createData('Uttar Pradesh', 77884, '+3.3%'),
  createData('Bihar', 51360, '-4.8%')
]

const rows2 = [
  createData2('Dr. A Singh', 180001, '+12%'),
  createData2('Dr. Subho Paul', 125784, '+6.5%'),
  createData2('Dr. Rahul S', 90784, '-7.2%'),
  createData2('Dr. Swaroop A S', 85784, '+1.6%'),
  createData2('Dr. John Doe', 65999, '+4.6%'),
  createData2('Dr. Martin Roy', 30083, '-0.6%')
]

const useStyles = makeStyles({
  root: {
    width: '100%'
  },
  container: {
    maxHeight: 440
  },
  annotation: {
    textAlign: 'center',
    marginBottom: '10px'
  }
})

export default function StickyHeadTable() {
  const classes = useStyles()
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [age, setAge] = React.useState('')

  const handleChange = event => {
    setAge(event.target.value)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  return (
    <Paper className={classes.root}>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Box>
            <AdminNavbar />
          </Box>
        </Grid>

        <Grid item xs={12} md={12} style={{ padding: '15px' }}>
          <Typography variant="h4" className={classes.annotation}>
            <ArrowBackIosIcon /> Azithromicin
          </Typography>
        </Grid>

        <Grid item xs={12} md={6} style={{ padding: '45px' }}>
          <FormControl className={classes.formControl}>
            <InputLabel
              id="demo-simple-select-label"
              style={{ width: '300px' }}>
              Select State
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={age}
              onChange={handleChange}
              style={{ width: '300px', marginBottom: '25px' }}>
              <MenuItem value={10}>Maharashtra</MenuItem>
              <MenuItem value={20}>West Bengal</MenuItem>
              <MenuItem value={30}>Punjab</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            className={classes.formControl}
            style={{ marginLeft: '25px' }}>
            <InputLabel
              id="demo-simple-select-label"
              style={{ width: '180px' }}>
              Select Month
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={age}
              onChange={handleChange}
              style={{ width: '180px', marginBottom: '25px' }}>
              <MenuItem value={10}>October</MenuItem>
              <MenuItem value={20}>September</MenuItem>
              <MenuItem value={30}>August</MenuItem>
            </Select>
          </FormControl>
          <Box>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map(column => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}>
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(row => {
                      return (
                        <>
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.code}>
                            {columns.map(column => {
                              const value = row[column.id]
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {column.format && typeof value === 'number'
                                    ? column.format(value)
                                    : value}
                                </TableCell>
                              )
                            })}
                          </TableRow>
                        </>
                      )
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={6} style={{ padding: '45px' }}>
          <FormControl className={classes.formControl}>
            <InputLabel
              id="demo-simple-select-label"
              style={{ width: '300px' }}>
              Search Doctor
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={age}
              onChange={handleChange}
              style={{ width: '300px', marginBottom: '25px' }}>
              <MenuItem value={10}>Maharashtra</MenuItem>
              <MenuItem value={20}>West Bengal</MenuItem>
              <MenuItem value={30}>Punjab</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            className={classes.formControl}
            style={{ marginLeft: '25px' }}>
            <InputLabel
              id="demo-simple-select-label"
              style={{ width: '180px' }}>
              Select Month
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={age}
              onChange={handleChange}
              style={{ width: '180px', marginBottom: '25px' }}>
              <MenuItem value={10}>October</MenuItem>
              <MenuItem value={20}>September</MenuItem>
              <MenuItem value={30}>August</MenuItem>
            </Select>
          </FormControl>
          <Box>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns2.map(column => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}>
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows2
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(row => {
                      return (
                        <>
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.code}>
                            {columns2.map(column => {
                              const value = row[column.id]
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {column.format && typeof value === 'number'
                                    ? column.format(value)
                                    : value}
                                </TableCell>
                              )
                            })}
                          </TableRow>
                        </>
                      )
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={6} style={{ padding: '5px' }}>
          <Chart
            width={'1000px'}
            height={'500px'}
            chartType="PieChart"
            loader={<div>Loading Chart</div>}
            data={[
              ['State', 'Percentage (%)'],
              ['Maharashtra', 83],
              ['Punjab', 13],
              ['Haryana', 1.4],
              ['Delhi', 2.3],
              ['Gujarat', 46],
              ['Tamil Nadu', 30],
              ['Uttar Pradesh', 38],
              ['Manipur', 5.5],
              ['Assam', 5],
              ['Andhra Pradesh', 20]
            ]}
            options={{
              title: 'Indian States',
              legend: 'none',
              pieSliceText: 'label',
              slices: {
                4: { offset: 0.2 },
                12: { offset: 0.3 },
                14: { offset: 0.4 },
                15: { offset: 0.5 }
              }
            }}
            rootProps={{ 'data-testid': '5' }}
          />
        </Grid>

        <Grid item xs={12} md={6} style={{ padding: '5px' }}>
          <Chart
            width={'1000px'}
            height={'500px'}
            chartType="PieChart"
            loader={<div>Loading Chart</div>}
            data={[
              ['Doctors', 'Percentage (%)'],
              ['Dr. A D Bannerjee', 83],
              ['Dr. Subho Paul', 13],
              ['Dr. Monosij', 1.4],
              ['Dr. Abhisek Sengupta', 2.3],
              ['Dr. Swaroop Anand', 46],
              ['Dr. Rahul S', 30],
              ['Dr. Ajay Mehta', 38],
              ['Dr. R D Barman', 5.5],
              ['Dr. Atul', 5],
              ['Dr. Niraj Jain', 20]
            ]}
            options={{
              title: 'Doctors',
              legend: 'none',
              pieSliceText: 'label',
              slices: {
                4: { offset: 0.2 },
                12: { offset: 0.3 },
                14: { offset: 0.4 },
                15: { offset: 0.5 }
              }
            }}
            rootProps={{ 'data-testid': '5' }}
          />
        </Grid>

        <Grid item xs={12} md={12} style={{ padding: '45px' }}>
          <Chart
            width={'1250px'}
            height={'750px'}
            chartType="ComboChart"
            loader={<div>Loading Chart</div>}
            data={[
              [
                'Month',
                'Maharashtra',
                'West Bengal',
                'Tamil Nadu',
                'Punjab',
                'Gujarat',
                'Average'
              ],
              ['June', 165, 938, 522, 998, 450, 614.6],
              ['July', 135, 1120, 599, 1268, 288, 682],
              ['August', 157, 1167, 587, 807, 397, 623],
              ['September', 139, 1110, 615, 968, 215, 609.4],
              ['October', 136, 691, 629, 1026, 366, 569.6]
            ]}
            options={{
              title: 'Monthly Prescribed Vol by States (Top 5)',
              vAxis: { title: 'Vol' },
              hAxis: { title: 'Month' },
              seriesType: 'bars',
              series: { 5: { type: 'line' } }
            }}
            rootProps={{ 'data-testid': '1' }}
          />
        </Grid>
      </Grid>
    </Paper>
  )
}
