import React, { useEffect, useState, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Loader from '../../components/commonComponents/Loader'
import Box from '@material-ui/core/Box'
import { useFormik } from 'formik'
import * as yup from 'yup'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import SnackbarAlert from '../../components/commonComponents/SnackbarAlert'
import MuiAlert from '@material-ui/lab/Alert'
import Slide from '@material-ui/core/Slide'
import {
  SPECIAL_CHAR,
  NETWORK_STATUS,
  VALIDATE_WHITESPACES
} from '../../Constants'
import { AdminStore } from '../../stores'
import VisibilityIcon from '@material-ui/icons/Visibility'

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
    backgroundColor: 'rgb(16 29 71)'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  drawer: {
    width: '20%',
    flexBasis: '20%',
    maxWidth: '260px'
  },
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch'
    }
  },
  Modal_popup: {
    height: '100%',
    position: 'absolute',
    top: '0',
    right: '0',
    zIndex: '99',
    cursor: 'pointer',
    display: 'none',
    /*This makes the button huge. If you want a bigger button, increase the font size*/
    fontSize: '50px',
    /*Opacity settings for all browsers*/
    opacity: '0',
    mozOpacity: '0',
    msFilter: 'progid:DXImageTransform.Microsoft.Alpha(opacity=0)'
  },
  errMsg: {
    color: 'red'
  },

  paper: {
    padding: '10px',
    '@media (max-width:767px)': {
      marginTop: '40px'
    }
  },
  Editprofilecol: {
    padding: '40px',
    '@media (max-width:767px)': {
      padding: '40px 15px 15px 0'
    },
    top: '20%',
    position: 'relative'
  },
  viewIcon: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  colorInherit: {
    color: '#f44336 !important'
  }
}))

function rand() {
  return Math.round(Math.random() * 20) - 10
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

function getModalStyle() {
  const top = 50 + rand()
  const left = 50 + rand()
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  }
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const singleSpaceRegex = /^(\w+\s)*\w+$/

const UpdateBankDetails = React.forwardRef((props, ref) => {
  const { user_master_id } = props
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [multiButton, setmultiButton] = useState(false)
  const [alertType, setAlertType] = useState('')
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [isSnackBarOpen, setOpenSnackBar] = React.useState(false)
  const [bankdetails, setBankDetails] = React.useState(false)
  const [accVisibility, setAccVisibility] = React.useState(false)

  const save = useRef()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClickClose = () => {
    setOpen(false)
  }

  const handleSave = () => {
    // setOpen(false);
    save.current.click()
  }

  const { doctorBankDetails, getBankDetails } = AdminStore.useStoreActions(
    actions => ({
      ...actions.profile
    })
  )

  const {
    docbankdetailsAddedStatus,
    getBankList,
    networkStatus
  } = AdminStore.useStoreState(state => ({
    ...state.profile
  }))

  useEffect(() => {
    if (
      docbankdetailsAddedStatus === undefined ||
      docbankdetailsAddedStatus === ''
    ) {
      //
    } else if (
      docbankdetailsAddedStatus.statusCode === 400 ||
      docbankdetailsAddedStatus.statusCode === 533
    ) {
      setOpenSnackBar(true)
      setSnackBarMessage(
        docbankdetailsAddedStatus.message.replace(/['"]+/g, '')
      )
      // setSnackBarMessage('Patient profile not updated successfully')
      setmultiButton(false)
      setAlertType('error')
    } else {
      setOpenSnackBar(true)
      setSnackBarMessage('Bank Details Updated Successfully')
      // setSnackBarMessage('Patient profile not updated successfully')
      setmultiButton(false)
      setAlertType('success')
      setTimeout(() => {
        setOpen(false)
      }, 2000)
    }
  }, [docbankdetailsAddedStatus])

  useEffect(() => {
    if (getBankList && getBankList.message) {
      setBankDetails(getBankList.message)
    }
  }, [getBankList])

  useEffect(() => {
    if (user_master_id !== undefined) {
      getBankDetails({ doctor_id: user_master_id })
    }
  }, [open])

  const submit = async values => {
    doctorBankDetails({
      ...values,
      doctor_id: user_master_id
    })
  }

  const formik = useFormik({
    initialValues: {
      account_no: bankdetails.primary_account_no
        ? bankdetails.primary_account_no
        : '',
      cnfrmaccount_no: bankdetails.primary_account_no
        ? bankdetails.primary_account_no
        : '',
      bank_name: bankdetails.primary_bank_name
        ? bankdetails.primary_bank_name
        : '',
      branch_name: bankdetails.primary_branch_name
        ? bankdetails.primary_branch_name
        : '',
      ifsc_code: bankdetails.primary_ifsc_code
        ? bankdetails.primary_ifsc_code
        : '',
      beneficiary_name: bankdetails.primary_beneficiary_name
        ? bankdetails.primary_beneficiary_name
        : ''
    },

    //Control whether Formik should reset the form if initialValues changes (enableReinitialize)
    enableReinitialize: true,
    validationSchema,
    onSubmit: submit
  })
  const showSnackBar = () => {
    setOpenSnackBar(true)
  }

  const hideSnackBar = () => {
    setOpenSnackBar(false)
  }
  const Input = e => {
    e.preventDefault()
    setOpenSnackBar(true)
    setSnackBarMessage('Paste option disabled...')
    setmultiButton(false)
    setAlertType('info')
  }

  return (
    <div>
      <Button
        ref={ref}
        className={classes.Modal_popup}
        onClick={handleClickOpen}>
        Open full-screen dialog
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClickClose}
        TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              classes={{
                colorInherit: classes.colorInherit
              }}
              onClick={handleClickClose}
              aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Update Bank Details
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={handleSave}
              style={{
                border: '1px rgba(255,255,255, 0.5) solid',
                borderRadius: '40px',
                padding: '5px 20px'
              }}>
              <b>save</b>
            </Button>
          </Toolbar>
        </AppBar>
        <div className={classes.Editprofilecol}>
          <Paper elevation={3} className={classes.paper}>
            {networkStatus === NETWORK_STATUS.FETCHING ? (
              <Loader open={true} />
            ) : (
              <Loader />
            )}

            <SnackbarAlert
              isOpen={isSnackBarOpen}
              message={snackBarMessage}
              alertType={alertType}
              multibutton={multiButton}
              primaryClick={hideSnackBar}
            />

            <form
              onSubmit={formik.handleSubmit}
              noValidate={true}
              className={classes.root}>
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <Grid container spacing={3}>
                    <Grid item xs={5}>
                      <TextField
                        id="account_no"
                        style={{ width: '100%' }}
                        type={accVisibility == true ? 'text' : 'password'}
                        placeholder="Account No"
                        onChange={formik.handleChange}
                        value={
                          formik && formik.values && formik.values.account_no
                        }
                        onBlur={formik.handleBlur}
                        name="account_no"
                        label="Account No *"
                        helperText={
                          <div className={classes.errMsg}>
                            {formik.touched.account_no &&
                            formik.errors.account_no
                              ? formik.errors.account_no
                              : null}
                          </div>
                        }
                        InputProps={{
                          endAdornment: (
                            <VisibilityIcon
                              onClick={() => setAccVisibility(!accVisibility)}
                              className={classes.viewIcon}
                            />
                          )
                        }}
                      />
                      <TextField
                        id="cnfrmaccount_no"
                        style={{ width: '100%' }}
                        placeholder="Confirm Account No"
                        name="cnfrmaccount_no"
                        onPaste={Input}
                        label="Confirm Account No *"
                        type="password"
                        value={
                          formik &&
                          formik.values &&
                          formik.values.cnfrmaccount_no
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        helperText={
                          <div className={classes.errMsg}>
                            {formik.touched.cnfrmaccount_no &&
                            formik.errors.cnfrmaccount_no
                              ? formik.errors.cnfrmaccount_no
                              : null}
                          </div>
                        }
                      />
                      <TextField
                        id="bank_name"
                        style={{ width: '100%' }}
                        type="text"
                        placeholder="Bank Name"
                        label="Bank Name *"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={
                          formik && formik.values && formik.values.bank_name
                        }
                        name="bank_name"
                        helperText={
                          <div className={classes.errMsg}>
                            {formik.touched.bank_name && formik.errors.bank_name
                              ? formik.errors.bank_name
                              : null}
                          </div>
                        }
                      />
                      <TextField
                        id="branch_name"
                        style={{ width: '100%' }}
                        type="text"
                        placeholder="Branch Name "
                        label="Branch Name  *"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={
                          formik && formik.values && formik.values.branch_name
                        }
                        name="branch_name"
                        helperText={
                          <div className={classes.errMsg}>
                            {formik.touched.branch_name &&
                            formik.errors.branch_name
                              ? formik.errors.branch_name
                              : null}
                          </div>
                        }
                      />
                    </Grid>
                    {/* <Grid item xs={1} /> */}
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    id="ifsc_code"
                    style={{ width: '95%' }}
                    type="text"
                    placeholder="IFSC Code"
                    value={formik && formik.values && formik.values.ifsc_code}
                    name="ifsc_code"
                    label="IFSC Code *"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    // disabled={true}
                    helperText={
                      <div className={classes.errMsg}>
                        {formik.touched.ifsc_code && formik.errors.ifsc_code
                          ? formik.errors.ifsc_code
                          : null}
                      </div>
                    }
                  />
                  <TextField
                    id="beneficiary_name"
                    style={{ width: '95%' }}
                    placeholder="Beneficiary Name"
                    name="beneficiary_name"
                    label="Beneficiary Name *"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={
                      formik && formik.values && formik.values.beneficiary_name
                    }
                    helperText={
                      <div className={classes.errMsg}>
                        {formik.touched.beneficiary_name &&
                        formik.errors.beneficiary_name
                          ? formik.errors.beneficiary_name
                          : null}
                      </div>
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <button
                    type={'submit'}
                    className={classes.Modal_popup}
                    ref={save}>
                    Save
                  </button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </div>
      </Dialog>
    </div>
  )
})

const validationSchema = yup.object().shape({
  account_no: yup
    .string()
    .required('Account No is required')
    .matches(/^[0-9]*$/, 'Only numbers are allowed')
    .min(9, 'Account number should be between 9 to 18 digits')
    .max(18, 'Account number should be between 9 to 18 digits')
    .matches(VALIDATE_WHITESPACES, 'Remove extra spaces'),

  cnfrmaccount_no: yup
    .string()
    .required('Confirm account No is required')
    .oneOf([yup.ref('account_no'), null], 'Confirm Account No must match')
    .matches(/^[0-9]*$/, 'Only numbers are allowed')
    .min(9, 'Account number should be between 9 to 18 digits')
    .max(18, 'Account number should be between 9 to 18 digits')
    .matches(VALIDATE_WHITESPACES, 'Remove extra spaces'),

  bank_name: yup
    .string()
    .required('Bank Name is required')
    .max(50, 'Character exceeded the limit!')
    .matches(/^([^0-9]*)$/, 'Digits are not allowed')
    .matches(SPECIAL_CHAR, 'Special Characters are not allowed')
    .matches(VALIDATE_WHITESPACES, 'Remove extra spaces')
    .matches(singleSpaceRegex, 'Remove extra spaces'),

  branch_name: yup
    .string()
    .max(50, 'Character exceeded the limit!')
    .required('Branch Name is required')
    .matches(/^([^0-9]*)$/, 'Digits are not allowed')
    .matches(VALIDATE_WHITESPACES, 'Remove extra spaces')
    .matches(singleSpaceRegex, 'Remove extra spaces'),

  ifsc_code: yup
    .string()
    .min(11, 'IFSC_Code must be 11 digit code!')
    .required('IFSC Code is required')
    .matches(VALIDATE_WHITESPACES, 'Remove extra spaces')
    .matches(singleSpaceRegex, 'Remove extra spaces'),

  beneficiary_name: yup
    .string()
    .max(50, 'Character exceeded the limit!')
    .required('Beneficiary Name is required')
    .matches(/^([^0-9]*)$/, 'Digits are not allowed')
    .matches(VALIDATE_WHITESPACES, 'Remove extra spaces')
    .matches(singleSpaceRegex, 'Remove extra spaces')
})

export default UpdateBankDetails
