import React from 'react'
import { Box, Link } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Link as RouterLink } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import Badge from '@material-ui/core/Badge'
import MailIcon from '@material-ui/icons/Mail'
import Logo from '../../images/logo.png'
const AdminNavbar = props => {
  const classes = useStyles()

  const logoutClicked = () => {
    localStorage.removeItem('admin_id_token')
    localStorage.removeItem('user_id')
    window.location.href = '/admin/login'
  }

  const logoClicked = () => {
    window.location.href = '/admin/dashboard'
  }

  return (
    <Box
      w="100%"
      h="100%"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      {...props}
      className={classes.headerSection}>
      <img
        className={classes.Logoimg}
        src={Logo}
        alt="Logo"
        onClick={logoClicked}
      />
      <Box justifyContent="right">
        <Button className={classes.contactButton} onClick={logoutClicked}>
          Logout
        </Button>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles(() => ({
  headerSection: {
    padding: '12px 40px'
  },
  Logoimg: {
    height: '60px',
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'none'
    }
  },
  loginButton: {
    color: '#212121',
    marginRight: '4rem',
    fontWeight: '400',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'none',
      color: '535f8f'
    }
  },

  contactButton: {
    borderRadius: '40px',
    // backgroundColor:"rgb(16 29 71)",
    backgroundColor: '#111d4e',
    color: '#fff',
    padding: '10px 20px',
    textTransform: 'initial',
    '&:hover':{
      backgroundColor: '#111d4e',
    }
  }
}))

export default AdminNavbar
