import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  FormControl,
  FormGroup,
  FormControlLabel,
  Button,
  InputAdornment
} from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Loading from 'react-loading'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import EditIcon from '@material-ui/icons/Edit'
import VisibilityIcon from '@material-ui/icons/Visibility'
import TextField from '@material-ui/core/TextField'
import Chip from '@material-ui/core/Chip'
import FiberSmartRecordIcon from '@material-ui/icons/FiberSmartRecord'
import LocalDrinkIcon from '@material-ui/icons/LocalDrink'
import Modal from 'react-modal'
import { useFormik } from 'formik'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Swal from 'sweetalert2'
import { FaSearch } from 'react-icons/fa'
import { AdminStore } from '../../stores'
import NoRecordImg from '../../images/noRecordFound.png'
import Menu from '@material-ui/core/Menu'
import moment from 'moment'
import clsx from 'clsx'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { debounce } from 'lodash'
import SnackbarAlert from '../../components/commonComponents/SnackbarAlert'
import { NETWORK_STATUS } from '../../Constants'
import SearchIcon from '@material-ui/icons/Search'

const columns = [
  {
    id: 'doctor_name',
    label: 'Doctor Name',
    minWidth: 180
    // align: 'center'
  },
  {
    id: 'patient_name',
    label: 'Patient Name',
    minWidth: 180
    // align: 'center'
  },
  {
    id: 'appointment_date',
    label: 'Appointment Date and Time',
    minWidth: 170
    // align: 'center'
  },
  {
    id: 'duration',
    label: 'Duration',
    maxWidth: 150
  },
  {
    id: 'appointment_type',
    label: 'Appointment Type',
    maxWidth: 170
    // align: 'center'
  },
  {
    id: 'status',
    label: 'Status',
    maxWidth: 150
    // align: 'center'
  },
  {
    id: 'purpose',
    label: 'Purpose',
    maxWidth: 170,
    minWidth: 150
    // align: 'center'
  }
]

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  paper: {
    padding: '10px',
    '@media (max-width:767px)': {
      marginTop: '40px'
    }
  },
  container: {
    maxHeight: 440,
    display: 'block',
    // overflowY: "clip",
    marginTop: '5px',
    '@media (max-width:767px)': {
      marginTop: '-20px !important'
    }
  },
  visibilityModal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '500px',
    zIndex: '1000005'
  },
  viewModalButton: {
    backgroundColor: '#3f4b79',
    color: 'white',
    borderRadius: 3,
    fontWeight: 200,
    padding: '0 30px',
    marginBottom: '10px',
    height: 40,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  visibilityIconMain: {
    color: '#202020',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  editIconMain: {
    color: '#9aca09',
    '&:hover': {
      cursor: 'pointer',
      color: '#9aca09'
    }
  },
  editModal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '500px',
    zIndex: '1000005',
    alignItems: 'center',
    justifyContent: 'center',
    border: '2px solid #3f4b79'
  },
  loadingSpinner: {
    position: 'absolute',
    top: '42px',
    right: '150px',
    left: '430px',
    '@media (max-width:976px)': {
      right: '5px'
    }
  },
  modalMain: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    width: '100%',
    height: '45%',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  textField: {
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: 0,
    marginTop: 0,
    fontWeight: 500
  },
  selectPtag: {
    fontSize: '14px',
    width: 'auto',
    fontWeight: 500
  },
  modalFields: {
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '20px'
  },
  tablecontent: {
    '& h6': {
      fontSize: '16px',
      padding: '15px 10px'
    },
    '& h5': {
      fontSize: '16px',
      fontWeight: 'bold',
      color: '#fff',
      margin: '0px'
    },
    '& p': {
      fontSize: '16px',
      marginBottom: '0px',
      fontWeight: '400'
    },
    '& .MuiTableCell-head': {
      color: '#fff',
      background: '#111d4e'
    },
    '& .MuiTableCell-root': {
      padding: '20px 10px'
    },
    '& .MuiPaper-rounded': {
      boxShadow: 'none !important',
      borderRadius: '0px !important'
    }
  },
  // loadingSpinner: {
  //   position: 'absolute',
  //   top: '40px',
  //   right: '150px',
  //   '@media (max-width:976px)': {
  //     right: '5px'
  //   }
  // },
  searchicon: {
    position: 'absolute',
    top: '40px',
    right: '175px',
    '@media (max-width:976px)': {
      right: '25px'
    }
  },
  Searchcol: {
    '@media (max-width:767px)': {
      marginBottom: '15px'
    }
  },
  modalView: {
    width: '32rem !important',
    height: '35rem'
  },
  ModalHead: {
    backgroundColor: '#111d4e',
    color: '#fff'
  },
  modalSecondaryButton: {
    backgroundColor: '#e4e4e4',
    padding: '5px 20px',
    borderRadius: '40px',
    color: '#111d4e',
    '&:hover': {
      backgroundColor: '#e4e4e4',
      color: '#111d4e'
    }
  },
  loading: {
    position: 'relative',
    top: '50%',
    left: '42%'
  }
}))

const menuTheme = createMuiTheme({
  overrides: {
    MuiMenu: {
      paper: {
        boxShadow: 'none',
        border: '1px solid #000',
        marginRight: 10
      }
    }
  }
})

export default function ApppointmentsList() {
  const classes = useStyles()
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [viewModal, setViewModal] = React.useState(false)
  const [viewModalData, setViewModalData] = React.useState({})
  const [editModal, setEditModal] = React.useState(false)
  const [editModalData, setEditModalData] = React.useState({})
  const [appointments, setAppointments] = React.useState([])
  const [searchString, setSearchString] = React.useState('')
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [appointmentData, setAppointmentData] = React.useState([])
  const [totalRowsCount, setTotalRowsCount] = React.useState([])
  const [multiButton, setmultiButton] = React.useState(false)
  const [alertType, setAlertType] = React.useState('')
  const [snackBarMessage, setSnackBarMessage] = React.useState('')
  const [isSnackBarOpen, setOpenSnackBar] = React.useState(false)

  function createData(name, specialization, contact, email) {
    return { name, specialization, contact, email }
  }

  const {
    getHospitalAppointments,
    cancelHospitalAppointment,
    clearSnack
  } = AdminStore.useStoreActions(actions => ({
    ...actions.doctorList
  }))

  const {
    hospitalAppointmentsList,
    appointmentsListRowCount,
    networkStatus,
    cancelAppointmentStatusCode
  } = AdminStore.useStoreState(state => ({
    ...state.doctorList
  }))

  React.useEffect(() => {
    clearSnack()
    return () => {
      clearSnack()
    }
  }, [])

  const debouncedGetHospitalAppointments = React.useCallback(
    debounce(
      searchString =>
        getHospitalAppointments({
          table_config_search_string: searchString,
          table_config_page_no: 1,
          table_config_rows_per_page: rowsPerPage
        }),
      1000
    ),
    []
  )

  useEffect(() => {
    getHospitalAppointments({
      table_config_rows_per_page: rowsPerPage,
      table_config_page_no: page + 1,
      table_config_search_string: searchString
    })
  }, [rowsPerPage, page])

  useEffect(() => {
    setAppointments(hospitalAppointmentsList)
  }, [hospitalAppointmentsList])

  //Working fine
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  //#bug ------ async await needs to be added
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const visibilityIconClicked = rowData => {
    setViewModal(true)
    setViewModalData(rowData)
  }

  const editIconClicked = (appointment, e) => {
    setAnchorEl(e.currentTarget)
    setAppointmentData(appointment)
  }

  const handleEditIconClose = () => {
    setAnchorEl(null)
  }

  const submit = values => {
    setEditModal(false)
    Swal.fire({
      title: 'RX Analytics Data Updated',
      text: `Name: ${values.name} Type: ${values.type} Date: ${values.dateAdded} Vol: ${values.prescribedVol} Demand: ${values.demand} State: ${values.state}`,
      icon: 'info',
      confirmButtonText: 'Fantastic!'
    })
  }

  const formik = useFormik({
    initialValues: {
      name: editModalData.name,
      type: editModalData.type,
      dateAdded: editModalData.date,
      prescribedVol: editModalData.presc,
      demand: editModalData.demand,
      state: editModalData.state
    },
    enableReinitialize: true,
    onSubmit: submit
  })

  const onSearchUsed = e => {
    setSearchString(e.target.value)
    debouncedGetHospitalAppointments(e.target.value)
  }

  const getAppointmentType = type => {
    switch (type) {
      case '1':
        if (viewModal == true) {
          return 'Offline'
        } else {
          return (
            <p style={{ textAlign: viewModal ? 'right' : 'center' }}>Offline</p>
          )
        }
      case '2':
        if (viewModal == true) {
          return 'Online'
        } else {
          return (
            <p style={{ textAlign: viewModal ? 'right' : 'center' }}>Online</p>
          )
        }
      default:
        if (viewModal == true) {
          return 'Invalid'
        } else {
          return (
            <p style={{ textAlign: viewModal ? 'right' : 'center' }}>Invalid</p>
          )
        }
    }
  }

  const getAppointmentStatus = status => {
    switch (status) {
      case '1':
        if (viewModal == true) {
          return 'Booked (Not Paid)'
        } else {
          return (
            <p style={{ textAlign: viewModal ? 'right' : 'center' }}>Booked</p>
          )
        }

      case '2':
        if (viewModal == true) {
          return 'Confirmed'
        } else {
          return (
            <p style={{ textAlign: viewModal ? 'right' : 'center' }}>
              Confirmed
            </p>
          )
        }
      case '3':
        if (viewModal == true) {
          return 'Checked-in'
        } else {
          return (
            <p style={{ textAlign: viewModal ? 'right' : 'center' }}>
              Checked-in
            </p>
          )
        }
      case '4':
        if (viewModal == true) {
          return 'In Progress'
        } else {
          return (
            <p style={{ textAlign: viewModal ? 'right' : 'center' }}>
              In Progress
            </p>
          )
        }
      case '5':
        if (viewModal == true) {
          return 'Cancelled'
        } else {
          return (
            <p style={{ textAlign: viewModal ? 'right' : 'center' }}>
              Cancelled
            </p>
          )
        }
      case '6':
        if (viewModal == true) {
          return 'No Show'
        } else {
          return (
            <p style={{ textAlign: viewModal ? 'right' : 'center' }}>No Show</p>
          )
        }
      case '7':
        if (viewModal == true) {
          return 'Completed'
        } else {
          return (
            <p style={{ textAlign: viewModal ? 'right' : 'center' }}>
              Completed
            </p>
          )
        }
      case '8':
        if (viewModal == true) {
          return 'Rescheduled'
        } else {
          return (
            <p style={{ textAlign: viewModal ? 'right' : 'center' }}>
              Rescheduled
            </p>
          )
        }

      default:
        if (viewModal == true) {
          return 'Invalid'
        } else {
          return (
            <p style={{ textAlign: viewModal ? 'right' : 'center' }}>Invalid</p>
          )
        }
    }
  }

  // useEffect(() => {
  //   console.log(cancelAppointmentStatusCode)
  // }, [cancelAppointmentStatusCode])

  const viewModalHandler = data => {
    setViewModal(true)
    setViewModalData(data)
  }

  const deleteAppointmentHandler = () => {
    handleEditIconClose()
    cancelHospitalAppointment({
      appointment_id: appointmentData.appointment_id,
      appointment_type: appointmentData.appointment_type
    })
    clearSnack()
  }
  React.useEffect(() => {
    if (cancelAppointmentStatusCode == undefined) {
      setOpenSnackBar(false)
    } else if (cancelAppointmentStatusCode.statusCode == 400) {
      setOpenSnackBar(true)
      setSnackBarMessage(cancelAppointmentStatusCode.message)
      // setSnackBarMessage('Patient profile not updated successfully')
      setmultiButton(false)
      setAlertType('error')
    } else {
      setOpenSnackBar(true)
      setSnackBarMessage(cancelAppointmentStatusCode.message)
      // setSnackBarMessage('Patient profile not updated successfully')
      setmultiButton(false)
      setAlertType('Success')
    }
    getHospitalAppointments({
      table_config_rows_per_page: rowsPerPage,
      table_config_page_no: page + 1,
      table_config_search_string: ''
    })
  }, [cancelAppointmentStatusCode])

  const getVisitType = type => {
    switch (type) {
      case '1':
        return 'Initial Consult'
      case '2':
        return 'Follow-up'
      default:
        return 'Invalid'
    }
  }

  const showSnackBar = () => {
    setOpenSnackBar(true)
  }

  const hideSnackBar = () => {
    setOpenSnackBar(false)
  }

  return (
    <React.Fragment>
      <React.Fragment>
        <Paper elevation={3} className={classes.paper}>
          <Grid item xs={12} md={6}>
            <FormControl component="fieldset" style={{ width: '100%' }}>
              <FormGroup aria-label="position" row style={{ width: '100%' }}>
                <FormControlLabel
                  className={classes.formcol}
                  style={{ width: '100%' }}
                  control={
                    <TextField
                      id="standard-basic"
                      label="Search Doctor"
                      style={{ margin: '20px 30px', width: '400px' }}
                      value={searchString}
                      onChange={onSearchUsed}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        )
                      }}
                    />
                  }
                />

                {/* <FormControlLabel
                  className={classes.searchicon}
                  control={<FaSearch />}
                /> */}
                <SnackbarAlert
                  isOpen={isSnackBarOpen}
                  message={snackBarMessage}
                  alertType={alertType}
                  multibutton={multiButton}
                  primaryClick={hideSnackBar}
                  autoHideDuration={3000}
                />
                {networkStatus == 'FETCHING' ? (
                  <Loading
                    type="spin"
                    textalign="center"
                    color="grey"
                    height={20}
                    width={20}
                    className={classes.loadingSpinner}
                  />
                ) : null}
              </FormGroup>
            </FormControl>
          </Grid>

          {appointments.length == 0 && networkStatus !== 'FETCHING' ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              <img alt="No data" style={{ width: '200px' }} src={NoRecordImg} />
            </div>
          ) : (
            <Grid item xs={12} sm={12} md={12} className={classes.tablecontent}>
              <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow
                      style={{ background: 'rgb(16 29 71)', color: '#fff' }}>
                      {/* <TableCell>
                        <h5>Doctor Name</h5>
                      </TableCell>
                      <TableCell>
                        <h5>Specialization</h5>
                      </TableCell>
                      <TableCell>
                        <h5>Phone Number</h5>
                      </TableCell>
                      <TableCell>
                        <h5>Email</h5>
                      </TableCell>
                      <TableCell>
                        <h5>Actions</h5>
                      </TableCell> */}
                      {columns.map(column => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                            maxWidth: column.maxWidth
                          }}>
                          {column.label}
                        </TableCell>
                      ))}
                      <TableCell align="right">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {appointments
                      // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .slice(0, rowsPerPage)
                      .map(data => {
                        return (
                          <TableRow>
                            {columns.map(column => {
                              const val = column.id
                              if (val == 'appointment_date') {
                                return (
                                  <TableCell align={column.align}>
                                    {`${moment(data['appointment_date']).format(
                                      'DD-MM-YYYY'
                                    )},
                                    ${moment(
                                      data['start_time'],
                                      'hh:mm:ss'
                                    ).format('hh:mm A')}`}
                                  </TableCell>
                                )
                              } else if (val == 'appointment_type') {
                                return (
                                  <TableCell align={column.align}>
                                    {getAppointmentType(data[val])}
                                  </TableCell>
                                )
                              } else if (val == 'status') {
                                return (
                                  <TableCell align={column.align}>
                                    {getAppointmentStatus(data[val])}
                                  </TableCell>
                                )
                              } else if (val == 'duration') {
                                return (
                                  <TableCell>
                                    {moment(data['end_time'], 'hh:mm:ss').diff(
                                      moment(data['start_time'], 'hh:mm:ss'),
                                      'minutes'
                                    )}
                                  </TableCell>
                                )
                              } else {
                                return (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}>
                                    {data[val]}
                                  </TableCell>
                                )
                              }
                            })}
                            <TableCell align="right">
                              <EditIcon
                                aria-controls="edit-menu"
                                aria-haspopup="true"
                                onClick={e => editIconClicked(data, e)}
                                className={classes.editIconMain}
                              />
                              <VisibilityIcon
                                onClick={() => viewModalHandler(data)}
                                className={classes.visibilityIconMain}
                              />
                              <ThemeProvider theme={menuTheme}>
                                <Menu
                                  id="edit-menu"
                                  anchorEl={anchorEl}
                                  keepMounted
                                  open={Boolean(anchorEl)}
                                  onClose={handleEditIconClose}
                                  PaperProps={{
                                    style: {
                                      marginTop: 37.5
                                    }
                                  }}>
                                  <MenuItem disabled onClick={handleEditIconClose}>
                                    Change status:
                                  </MenuItem>
                                  <MenuItem style={{fontSize:'14px'}} onClick={handleEditIconClose}>
                                    Confirmed
                                  </MenuItem>
                                  <MenuItem  style={{fontSize:'14px'}} onClick={handleEditIconClose}>
                                    Checked In
                                  </MenuItem>
                                  <MenuItem  style={{fontSize:'14px'}} onClick={handleEditIconClose}>
                                    In Progress
                                  </MenuItem>

                                  <MenuItem  style={{fontSize:'14px'}} onClick={deleteAppointmentHandler}>
                                    Cancel
                                  </MenuItem>
                                </Menu>
                              </ThemeProvider>
                            </TableCell>
                          </TableRow>
                        )
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}

          {appointments.length >= 1 ? (
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={
                appointmentsListRowCount !== undefined
                  ? appointmentsListRowCount
                  : 0
              }
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          ) : null}
        </Paper>
      </React.Fragment>
      <React.Fragment>
        <Dialog
          open={viewModal}
          classes={{ paper: classes.modalView }}
          // onClose={close}
          onClose={() => setViewModal(false)}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title" className={classes.ModalHead}>
            Appointment Details
            {/* <span
            style={styles}
            onMouseEnter={() => setColor('red')}
            onMouseLeave={() => setColor('')}
            onClick={handleClose}>
            X
          </span> */}
          </DialogTitle>
          <DialogContent style={{ overflow: 'hidden' }}>
            {networkStatus === NETWORK_STATUS.FETCHING ? (
              <Loading
                type="bubbles"
                color="#9138aa"
                height={50}
                className={classes.loading}
              />
            ) : (
              <div style={{ paddingLeft: '20px' }}>
                <div className={classes.modalFields}>
                  <b>Doctor Name:</b>
                  {viewModalData.doctor_name}
                </div>
                <div className={classes.modalFields}>
                  <b>Doctor Phone:</b>
                  {viewModalData.doctor_phone}
                </div>
                <div className={classes.modalFields}>
                  <b>Patient Name:</b>
                  {viewModalData.patient_name}
                </div>
                <div className={classes.modalFields}>
                  <b>Patient Phone:</b>
                  {viewModalData.patient_phone}
                </div>
                <div className={classes.modalFields}>
                  <b>Date and Time:</b>
                  {viewModalData.appointment_date}, {viewModalData.start_time}
                </div>
                <div className={classes.modalFields}>
                  <b>Appointment Status:</b>
                  {getAppointmentStatus(viewModalData.status)}
                </div>
                <div className={classes.modalFields}>
                  <b>Appointment Type:</b>
                  {getAppointmentType(viewModalData.appointment_type)}
                </div>
                <div className={classes.modalFields}>
                  <b>Visit Type:</b>
                  {getVisitType(viewModalData.visit_type)}
                </div>
                <div className={classes.modalFields}>
                  <b>Purpose:</b>
                  {viewModalData.purpose}
                </div>
              </div>
            )}
          </DialogContent>
          <DialogActions style={{ padding: '20px 40px' }}>
            <Button
              onClick={() => setViewModal(false)}
              className={classes.modalSecondaryButton}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
      <React.Fragment>
        <Modal
          isOpen={editModal}
          onRequestClose={() => {
            setEditModal(false)
          }}
          className={classes.editModal}>
          <p style={{ fontWeight: 'bold', textAlign: 'center' }}>
            EDIT RX ANALYTICS
          </p>
          <hr style={{ border: '1px solid black' }} />
          <form onSubmit={formik.handleSubmit}>
            <Grid>
              <Grid>
                <TextField
                  id="name"
                  style={{ width: '100%' }}
                  type="text"
                  placeholder="RX Name"
                  onChange={formik.handleChange}
                  value={formik && formik.values && formik.values.name}
                  onBlur={formik.handleBlur}
                  name="name"
                  label="RX Name"
                  helperText={
                    <div className={classes.errMsg}>
                      {formik.touched.name && formik.errors.name
                        ? formik.errors.name
                        : null}
                    </div>
                  }
                />
              </Grid>
              <Grid>
                <FormControl>
                  <InputLabel
                    shrink
                    id="demo-simple-select-placeholder-label-label">
                    RX Type
                  </InputLabel>
                  <Select
                    id="gender"
                    value={formik.values.type}
                    name="type"
                    label="RX Type"
                    // onChange={genderChange}
                    style={{ width: '100%' }}
                    onBlur={formik.handleBlur}
                    helperText={
                      <div className={classes.errMsg}>
                        {formik.touched.type && formik.errors.type
                          ? formik.errors.type
                          : null}
                      </div>
                    }>
                    <MenuItem value="1">
                      <FiberSmartRecordIcon style={{ color: '#202020' }} />
                    </MenuItem>
                    <MenuItem value="2">
                      <LocalDrinkIcon style={{ color: '#202020' }} />
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid>
                <TextField
                  id="dateAdded"
                  style={{ width: '100%' }}
                  type="text"
                  placeholder="Date Added"
                  onChange={formik.handleChange}
                  value={formik && formik.values && formik.values.dateAdded}
                  onBlur={formik.handleBlur}
                  name="dateAdded"
                  label="Date Added"
                  helperText={
                    <div className={classes.errMsg}>
                      {formik.touched.dateAdded && formik.errors.dateAdded
                        ? formik.errors.dateAdded
                        : null}
                    </div>
                  }
                />
              </Grid>
              <Grid>
                <TextField
                  id="prescribedVol"
                  style={{ width: '100%' }}
                  type="text"
                  placeholder="Prescribed Vol"
                  onChange={formik.handleChange}
                  value={formik && formik.values && formik.values.prescribedVol}
                  onBlur={formik.handleBlur}
                  name="prescribedVol"
                  label="Prescribed Vol"
                  helperText={
                    <div className={classes.errMsg}>
                      {formik.touched.prescribedVol &&
                      formik.errors.prescribedVol
                        ? formik.errors.prescribedVol
                        : null}
                    </div>
                  }
                />
              </Grid>
              <Grid>
                <FormControl>
                  <InputLabel
                    shrink
                    id="demo-simple-select-placeholder-label-label">
                    Market Demand
                  </InputLabel>
                  <Select
                    id="gender"
                    value={formik.values.demand}
                    name="type"
                    label="Market Demand"
                    // onChange={genderChange}
                    style={{ width: '100%' }}
                    onBlur={formik.handleBlur}
                    helperText={
                      <div className={classes.errMsg}>
                        {formik.touched.demand && formik.errors.demand
                          ? formik.errors.demand
                          : null}
                      </div>
                    }>
                    <MenuItem value="1">
                      <Chip label="High" style={{ backgroundColor: 'red' }} />
                    </MenuItem>
                    <MenuItem value="2">
                      <Chip
                        label="Moderate"
                        style={{ backgroundColor: 'yellow' }}
                      />
                    </MenuItem>
                    <MenuItem value="3">
                      <Chip label="Low" style={{ backgroundColor: 'green' }} />
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid>
                <TextField
                  id="state"
                  style={{ width: '100%' }}
                  type="text"
                  placeholder="State"
                  onChange={formik.handleChange}
                  value={formik && formik.values && formik.values.state}
                  onBlur={formik.handleBlur}
                  name="state"
                  label="State"
                  helperText={
                    <div className={classes.errMsg}>
                      {formik.touched.state && formik.errors.state
                        ? formik.errors.state
                        : null}
                    </div>
                  }
                />
              </Grid>
              <Grid style={{ textAlign: 'center' }}>
                <button type={'submit'} className={classes.viewModalButton}>
                  Confirm
                </button>
              </Grid>
            </Grid>
          </form>
        </Modal>
      </React.Fragment>
    </React.Fragment>
  )
}
