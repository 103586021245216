import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import PublicNavbar from '../../components/commonComponents/PublicNavbar'
import { Layout } from '../../layouts'
import { useLocation, useHistory } from 'react-router-dom'
import LoginForm from '../../pages/Admin/AdminLoginForm'
import AdminBgImg from '../../images/adminbg.png'
import Swal from 'sweetalert2'

const AdminLogin = props => {
  const location = useLocation()
  const history = useHistory()
  const classes = useStyles()

  const onLoginSuccess = ({ request, response }) => {
    if (response.statusCode === 200) {
      window.location.href = '/admin/dashboard'
    } else {
      return Swal.fire(
        'Login failed!',
        'Please enter your details correctly and try again',
        ''
      )
    }
  }

  const [showAlert, setAlert] = useState(false)

  useEffect(() => {
    if (props.history?.location?.state?.unauthorized === true) {
      setAlert(true)
    }
    return () => {}
  }, [])

  const fireAlert = () => {
    Swal.fire('', 'Please log in to continue', 'info').then(() => {
      setAlert(false)
      const state = { ...history.location.state }
      state.unauthorized = false
      history.replace({ ...history.location, state })
    })
  }

  return (
    <Layout hideNavbar>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Box>
            <PublicNavbar />
          </Box>
        </Grid>
        <Grid item md={6}>
          <Box
            m={{ xs: 4, sm: 6, md: 16 }}
            textAlign={{ xs: 'center', md: 'left' }}
            color="primary.dark">
            <LoginForm onSuccess={onLoginSuccess} />
          </Box>
        </Grid>
        <Grid item md={6}>
          <Box
            m={{ xs: 4, sm: 6, md: 16 }}
            width="80vh"
            display={{ xs: 'none', md: 'block' }}>
            <img className={classes.image} src={AdminBgImg} alt="Landing" />
          </Box>
        </Grid>
      </Grid>
      {showAlert && fireAlert()}
    </Layout>
  )
}

const useStyles = makeStyles(theme => ({
  image: {
    width: '100%'
  },
  count: {
    color: '#fff'
  },
  mobileImage: {
    width: '100%',
    objectFit: 'cover',
    height: '30vh'
  },
  annotation: {
    marginBottom: theme.spacing(8),
    '@media (max-width:780px)': {
      fontSize: '24px'
    }
  },
  colorWhiteBg: {
    backgroundColor: '#fff'
  },
  colorDarkBg: {
    backgroundColor: '#2d3864'
  }
}))

export default AdminLogin
