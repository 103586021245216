import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch'
    }
  }
}))

export default function BasicTextFields() {
  const classes = useStyles()

  return (
    <Grid container>
      <Grid item xs={12} md={2}></Grid>
      <Grid item xs={12} md={8}>
        <form
          className={classes.root}
          noValidate
          autoComplete="off"
          align="left">
          <TextField id="standard-basic" label="Rx Name" />
          <br />
          <FormControl component="fieldset" style={{ marginTop: '20px' }}>
            <FormLabel component="legend">Rx Type</FormLabel>
            <RadioGroup aria-label="gender" name="gender1">
              <FormControlLabel value="1" control={<Radio />} label="Tablet" />
              <FormControlLabel value="2" control={<Radio />} label="Capsule" />
              <FormControlLabel value="3" control={<Radio />} label="Syrup" />
              <FormControlLabel
                value="4"
                control={<Radio />}
                label="Injection"
              />
              <FormControlLabel value="5" control={<Radio />} label="Inhaler" />
              <FormControlLabel value="6" control={<Radio />} label="Drops" />
              <FormControlLabel value="7" control={<Radio />} label="Implant" />
            </RadioGroup>
          </FormControl>
          <br />
          <TextField id="standard-basic" label="Cost per unit" />
          <br />
          <TextField id="standard-basic" label="Generic Name" />
          <br />
          <Button variant="contained" className={classes.CancelButton}>
            Add
          </Button>
        </form>
      </Grid>
    </Grid>
  )
}
