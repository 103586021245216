import { action, thunk } from 'easy-peasy'
import api from '../../api'
import { NETWORK_STATUS } from '../../Constants'
import localforage from 'localforage'
import BaseModel from '../Base'

export default {
  ...BaseModel(),
  countryList: [],
  stateList: [],
  cityList: [],
  fetchingCountry: false,
  fetchingState: false,
  fetchingCity: false,

  doctorDetails: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
    // return
    api.setAuthHeaders({
      authorization: localStorage.getItem('admin_id_token')
    })
    const response = await api.hospital.createDoctorDetails({
      name: payload?.name,
      user_name: payload?.username,
      email: payload?.email.toLowerCase(),
      user_short_code: payload?.user_short_code,
      picture_name: payload.fileName,
      bio: payload?.bio,
      experience: payload?.experience,
      picture:
        payload && payload.format && payload.format[1] ? payload.format[1] : '',
      specialization: payload?.specialization,
      qualification: payload?.qualification,
      mobile_no: `${payload?.mobile_no}`,
      is_new_user: 'true',
      department_id: '2af4713f-1678-4a40-98aa-3f2d19ff0c88',
      role_id: '52c81c76-5236-4085-adfb-e8b4c7e4ba86',
      registration_no: payload?.registration_no,
      document:
        payload && payload.format && payload.format[1] ? payload.format[1] : '',
      document_name: payload && payload?.docName ? payload?.docName : '',
      country_code: payload?.country_id,
      state_code: payload?.state_id,
      city_code: payload?.city_id,
      pincode: payload?.pincode,
      associated_with: payload?.associated_with,
      clinic_id :payload?.clinic_list ? payload?.clinic_list : ""
    })

    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.setDocdetailsAddedStatus(response.data)
      localStorage.setItem('doctor_id', response.data.doctor_id)
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),
  setDocdetailsAddedStatus: action((state, payload) => {
    state.docdetailAddedStatus = payload
  }),

  doctorBankDetails: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
    api.setAuthHeaders({
      authorization: localStorage.getItem('admin_id_token')
    })
    const response = await api.hospital.addDoctorBankDetails({
      doctor_id: localStorage.getItem('doctor_id') == null
          ? payload?.doctor_id
          : localStorage.getItem('doctor_id'),
      // doctor_id: localStorage.getItem('doctor_id'),
      user_id: localStorage.getItem('user_id'),
      primary_account_no: payload?.account_no,
      primary_branch_name: payload?.branch_name,
      primary_bank_name: payload?.bank_name,
      primary_ifsc_code: payload?.ifsc_code,
      primary_beneficiary_name: payload?.beneficiary_name
    })
    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.setDocbankdetailsAddedStatus(response.data)
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),
  setDocbankdetailsAddedStatus: action((state, payload) => {
    state.docbankdetailsAddedStatus = payload
  }),

  subscriptionPlanList: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
    const response = await api.hospital.getSubscriptionList({
      subscription: payload?.subscription_plan
    })
    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.getSubscriptionPlanList(response.data)
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),
  getSubscriptionPlanList: action((state, payload) => {
    state.getSubscriptionList = payload
  }),

  addSubscriptionChoice: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
    const response = await api.hospital.subscriptionChoice({
      doctor_id: localStorage.getItem('doctor_id'),
      subscription_id: payload?.subscription_id
    })
    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.subscriptionChoiceAdded(response.data)
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),
  subscriptionChoiceAdded: action((state, payload) => {
    state.subscriptionChoiceStatus = payload
  }),

  clearDocDetails: action((state, payload) => {
    state.docdetailAddedStatus = undefined
    state.docbankdetailsAddedStatus = undefined
    state.subscriptionChoiceStatus = undefined
  }),

  getDoctorDetails: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
    const response = await api.hospital.getDoctorProfileDetails({
      user_short_code: payload?.user_short_code
    })
    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.getDoctorProfileList(response.data)
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),
  getDoctorProfileList: action((state, payload) => {
    state.getDoctorList = payload
  }),

  getBankDetails: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
    const response = await api.hospital.getDoctorBankDetails({
      doctor_id: payload?.doctor_id
    })
    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.getDoctorBankDetailList(response.data)
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),
  getDoctorBankDetailList: action((state, payload) => {
    state.getBankList = payload
  }),
  updatedDoctorProfile: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
    const response = await api.hospital.updateDoctorProfile({
      doctor_id: payload && payload.doctor_id,
      name: payload && payload.name,
      password: '',
      user_name: payload && payload.username,
      email: payload && payload?.email.toLowerCase(),
      user_short_code: payload && payload.user_short_code,
      picture_name: payload.docName ? payload.docName :"",
      picture:
        payload && payload.format && payload.format[1] ? payload.format[1] : '',
      bio: payload && payload.bio,
      experience: payload && payload.experience,
      specialization: payload && payload.specialization,
      qualification: payload && payload.qualification,
      mobile_no: payload && payload.mobile_no,
      prefix: payload && payload.prefix,
      clinic_name: payload?.clinic_name,
      clinic_address: payload?.clinic_address,
      associated_with: payload?.associated_with
    })

    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.updatedDoctorProfileStatus(response.data)
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),
  updatedDoctorProfileStatus: action((state, payload) => {
    state.DoctorProfileStatus = payload
  }),

  updateDoctorConsultationFees: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
    // actions.setProfileUpdateStatus(false)

    const response = await api.hospital.updateConsultationFees({
      doctor_id: payload?.doctor_id,
      in_person_fees: payload?.in_person_fees,
      tele_fees: payload?.tele_fees
    })

    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.updatedDoctorFeesStatus(response.data)
      // actions.setProfileUpdateLoader(false)
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),
  updatedDoctorFeesStatus: action((state, payload) => {
    state.DoctorFeesStatus = payload
  }),

  /* COUNTRY, STATE, CITY LIST */

  fetchCountryList: thunk(async (actions, payload) => {
    actions.fetchingCountryStatus(true)
    const response = await api.getCountryStateCity({})

    if (response.ok) {
      actions.fetchedCountryList(response.data.data)
      // actions.fetchedStateList([])
      actions.fetchingCountryStatus(false)
    }
  }),

  fetchingCountryStatus: action((state, payload) => {
    state.fetchingCountry = payload
  }),

  fetchedCountryList: action((state, payload) => {
    if (payload) {
      state.countryList = payload
    }
  }),

  fetchStateList: thunk(async (actions, payload) => {
    // const response = await api.getCountryStateCity({ country_id: payload })

    // if (response.ok) {
    //   actions.fetchedStateList(response.data.data)
    // }
    if (payload) {
      actions.fetchingStateStatus(true)
      const response = await api.getCountryStateCity({
        country_id: payload
      })
      if (response.ok) {
        actions.fetchingStateStatus(false)
        actions.fetchedStateList(response.data.data)
        actions.fetchedCityList([])
      }
    }
  }),

  fetchingStateStatus: action((state, payload) => {
    state.fetchingState = payload
  }),

  fetchedStateList: action((state, payload) => {
    if (payload) {
      state.stateList = payload
    }
  }),
  fetchCityList: thunk(async (actions, payload) => {
    // const response = await api.getCountryStateCity({ state_id: payload })

    // if (response.ok) {
    //   actions.fetchedCityList(response.data.data)
    // }
    if (payload) {
      actions.fetchingCityStatus(true)

      const response = await api.getCountryStateCity({ state_id: payload })
      if (response.ok) {
        actions.fetchingCityStatus(false)
        actions.fetchedCityList(response.data.data)
      }
    }
  }),

  fetchingCityStatus: action((state, payload) => {
    state.fetchingCity = payload
  }),

  fetchedCityList: action((state, payload) => {
    if (payload) {
      state.cityList = payload
    }
  }),

  clearState: action((state, payload) => {
    state.stateList = []
    state.cityList = []
  }),
  clearCity: action((state, payload) => {
    state.cityList = []
  })
}
